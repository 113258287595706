export class Simular {
    NumeroDocumento: string;
    TipoDocumento: string;
    CodigoPolitica: number;
    Monto: string;
    DiasGracia: number;
    DiasPago: number;
    CuotaNueva: number;
    PrimerPago: string;
    NombreProducto: string;
    Tea: string;
    noExisteFactor: boolean;
    MontoDistinto: boolean;
    ListCuotas: number[];
    cuotas: Array<Cuota> = [];

    applyTF: string;
    ProductId: string;
    Ammount: number;
    Tasa: number;
    AmmountQuota: number;
    IsWeb: boolean;
}

export class Cuota {
    cuotas: number;
    montoSimulado: string;
    montoCuota: string;
    tcea: string;
    seguroPago: number;
    seguroDesgravem: number;
    tf: string;
    tipoMoneda: string;
    degravamen: number;
    producIdT24: string;
    detalle: [];
    tasaSeguroDesgravamen: number;
    totalDesgravamen: number;
    totalInteres: number;
    totalCouta: number;
    totalAmortizacion: number;
    montoSeguro: number;
    seguroOptativo: boolean;
    safePercentage: number;
    safeParcialAmount: number;
    safeTaxes: number;
    safeCost: number;
    safeOfferIdentification: string;
    tem: string;
}

export class Simulacion {
    monto: number;
    diasgracia: number;
    diaspago: number;
    numerocuotas: number;
    fechaprimerpago: number;
    cuota: Cuota;
}

export class Politica {
    codigo: string;
    mensaje: string;
    estado: string;
    estadoDescripcion: string;
}

export class DataCliente {
    tea: string;
    tem: string;
    saldo: string;
}

export class Cliente {
    Nombres: string;
    ApellidoPaterno: string;
    ApellidoMaterno: string;
    NumeroDocumento: string;
    TipoDocumento: string;
    NumeroSolicitud: string;
    TipoDocumentoString: string;
    WFInstance: string;
    CodigoPolica: string;
    MiddleName: string;
    userCreation: string;
    offersearchdate: string;
}

export class Producto {
    IdProducto: string;
    Nombre: string;
    Codigo: string;
    Imagen: string;
    Imagen_m: string;
    Activacion: string;
    Saldo: number;
    FechaInicio: string;
    FechaFin: string;
    Tem: string;
    TemValue: string;
    Tea: string;
    TeaValue: string;
    Tna: string;
    TnaValue: string;
    Tca: string;
    TipoMoneda: number;
    PrimerPago: string;
    MontoMinimo: number;
    MontoMaximo: number;
    PlazoMinimo: number;
    PlazoMaximo: number;
    NumeroDiasAtraso: number;
    CodigoBase: string;
    ValidactionCDD: string;
    ModoActivacionPYA: string;
    FechaPago: string;

    PrestamoProducto: string;
    PrestamoDescription: string;
    PrestamoNumero: string;
    PrestamoArragement: string;
    PrestamoFechaDesembolso: string;
    PrestamoMontoOferta: string;
    PrestamoAmmountMinimo: number;
    PrestamoDeudaTotal: number;

    FlagEntidadesReengancheConvenio: boolean;

    ClientIdT24: string;

    OfferNumReenganches: number;
    OfferMontoMaximoReenganche: number;
    OfferMessageReenganche: string;

    TASA_TEA: string;
    TASA_TEM: string;
    TASA_TCA: string;
    TASA_TNA: string;
    LIN_EFEX_FINA: number;
    COD_TIPO_TARJ: string;
    TIP_TARJ: string;
    LIN_TC_FINA: number;
    LIN_FINA: number;
    NUM_CUEN: string;
    CLASE: string;
    CARACTER: string;
    PER_GRAC: string;
    BASE_ORIGEN: string;

    IntentosToken: number;

    //Valores para auditoria
    CapitalPayOff: number;
    MontoNetoPayOff: number;
    indicadorOrigenSef: string;

    diasMora: number;
    quotasCanceled: number;
    quotasPendient: number;
    seguroProdDatoAnt: number;
    seguroDesgravamenAnt: number;

    sdConRescate: string;
    sdSinRescate: string;
    offerAmountMaxSure: string;
    offerTEASure: string;
    reniecFechaNacimiento: string;
    productoEfex : ProductoEFEX;
}

export class Entidad {
    guid: string;
    entidad: string;
    monto: number;
    producto: string;
    cuenta: string;
    files: File[];
    entCodigo: string;
}

export class RequestSimulator {
    productId: string;
    ammount: number;
    tasa: number;
    cuotaNueva: number;
    isWeb: boolean;
    dayPayment: number;
    gracePeriod: number;
    offerCodBase: string;
    offerAmmountMax: number;
    currency: number;
    offerQuotaMax: number;
    clientIdT24: string;
    documentType: string;
    documentNumber: string;
    offerQuotaMin: number;
    ofeIndConvPef: string;
    baseOrigen: string;
    planDesgravamen: string;
    tasaDesgravamen: string;
    offerAmmountMaxSure: string;
    tasaSure: string;
    fechaNacimiento: string;
    fullName: string;
    productoEfex : ProductoEFEX;
    //tem_EFEX: number;
    //tea_EFEX: number;
    //plazo_EFEX: number;
    //diaspago_EFEX: number;
    contractNumber: string;
}

export class ResponseSimulator {
    ammount: number;
    ammountQuota: number;
    plazoCuota: number;
    seguroProtPago: number;
    seguroDesgravamen: number;
    applyTF: string;
    key: string;
    value: string;
    identificactionNumber: string;
    periodFrom: string;
    periodTo: string;
    firstPayment: string;
    percentage: number;
    messagingNotification: boolean;
    messagin: string;
    tea: number;
    tcea: number;
    producIdT24: string;
    tasaSeguroDesgravamen: string;
    detalle: [];
    totalDesgravamen: number;
    totalInteres: number;
    totalCouta: number;
    totalAmortizacion: number;
    safeAmount?: number;
    seguroOptativo: boolean;
    safePercentage?: number;
    safeParcialAmount?: number;
    safeTaxes?: number;
    safeCost?: number;
    safeOfferIdentification: string;
    globalTPAMessageCode: string;
    globalTPAMessage: string;
    tem: number;
    comission: number;
}

export class RequestSimulatorClient {
    arrangment: string;
    codeProductT24: string;
    loanDisbursementDate: string;
    codeProductRuo: string;
}

export class ResponseSimulatorClient {
    title: string;
    ammountFormat: string;
    commissions: string;
    ammount: string;
    hidden: boolean;
}

export class RequestSimulatorReprograming {
    quota: number;
    monthsGrace: number;
    ammount: number;
    currency: number;
    contractNumber: string;
    productCode: string;
    tasaTem: number;
    classCode: string;
}

export class ResponseSimulatorReprograming {
    quota: number;
    ammountQuota: number;
    ammountQuotaFormat: string;
    teaFormat: string;
    tea: number;
    tceaFormat: string;
    tcea: number;
    interests: number;
    interestsFormat: string;
    total: number;
    totalFormat: string;
    firstPayment: string;
    firstPaymentFormat: string;
    bicfi: string;
    bankPartyIdentification: string;
    amount: string;
    documentIdentification: string;
}

export class ProductoEFEX{
    amount:number;
    tem:number;
    tea:number;
    productCode:string;
    subProductCode:string;
    cardType:string;
    cardBrand:string;
    settlementGroup:string;
    plazo: number;
    diaspago: number;
    panNumber: string;
    maskPan: string;
    product: string;
    productType: string;
    productTypeDescription: string;
    diferido: string;
    expiryDate: string;
}

export class RequestCronogramaEfex {
    monto: string;
    diferido: string;
    diasplazo: string;
    diaspagoEfex: string;
    primerpago: string;
    tipodocumento: string;
    numeroDocumento: string;
    nombrecliente: string;
    usersessionId: string;
    totalDesgravamen: number;
    totalInteres: number;
    totalCouta: number;
    totalAmortizacion: number;
    detalle: [];
}