import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SolicitudService } from "../../../../services/ms-backoffice/solicitud.service";
import { AlertService, MessageSeverity } from "../../../services/alert.service";
import { AppConstants } from "../../../constants/app.constants";
import { StorageService } from "../../../services/storage.service";
import * as FileSaver from "file-saver";
import { Platform } from "@ionic/angular";
import { RequestTrayService } from "../../../../services/ms-backoffice/request-tray.service";

@Component({
  selector: "app-request-process-cancel-modal",
  templateUrl: "./request-process-cancel-modal.component.html",
  styleUrls: ["./request-process-cancel-modal.component.css"],
})
export class RequestProcessCancelModalComponent implements OnInit {
  arrayMessages = [];
  isLoadingProcess = false;
  messagestatusResult = "";
  classmessage = "";
  validDownload = false;
  isSuccess = false;
  errorProcess = false;
  maxAttempts = false;
  maxiError = 0;
  // Paramtros
  solicitudId: string;
  onClose: (() => void) | undefined;

  constructor(
    private solicitudService: SolicitudService,
    private alertaService: AlertService,
    private storageService: StorageService,
    private platform: Platform,
    private requestTrayService: RequestTrayService,
    public modalRef: BsModalRef
  ) {}

  ngOnInit() {
    this.isLoadingProcess = true;
    this.CallFinalizar();
  }

  FinalizarSolicitud(solicitudId: string) {
    console.log("FinalizarSolicitud");
    this.solicitudService.solicitudProcess(solicitudId).subscribe(
      async (response) => {
        this.arrayMessages = [
          ...response.messages.filter((f) => f.status !== "NA"),
        ];
        this.maxAttempts = response.maxAttempts;
        this.maxiError = 0;
        if (response.codigo === 400) {
          this.errorProcess = true;
          this.isLoadingProcess = false;
          this.classmessage = "modal-texto-error";
          this.messagestatusResult =
            this.maxAttempts === true
              ? "Usted superó el número de reintentos, favor comunicarse con el área de soporte."
              : "Finalizado con Error, porfavor vuelva a intentar";
        } else if (response.pending && !response.finish) {
          this.errorProcess = false;
          this.CallFinalizar();
        } else if (!response.pending && response.finish) {
          this.errorProcess = false;
          this.classmessage = "modal-texto-exito";
          this.messagestatusResult = "FINALIZADO CON EXITO";
          this.isSuccess = true;
          this.isLoadingProcess = false;
        } else {
          this.isLoadingProcess = false;
        }
      },
      (error: HttpErrorResponse) => {
        // this.loading = false;
        this.isLoadingProcess = false;
        this.errorProcess = true;
        this.maxiError = this.maxiError + 1;
        if (this.maxiError == 3) {
          this.maxAttempts = true;
        }
      }
    );
  }

  CallFinalizar() {
    this.FinalizarSolicitud(this.solicitudId);
    //this.errorProcess = true;
  }

  closeAlertModal() {
    if(!this.validDownload){
      this.donwloadConstacia(0);
    }

    if (this.onClose && !this.errorProcess) {
      this.onClose(); // Llama a la función de callback
    }
    this.modalRef.hide();
  }

  retryAgain() {
    this.isLoadingProcess = true;
    this.errorProcess = false;
    this.arrayMessages = [];
    this.messagestatusResult = "";
    this.classmessage = "";
    this.CallFinalizar();
  }

  donwloadConstacia(row: any) {
    this.isLoadingProcess = true;

    var filter = {
      solId: this.solicitudId,
      NameProduct:""
    };

    this.requestTrayService
      .downloadConstanciaCancelacionPDF(filter)
      .subscribe(
        (response) => {
          const data: Blob = new Blob([response], {
            type: response.type,
          });
          var nameFile = `Constancia_${this.solicitudId}.pdf`;
          if (!this.platform.is("cordova")) {
            FileSaver.saveAs(data, nameFile);
          } else {
            this.storageService.WriteFileMovil(nameFile, data);
          }
          this.validDownload = true;
        },
        (error) => {
          this.alertaService.showMessage(
            AppConstants.TitleModal.DOWNLOAD_FILE,
            error.messages,
            MessageSeverity.error
          );
        }
      )
      .add(() => {
        this.isLoadingProcess = false;
      });
  }
}
