export class DeviceObject {
    city: string;
    timezone: string;
    isp: string;
    region: string;
    country: string;
    ip: string;
    continentCode: string;
    countryCode: string;
    platform: string;
    agent: string;
    status: boolean;
    deviceUuid: string;
    deviceModel: string;
    deviceVersion: string;
    deviceManufacturer: string;
}
