export class OnboardingIntegration {
}

export class EmployedResponse {
    agencyCode: string;
    agencyName: string;
}

export class BankResponse {
    id: number;
    code: string;
    name: string;
}

export class BankComissionResponse {
    comission: number;
    operationTypeCode: string;
    bankOperationPartyType: string;
    bankTaxOnPaymentRate: string;
    tradeComision: number;
}

export class AmountCocoResponse {
    ammountCoco: number;
}

export class ProductRateResponse {
    tem: number;
    tea: number;
}

export class ActiveProductResponse {
    product: string;
    subProduct: string;
    cardType: string;
    cardBrand: string;
    settlementGroup: string;
    panNumber: string;
    maskPan: string;
    productType: string;
    productTypeDescription: string;
    expiryDate: string;

}

export class KeyConfigurationResponse {
    user: string;
    nonce: string;
    password: string;
}