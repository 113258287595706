import { CurrencyPipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ColumnMode } from '@swimlane/ngx-datatable';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Client, WorkFlowHeader } from '../../../../models/client';
import { Parameter } from '../../../../models/parameter';
import { ClientService } from '../../../../services/client.service';
import { ObservationService } from '../../../../services/observation.service';
import { ParameterService } from '../../../../services/parameter.service';
import { ReportService } from '../../../../services/report.service';
import { AppConstants } from '../../../constants/app.constants';
import { AccountStateModalComponent } from '../account-state-modal/account-state-modal.component';
import { AttachmentDetailModalComponent } from '../attachment-detail-modal/attachment-detail-modal.component';
import { ClientDetailModalComponent } from '../client-detail-modal/client-detail-modal.component';
import { DirectionDetailModalComponent } from '../direction-detail-modal/direction-detail-modal.component';
import { DisbursementDetailModalComponent } from '../disbursement-detail-modal/disbursement-detail-modal.component';
import { FinancialDataModalComponent } from '../financial-data-modal/financial-data-modal.component';
import { ProductDetailModalComponent } from '../product-detail-modal/product-detail-modal.component';
import { ReferenceDetailModalComponent } from '../reference-detail-modal/reference-detail-modal.component';

@Component({
    selector: 'app-request-detail-modal',
    templateUrl: './request-detail-modal.component.html',
    styleUrls: ['./request-detail-modal.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class RequestDetailModalComponent implements OnInit {
    ColumnMode = ColumnMode;

    modalDetailRef: BsModalRef;

    data: any;
    logList = [];
    archivosList = [];
    evidenciaCelularList = [];
    page: number;

    isCDD: boolean = false;

    imgMoney: string = './assets/images/icon-money.png';
    imgAttachment: string = './assets/images/icon-attachment.png';
    imgHouse: string = './assets/images/icon-house.png';
    imgUser: string = './assets/images/icon-user.png';
    loadingIndicator: boolean = true;

    loading: boolean = false;
    listObservado: any = [];
    listRechazado: any = [];
    listMotivos: any = [];
    listAprobado;
    comments = [];
    client: Client = new Client();
    formatmontoofertado;
    formatSolValueQuota;
    modeDesembolso;
    nroCtaDesembolso;
    userId: string = sessionStorage.getItem(AppConstants.Session.USERID);
    dataWorkflowHeader : WorkFlowHeader = new WorkFlowHeader();
    dataWorkflowDetail: any = [];

    formatDebtAmmount;
    fortmatAmmountNeto;
    listProdutcsDesembolso = [];

    resumenArray = [];
    resumenArrayFormat = [];

    datospersonalesActive: boolean = false;
    datosdireccionActive: boolean = false;
    datosreferenciaActive: boolean = false;
    modoDesembolsoActive: boolean = false;
    questionListActive: boolean = false;
    accountStatusActive: boolean = false;

    labelAccountState = '';
    validacionSinToken: boolean;

    showSeguroTC: boolean = false;

    constructor(
        private modalRequestRef: BsModalRef,
        private modalService: BsModalService,
        private clientService: ClientService,
        private parameterService: ParameterService,
        private observationService: ObservationService,
        private currencyPipe: CurrencyPipe,
        private reportService: ReportService
    ) { }

    async ngOnInit() {
        this.getParameters(AppConstants.ParameterCode.MOTIVOS);
        this.callGetComments(this.data.numeroSolicitud);
        await this.callGetClient(this.data.numeroSolicitud);
        await this.GetParameters([
            AppConstants.ParameterCode.PRODUCT_SECCION,
            AppConstants.ParameterCode.RESUMEN_SOLICITUD,
            AppConstants.ParameterCode.SECCION_CLIENTE,
            AppConstants.ParameterCode.ADICIONAL_PARAMETERS,
            AppConstants.ParameterCode.PRODUCTOS_SEGURO_OPTATIVO,
        ]);
        this.getWorkFlow(this.data.numeroSolicitud);
    }

    close() {
        this.modalRequestRef.hide();
    }

    clientDetailShow() {
        const initialState = {
            data: this.data
        }
        this.modalDetailRef = this.modalService.show(ClientDetailModalComponent, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false, initialState });
    }

    directionDetailShow() {
        const initialState = {
            data: this.data
        }
        this.modalDetailRef = this.modalService.show(DirectionDetailModalComponent, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false, initialState });
    }

    referenceDetailShow() {
        const initialState = {
            data: this.data
        }
        this.modalDetailRef = this.modalService.show(ReferenceDetailModalComponent, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false, initialState });
    }

    disbursementDetailShow() {
        const initialState = {
            data: this.data,
            desembolsoMode: this.listProdutcsDesembolso
        }
        this.modalDetailRef = this.modalService.show(DisbursementDetailModalComponent, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false, initialState });
    }

    attachmentDetailShow() {        
        const initialState = {
            data: this.data
        }
        this.modalDetailRef = this.modalService.show(AttachmentDetailModalComponent, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false, initialState });
    }


    getParameters(code: number) {
        this.loading = true;
        this.parameterService.getParameters(code).subscribe((response) => {
            this.listMotivos = response;
            this.loading = false;

            this.listAprobado = this.listMotivos.filter(x => x.tabDetCode008 == "APROBADO" && x.tabDetCode007 == this.client.offerProduct && x.tabDetCode006 == AppConstants.RolesCode.ANALISTA);
        },
            (error: HttpErrorResponse) => {
                this.loading = false;
            });
    }

    callGetComments(solId: string) {
        this.loading = true;
        this.observationService.getComentarios(solId).subscribe((response) => {
            response.forEach((item) => { item.class = item.usuario == this.userId ? 'comment-green' : 'comment-purple'; });
            this.comments = response;
            this.loading = false;
        },
            (error: HttpErrorResponse) => {
                this.loading = false;
            });
    }

    async callGetClient(code: string) {
        this.loading = true;
        try {
            const response = await this.clientService.getClient(code).toPromise();
            this.client = response;
            this.validacionSinToken = this.client.validacionSinToken == "SI";
            this.setValues();
            this.archivosList = await this.getAdjuntos(this.client.solId, AppConstants.AdjuntoModo.Cliente);
            this.evidenciaCelularList = await this.getAdjuntos(this.client.solId, AppConstants.AdjuntoModo.EvidenciaCelular);
            this.labelAccountState = this.getNameAccountState(response);
            this.loading = false;
        } catch (error) {
            this.loading = false;
        }
    }

    setValues() {
        this.formatmontoofertado = this.transformAmount(this.client.solAmount, this.client.solMoneda);
        this.formatSolValueQuota = this.transformAmount(this.client.solValueQuota, this.client.solMoneda);
        this.formatDebtAmmount   = this.transformAmount(this.client.debtAmount, this.client.solMoneda);
        this.fortmatAmmountNeto  = this.transformAmount(this.client.ammountNeto, this.client.solMoneda);
        this.inputsModeDesembolso(this.client.desemCtaExi, this.client.desemCtaNew, this.client.desemModEfect);
        this.nroCtaDesembolso = this.client.desemCtaExi == AppConstants.StateFlag.Activo ? this.client.desemNroCta : '';

    }

    transformAmount(montoEvaluar: number, tipoMoneda: number) {
        if (tipoMoneda == 1) {
            return this.currencyPipe.transform(montoEvaluar, 'S/ ', true, '1.2-2');
        } else {
            return this.currencyPipe.transform(montoEvaluar, '$ ', true, '1.2-2');
        }
    }

    inputsModeDesembolso(ctaExis: number, ctaNew: number, modEfec: number) {
        this.modeDesembolso = ctaExis == AppConstants.StateFlag.Activo && ctaNew == AppConstants.StateFlag.Inactivo && modEfec == AppConstants.StateFlag.Inactivo ? 'Abono a Cuenta Existente' :
            ctaExis == AppConstants.StateFlag.Inactivo && ctaNew == AppConstants.StateFlag.Activo && modEfec == AppConstants.StateFlag.Inactivo ? 'Abono a Cuenta Nueva' :
                ctaExis == AppConstants.StateFlag.Inactivo && ctaNew == AppConstants.StateFlag.Inactivo && modEfec == AppConstants.StateFlag.Activo ? 'Efectivo' :
                    '';
    }

    async getAdjuntos(code: string, contidion: number) {
        let list = [];
        this.loading = true;
        this.clientService.getFilesClient(code, contidion).subscribe((response) => {
            for (let k in response.files) {
                var archivo = { name: response.files[k]["name"], key: response.files[k]["rutaId"] };
                list.push(archivo);
            }
            this.loading = false;
        },
            (error: HttpErrorResponse) => {
                this.loading = false;
            });
        return list;
    }



    getWorkFlow(solId: string) {
        this.loading = true;
        this.reportService.getWorkflowClient(solId).subscribe((response) => {
            this.dataWorkflowHeader = response["workFlowHeader"];
            this.dataWorkflowDetail = response["workFlowDeatail"];
            this.loading = false;
        },
        (error: HttpErrorResponse) => {
            this.loading = false;
        });
    }

    async GetParameters(array: Array<number>) {
        let modelRequest = { headerId: array };
        this.loading = true;
        await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
          this.setVariablesParameters(response);
          this.loading = false;
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    setVariablesParameters(listParameter: Parameter[]) {
        this.listProdutcsDesembolso = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.PRODUCT_SECCION && f.tabDetState  == AppConstants.StateFlag.Activo &&  f.tabDetCode001 == this.client.offerProduct && f.tabDetCode003 == this.client.salesChannel);

        this.showSeguroTC = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.PRODUCTOS_SEGURO_OPTATIVO && f.tabDetCode001 == this.client.offerProduct && f.tabDetState == AppConstants.StateFlag.Activo).length > 0 ? true : false;

        if(this.client.solFlagSeguro === false || this.client.solFlagSeguro === null || this.client.solFlagSeguro === undefined) {
            this.resumenArray = (listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.RESUMEN_SOLICITUD && f.tabDetCode001 == this.client.offerProduct  && f.tabDetState == AppConstants.StateFlag.Activo && f.tabDetCode009 != AppConstants.StateFlag.Optional)).sort((a, b) => (a.tabDetCode003 < b.tabDetCode003 ? -1 : 1));
        } else {
            this.resumenArray = (listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.RESUMEN_SOLICITUD && f.tabDetCode001 == this.client.offerProduct  && f.tabDetState == AppConstants.StateFlag.Activo)).sort((a, b) => (a.tabDetCode003 < b.tabDetCode003 ? -1 : 1));
        }
        if (this.client.offerProduct == AppConstants.Products.CAPTACION && this.client.typeOfferCode.includes("01")) { // 01
            this.resumenArray = this.resumenArray.filter(f => !f.tabDetCode007.includes("debtAmount")); // Saldo a favor
        }

        if (this.client.offerProduct == AppConstants.Products.CAPTACION && !this.client.typeOfferCode.includes("01") && this.client.debtAmount === 0) {
            this.resumenArray = this.resumenArray.filter(f => !f.tabDetCode007.includes("debtAmount"));
        }

        if(this.client.offerProduct == AppConstants.Products.CAPTACION && this.client.typeOfferDescription.includes("Pre-emisión")){ // 01
            this.resumenArray = this.resumenArray.filter(f=>!f.tabDetCode007.includes("debtAmount")); // Saldo a favor
        }
        if(!this.showSeguroTC){
            this.resumenArray = this.resumenArray.filter(f => !f.tabDetCode007.includes("solAmountPrimaSeg"));
        }

        this.resumenArray.forEach(element => {
          if(element.tabDetCode008 == 'solMoneda') {
            this.resumenArrayFormat.push({
              label: element.tabDetCode006,
              value: this.transformAmount(this.nestedProp(this.client, element.tabDetCode007) as any, this.client.solMoneda)
            });
          } else if(element.tabDetCode008 == '%') {
            this.resumenArrayFormat.push({
              label: element.tabDetCode006,
              value: `${ isNaN(this.nestedProp(this.client, element.tabDetCode007) as any) ? `${ this.nestedProp(this.client, element.tabDetCode007)} ${(element.tabDetCode008 == null || element.tabDetCode008 == undefined || element.tabDetCode008 == 'undefined') ? '' : element.tabDetCode008}` : Number(this.nestedProp(this.client, element.tabDetCode007)).toFixed(2)} ${(element.tabDetCode008 == null || element.tabDetCode008 == undefined || element.tabDetCode008 == 'undefined') ? '' : element.tabDetCode008}`
            });
          } else {
            this.resumenArrayFormat.push({
              label: element.tabDetCode006,
              value: `${this.nestedProp(this.client, element.tabDetCode007)}${(element.tabDetCode008 == null || element.tabDetCode008 == undefined || element.tabDetCode008 == 'undefined') ? '' : element.tabDetCode008}`
            });
          }
        });

        let filterProduct = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.SECCION_CLIENTE && f.tabDetState == AppConstants.StateFlag.Activo && f.tabDetCode001 == this.client.offerProduct);
        if (filterProduct.length > 0) {
            this.createOptions(filterProduct[0].tabDetCode002 == 'S' ? true : false, filterProduct[0].tabDetCode006 == 'S' ? true : false, filterProduct[0].tabDetCode007 == 'S' ? true : false, filterProduct[0].tabDetCode008 == 'S' ? true : false, this.client.offerProduct == AppConstants.Products.REENGANCHECDD && this.client.ofeIndConvPef == AppConstants.StateFlag.InactivoString ? false : filterProduct[0].tabDetCode009 == 'S' ? true : false, filterProduct[0].tabDetCode010 == 'S' ? true : false, filterProduct[0].tabDetCode011 == 'S' ? true : false);
        }
    }

    nestedProp(obj: object, path: string): unknown {
        return path.split(".").reduce((o, k) => o ? (o as any)[k] : undefined, obj);
    }

    productDetailShow() {
        const initialState = {
            data: this.data
        }
        this.modalDetailRef = this.modalService.show(ProductDetailModalComponent, { class: 'modal-lg modal-dialog-centered', backdrop: 'static', keyboard: false, initialState });
    }

    createOptions(personalData: boolean, address: boolean, personReference: boolean, protectionData: boolean, disbursementMode: boolean, financialdata: boolean, esatdoCuenta : boolean) {
        this.datospersonalesActive = personalData;
        this.datosdireccionActive = address;
        this.datosreferenciaActive = personReference;
        this.modoDesembolsoActive = disbursementMode;
        this.questionListActive = financialdata;
        this.accountStatusActive = esatdoCuenta;
    }

    viewModalDatosFinancieros(operationType : string) {
        const initialState = {
            data: this.client,
            isView: operationType == 'view' ? true : false
        }
        this.modalDetailRef = this.modalService.show(FinancialDataModalComponent, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false, initialState });
    }

    viewModalAccountState(operationType : string) {
        const initialState = {
            data: this.client,
            isView: operationType == 'view' ? true : false
        }
        this.modalDetailRef = this.modalService.show(AccountStateModalComponent, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false, initialState });

        (this.modalDetailRef.content).onClose.subscribe((result: any) => {
          if (result) {
            this.callGetClient(this.client.solId);
          }
        })
    }

    getNameAccountState(input : Client) {
        let label = '';
        let state = input.estadoCuentaRecepcion == null ? "1" : input.estadoCuentaRecepcion;
        label = state == "1" ? this.client.direccion : this.client.email;
        return input.estadoCuentaFlag == 0 ? label : this.client.direccion2;
    }
}
