import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as FileSaver from 'file-saver';
import { ClientService } from '../../../../services/client.service';
import { Platform } from '@ionic/angular';
import { StorageService } from '../../../services/storage.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { AppConstants } from '../../../constants/app.constants';

@Component({
  selector: 'app-product-entidad-detail-modal',
  templateUrl: './product-entidad-detail-modal.component.html',
  styleUrls: ['./product-entidad-detail-modal.component.scss'],
})
export class ProductEntidadDetailModalComponent implements OnInit {
  modalDetailRef: BsModalRef;
  data: any;
  loading: boolean = false;

  entidadForm: FormGroup;
  messageErrors = {
    entidad: '',
    cuenta: '',
    monto: '',
    producto: ''
  };

  submit: boolean = false;
  archivosEntidadesList = [];
  guid: string = '';

  simboloMoneda: string;


  constructor(
    private modalProductEntidadDetailRef: BsModalRef,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private platform: Platform,
    private storageService: StorageService,
    private alertaService: AlertService
  ) { }

  ngOnInit() {
    this.simboloMoneda =  (this.data.entMoneda == AppConstants.TipoMonedaId.SOLES) ? AppConstants.TipoMonedaValor.SOLES : AppConstants.TipoMonedaValor.DOLARES;
    this.createForm();
    this.archivosEntidadesList = (this.data.files != null ? this.data.files : []);
  }

  createForm() {
    this.entidadForm = this.formBuilder.group({
      entidad: ['', Validators.required],
      cuenta: ['', Validators.required],
      monto: ['', Validators.required],
      producto: ['', Validators.required]
    });
    this.entidadForm.patchValue({
      entidad: this.data.entNombre,
      cuenta: this.data.entCuenta,
      monto: this.data.entMonto,
      producto: this.data.entProducto
    });
  }

  evaluateError(formControlName: string, label: string): boolean {
    var Errores = this.entidadForm.get(formControlName).errors;
    var Dirty: boolean = this.entidadForm.get(formControlName).dirty;
    var Touched: boolean = this.entidadForm.get(formControlName).touched;

    if (formControlName != null) {
      if (Errores != null) {
        if (Dirty && Touched || this.submit) {
          if (Errores['required'] && label != '') {
            this.messageErrors[formControlName] = 'Ingrese ' + label + '';
          }
          return true;
        }
      }
    }
    return false;
  }

  clear() {
    this.guid = '';
    this.archivosEntidadesList = [];
    this.entidadForm.patchValue({
      entidad: '',
      cuenta: '',
      monto: '',
      producto: ''
    });
  }

  close() {
    this.modalProductEntidadDetailRef.hide();
  }

  downloadArchivoEntidades(index) {
    var archivoEntidad = this.archivosEntidadesList[index];

    if (archivoEntidad.fileBase64 == undefined || archivoEntidad.fileBase64 == null) {
      this.downloadFile(archivoEntidad.rutaId, archivoEntidad.name);
    } else {
      this.downloadFileTemp(archivoEntidad.fileBase64, archivoEntidad.type, archivoEntidad.name);
    }
  }

  downloadFile(fileId: string, nameFile: string): void {
    this.loading = true;

    this.clientService.downloadFile(fileId).subscribe(
      (response) => {

        const data: Blob = new Blob([response], {
          type: response.type
        });

        let extension = fileId.split('.');
        if (!this.platform.is('cordova')) {
          FileSaver.saveAs(data, nameFile);
        } else {
          this.storageService.WriteFileMovil(nameFile, data);
        }

      },
      (error) => {
        this.alertaService.showMessage(AppConstants.TitleModal.DOWNLOAD_FILE,
          error.messages[0], MessageSeverity.error);
      }
    ).add(() => {
      this.loading = false;
    });
  }

  downloadFileTemp(base64: any, type: string, name: string): void {
    let data = this.b64toBlob(base64, type);
    if (!this.platform.is('cordova')) {
      FileSaver.saveAs(data, name);
    }
    else {
      this.storageService.WriteFileMovil(name, data);
    }
  }

  b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

}
