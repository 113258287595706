import { Injectable } from '@angular/core';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class ReportService {

    constructor(
        private httpClient: HttpClient
    ) { }

    private POST_ENDPOINT: string = "Reporte";
    private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    downloadFile(parameters: any): Observable<Blob> {
        let httpParams = new HttpParams({
            fromObject: parameters
        });
        //return this.httpClient.get(`${this.REQUEST_URL}/Excel`, { params: httpParams });
        return this.httpClient.get<Blob>(`${this.REQUEST_URL}/Excel`, { params: httpParams,
            responseType: 'blob' as 'json' })
            .pipe(catchError(this.parseErrorBlob))
    }

    downloadFileWorkFlow(parameters: any): Observable<Blob> {
        let httpParams = new HttpParams({
            fromObject: parameters
        });
        //return this.httpClient.get(`${this.REQUEST_URL}/Excel`, { params: httpParams });
        return this.httpClient.get<Blob>(`${this.REQUEST_URL}/ExcelWorkflow`, {
            params: httpParams,
            responseType: 'blob' as 'json'
        })
            .pipe(catchError(this.parseErrorBlob))
    }

    parseErrorBlob(err: HttpErrorResponse): Observable<any> {
        const reader: FileReader = new FileReader();

        const obs = Observable.create((observer: any) => {
            reader.onloadend = (e) => {
                observer.error(JSON.parse(reader.result.toString()));
                observer.complete();
            }
        });
        reader.readAsText(err.error);
        return obs;
    }

    getLogsFilter(parameters: any) {
        return this.httpClient.get<any[]>(`${this.REQUEST_URL}/GetLogs`, { params: parameters });
    }

    getReportsConfig() {
        return this.httpClient.get<any[]>(`${this.REQUEST_URL}/GetReports`);
    }

    downloadFileReport(request: any): Observable<Blob> {
        return this.httpClient.post<Blob>(`${this.REQUEST_URL}/DownloadReports`,  request,
        {responseType: 'blob' as 'json' })
        .pipe(catchError(this.parseErrorBlob))
    }

    getWorkflowClient(solId: string): Observable<any> {
        return this.httpClient.get<any>(`${this.REQUEST_URL}/GetWorkflow/${solId}`);
    }

    getAuditoriaLogin(parameters: any) {
        return this.httpClient.get<any[]>(`${this.REQUEST_URL}/GetAuditoriaLogin`, { params: parameters });
    }

    getLogDetail(Id: number) {
        return this.httpClient.get<any>(`${this.REQUEST_URL}/GetLog/${Id}`);
    }

    downloadFileReportClient(request: any): Observable<any> {
        return this.httpClient.post<any>(`${this.REQUEST_URL}/DownloadReportsClient`,  request);
    }
}
