import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse  } from '@angular/common/http';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { RequestSimulator, RequestSimulatorClient, RequestSimulatorReprograming, ResponseSimulator, ResponseSimulatorClient, ResponseSimulatorReprograming } from '../models/simular';
import { Observable } from 'rxjs';
import { GetContractByAccount } from '../models/contract';
import { catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SimulatorService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "Simulator";
  private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  postGetSimulacion(model: RequestSimulator): Observable<ResponseSimulator[]> {
    return this.httpClient.post<ResponseSimulator[]>(`${this.REQUEST_URL}/GetSimulacion`, model);
  }

  postGetSimulacionClient(model: RequestSimulatorClient): Observable<ResponseSimulatorClient[]> {
    return this.httpClient.post<ResponseSimulatorClient[]>(`${this.REQUEST_URL}/GetSimulationLoanX`, model);
  }

  postGetSimulacionReprogramming(model: RequestSimulatorReprograming): Observable<ResponseSimulatorReprograming[]> {
    return this.httpClient.post<ResponseSimulatorReprograming[]>(`${this.REQUEST_URL}/GetSimulationReprogramming`, model);
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();
    const obs = Observable.create((observer: any) => {
      reader.onloadend = (e) => {
        observer.error(JSON.parse(reader.result.toString()));
        observer.complete();
      }
    });
    reader.readAsText(err.error);
    return obs;
  }
  postGetContractByAccount(model: GetContractByAccount): Observable<any> {
    return this.httpClient.post<GetContractByAccount[]>(`${this.BASE_URL}/Integration/GetContractByAccount`, model);
  }
  downloadFileCronogramaEfexPDF(request: any): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/GetCronogramaEfexPDF`, request,
      { responseType: 'blob' as 'json' })
      .pipe(catchError(this.parseErrorBlob))
  }

}
