export class Verification {
}

export class VerificationSMS {
  documentType: string;
  documentNumber: string;
  email: string;
  phone: string;
  userId: string;
  userRol: string;
}

export class VerificationValidateSMS {
  documentType: string;
  documentNumber: string;
  pin: string;
  phone: string;
  solId: string;
  userId: string;
  userRol: string
}

export class VerificationDactilar {
  solId: string;
  validationId: number;
  validationDes: string;
  indQA: string;
  numTxn: string;
  userId: string;
  userRol: string;
}

export class VerificationDactilarResponse {
  code: number;
  descryption: string;
}

export class Accept {
  solId: string;
  worFlowId: string;
  userId: string;
  userRol: string;
  processId: number;
  channel: number;
  taskId: string;
}

export class Response {
  solId: string;
  worFlowId: string;
}

export class ResponseBiomatchWS {
  coMensaje: string;
  deMensaje: string;
  numDoc: string;
  codResul: string;
  deResult: string;
  indQA: string;
  numTxn: string;
}

export class AuthenticationInitiation {
  solId: string;
  authenticationMethod: number;
}

export class AuthenticationUpdation {
  solId: string;
  authenticationMethod: number;
  reponseCode: number;
  reponseMessage: string;
  transactionId: string;
  transactionState: number;
  transactionDescription: string;
}

export class RegisterMonitor {
  solId: string;
  codRespuesta: string;
  tipoAutenticacion: string;
  applyRule: string;
}

export class VerificationFlow {  
  mode?: number;
  nextMode: number;
  title?: string;
  subTitle?: string;
  label?: string;
  retries?: number;
  sendTo?: string;
  resendButtonText?: string;
  enable?: boolean;
  constructor() {
    // Valores predeterminados
    this.mode = 0;
    this.nextMode = 0;
    this.mode = 0;
    this.title = '';
    this.subTitle = '';
    this.label = '';
    this.retries = 0;
    this.sendTo = '';
    this.resendButtonText = '';
  }
}