import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Client } from '../../../../models/client';
import { ModeDesembolso } from '../../../../models/mode-desembolso';
import { Parameter } from '../../../../models/parameter';
import { ClientService } from '../../../../services/client.service';
import { ParameterService } from '../../../../services/parameter.service';
import { AppConstants } from '../../../constants/app.constants';
import { TransferBankSchedulesComponent } from '../transfer-bank-schedules/transfer-bank-schedules.component';

@Component({
    selector: 'app-disbursement-detail-modal',
    templateUrl: './disbursement-detail-modal.component.html',
    styleUrls: ['./disbursement-detail-modal.component.css'],
})
export class DisbursementDetailModalComponent implements OnInit {
    modeDesembolsoModel: ModeDesembolso = new ModeDesembolso();
    abonoCtaMode = "0";
    btncuentaActive: boolean = true;
    cuentasList = [];
    desembolsoMode: any;
    data: any;
    loading: boolean = false;
    client: Client = new Client();
    isView = true;

    imgMoneyCircle: string = './assets/images/icon-money-circle.png';


    productsNew = [];
    diasgraciaProduct = [];
    listaParameters = [];
    ammountMinReeganche = [];


    ModeAccountUser:  boolean = true;
    Modecash:  boolean = true;
    Modetransferinterbank:  boolean = true;
    ModeInternalAccount: boolean = true;

    ModeAccountUserClass: string = 'button-cuenta';
    ModecashClass: string = 'button-cuenta';
    ModetransferinterbankClass: string = "button-cuenta";
    ModeInternalAccountClass: string = "button-cuenta";

    DetailModeAccountUser:  boolean = false;
    DetailModetransferinterbank:  boolean = false;

    numCtaCCI: string = '';
    bankDescription: string = '';
    descriptionTransferInterbank: string = 'Transferencia a  cuenta interbancaria propia.';
    comisionTransfer: number = 0.0;
    comisionTransferConfirmed: boolean = false;

    modeAccount: string =  "2";
    modeAccountInterbank: string =  "2";
    typeDesembolso: number = 0;

    modeAccountNumber: string = null;
    modeInterbankNumber: string = null;

    callInvocation: number = 0;

    modalSchedule: BsModalRef;
    showDesembolsoCash: boolean = false;

    constructor(
        private modalDisbursementDetailRef: BsModalRef,
        private clientService: ClientService,
        private modalService: BsModalService,
        private parameterService: ParameterService
    ) { }

    async ngOnInit() {
      await this.GetParameters([
        AppConstants.ParameterCode.ADICIONAL_PARAMETERS
      ]);
      await this.callGetClient(this.data.numeroSolicitud);
      console.log(this.client);
      if(this.desembolsoMode.length > 0) {
        this.createOptionsDesembolso(this.desembolsoMode[0].tabDetCode006 == 'S' ? true : false, (this.client.solFlagSeguro && this.client.offerProduct == AppConstants.Products.SEF) ? this.showDesembolsoCash : (this.desembolsoMode[0].tabDetCode007 == 'S' ? true : false), this.desembolsoMode[0].tabDetCode008 == 'S' ? true : false, this.desembolsoMode[0].tabDetCode009 == 'S' ? true : false);
      }
     }

    close() {
        this.modalDisbursementDetailRef.hide();
    }

    tipoCuenta() {

    }

    ValidatorNoWhitespace(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }


    handleChange(input : number){
        if(input == 2){
          this.modeAccountNumber = null;
          this.modeInterbankNumber = null;
        }
      }
    
    validateModeDesembolso(type : number) {
    
        this.typeDesembolso = type;
        switch (type) {
            case 1:
            this.DetailModeAccountUser = true;
            this.DetailModetransferinterbank = false;

            this.ModeAccountUserClass = "button-cuenta active";
            this.ModecashClass = "button-cuenta";
            this.ModetransferinterbankClass = "button-cuenta";
            this.ModeInternalAccountClass = "button-cuenta";
            break;
            case 2:
            this.DetailModeAccountUser = false;
            this.DetailModetransferinterbank = false;
            this.ModeAccountUserClass = "button-cuenta";
            this.ModecashClass = "button-cuenta active";
            this.ModetransferinterbankClass = "button-cuenta";
            this.ModeInternalAccountClass = "button-cuenta";
            break;
            case 3:
            this.DetailModeAccountUser = false;
            this.DetailModetransferinterbank = true;

            this.ModeAccountUserClass = "button-cuenta";
            this.ModecashClass = "button-cuenta";
            this.ModeInternalAccountClass = "button-cuenta";
            this.ModetransferinterbankClass = "button-cuenta active";
            break;     
            case 4:
            this.DetailModeAccountUser = false;
            this.DetailModetransferinterbank = false;

            this.ModeAccountUserClass = "button-cuenta";
            this.ModecashClass = "button-cuenta";
            this.ModetransferinterbankClass = "button-cuenta";
            this.ModeInternalAccountClass = "button-cuenta active";
            break;
        }
    }

    createOptionsDesembolso(des1: boolean, des2: boolean, des3: boolean, des4: boolean){
        this.ModeAccountUser = des1;
        this.Modecash = des2;
        this.Modetransferinterbank= des3;
        this.ModeInternalAccount = des4;
    }

    async callGetClient(code: string) {
        this.loading = true;
        await this.clientService.getClient(code).toPromise().then((response) => {
            this.client = response;
            this.modeDesembolsoSet(this.client);
            this.loading = false;
        },
        (error: HttpErrorResponse) => {
        this.loading = false;
        });
    }

    modeDesembolsoSet(model: Client) {
    
        if(model.modCtaInterna == 5){
          this.validateModeDesembolso(4);
        } else if (model.modTransInterbancaria == 4) {
          this.comisionTransfer = model.modTransInterbancariaComision;
          this.bankDescription = model.modTransInterbancariaBanco;
          this.numCtaCCI = model.modNroCtaInterbancaria;
          this.validateModeDesembolso(3);
          if(model.desemCtaNew == 1){
            this.handleChange(2);
          } else if(model.desemCtaExi == 1) {
            this.handleChange(1);
          }
        } else if (model.desemModEfect == 1) {
          this.validateModeDesembolso(2);
        } else if (model.desemCtaNew == 1) {
          this.validateModeDesembolso(1);
          this.handleChange(2);
        } else if (model.desemCtaExi == 1) {
          this.validateModeDesembolso(1);
          this.handleChange(1);
        }
    }

    viewHorarios() {
      this.modalSchedule = this.modalService.show(TransferBankSchedulesComponent, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false });
    }

    async GetParameters(array: Array<number>) {
      let modelRequest = {headerId: array};
      this.loading = true;
      await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
              this.listaParameters = response;
              this.setVariablesParameters(response);
              this.loading = false;
      },
      (error: HttpErrorResponse) => {
          this.loading = false;
      });
    }

    setVariablesParameters(listParameter: Parameter[]) {                                  
      this.showDesembolsoCash = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS && f.tabDetCode001 == "07" && f.tabDetState == AppConstants.StateFlag.Activo).length > 0 ? true: false;
    }
}
