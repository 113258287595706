import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';

declare const hideMessage: any;
declare const showMessage: any;

@Component({
  selector: 'onb-information-user-float',
  templateUrl: './onb-information-user-float.component.html',
  styleUrls: ['./onb-information-user-float.component.css']
})
export class OnbInformationUserFloatComponent implements OnInit {

  agencyCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE);
  agencyName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME);
  usersessionId: string = sessionStorage.getItem(AppConstants.Session.USERID);
  channelName: string = sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION);

  constructor() { }

  ngOnInit() {
  }

  showMessage() {
    showMessage();
  }

  hideMessage() {
    hideMessage();
  }

}
