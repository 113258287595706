import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { HelperService } from '../shared/services/helper.service';
import { SolicitudPut, SolicitudPutProduct, RequestReturnSolicitud, ResponseReturnSolicitud, FechasSolicitud, ResponseFinalizarSolicitud, ResponseValidateOfferReeganche, RequestUpdateSolicitudCoco, ValidateStateSolicitud, ValidateDecline, OperacionesEfex, AnularSolicitudRequest, ResponseAnularSolicitud, RequestAnularSolicitud, RequestValidatePinpadTicket, ResponseValidate } from '../models/solicitud';
import { Observable } from 'rxjs';
import { RequestTray } from '../models/request-tray';
import { ResponseService } from '../models/response';
import { getWorkFlowSummaryResponse } from '../models/parameter';																 

@Injectable({
  providedIn: 'root'
})
export class SolicitudService {

  constructor(
      private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "Solicitud";
  private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  actualizarBandejaAnulacion = new EventEmitter<void>();

  updateStateSolicitud(model: SolicitudPut): Observable<SolicitudPut> {
    return this.httpClient.put<SolicitudPut>(`${this.REQUEST_URL}/Update/State`, model);
  }

  updateProductSolicitud(model: SolicitudPutProduct): Observable<SolicitudPutProduct> {
    return this.httpClient.put<SolicitudPutProduct>(`${this.REQUEST_URL}/Update/Product`, model);
  }

  returnSolicitud(model: RequestReturnSolicitud) {
    return this.httpClient.post<ResponseReturnSolicitud>(`${this.REQUEST_URL}/return`, model, { observe: 'response' });
  }

  getFechasSolicitud(solicitudId: string) {
    return this.httpClient.get<FechasSolicitud>(`${this.REQUEST_URL}/FechasSolicitud?solicitudId=${solicitudId}`)
  }

  updateReferred(model: any): Observable<any> {
    return this.httpClient.put<any>(`${this.REQUEST_URL}/Update/Referred`, model);
  }

  getWorkflowDetail(solicitudId: string) {
    return this.httpClient.get<any[]>(`${this.REQUEST_URL}/GetWorkflow/${solicitudId}`)
  }
  getWorkFlowSummary(parameter: any) {
    return this.httpClient.post<getWorkFlowSummaryResponse>(`${this.REQUEST_URL}/getWorkFlowSummary`, parameter);
  }

  updateStateFinish(model: SolicitudPut): Observable<ResponseFinalizarSolicitud> {
    return this.httpClient.put<ResponseFinalizarSolicitud>(`${this.REQUEST_URL}/Update/finish`, model);
  }

  validateOfferReeganche(model: any): Observable<ResponseValidateOfferReeganche> {
    return this.httpClient.post<ResponseValidateOfferReeganche>(`${this.REQUEST_URL}/ValidationOfferReenganche`, model);
  }

  updateStateClient(model: SolicitudPut): Observable<SolicitudPut> {
    return this.httpClient.put<SolicitudPut>(`${this.REQUEST_URL}/ValidationClient`, model);
  }

  updateEstadoCuenta(model: RequestUpdateSolicitudCoco): Observable<number> {
    return this.httpClient.put<number>(`${this.REQUEST_URL}/Update/StateAccount`, model);
  }

  updateTypeCallCenter(solicitudId: string,model) {
    return this.httpClient.put<any>(`${this.REQUEST_URL}/${solicitudId}/tipo-call`,model);
  }

  getValidateStateSolicitud(solicitudId: string) {
    return this.httpClient.get<ValidateStateSolicitud>(`${this.REQUEST_URL}/ValidateState/${solicitudId}`)
  }

  getValidateDecline(solicitudId: string) {
    return this.httpClient.get<ValidateDecline>(`${this.REQUEST_URL}/ValidateDecline/${solicitudId}`)
  }

  getOperacionesEfex(tipoDocumento: string, nroDocumento:string, nroSolicitud:string, userId:string) {
    const params ={
      tipoDocumento,
      nroDocumento,
      nroSolicitud,
      userId
    }
    return this.httpClient.get<RequestTray[]>(`${this.REQUEST_URL}/Efex/Anulacion/Bandeja`,{params:params})
  }

  anularSolicitud(model: AnularSolicitudRequest) {
    return this.httpClient.post<ResponseService>(`${this.REQUEST_URL}/Efex/Anulacion/Solicitud`, model);
  }

  notificarActualizacionBandejaAnulacion() {
    this.actualizarBandejaAnulacion.emit();
  }

  anulacionSolicitud(model: RequestAnularSolicitud): Observable<ResponseAnularSolicitud> {
    return this.httpClient.post<ResponseAnularSolicitud>(`${this.REQUEST_URL}/Efex/Anulacion/Finish`, model);
  }
  
  updateStateFinishEFEX(model: SolicitudPut): Observable<ResponseFinalizarSolicitud> {
    return this.httpClient.put<ResponseFinalizarSolicitud>(`${this.REQUEST_URL}/Update/finishEFEX`, model);
  }

  validatePinpadTicket(model: RequestValidatePinpadTicket): Observable<ResponseValidate> {
    return this.httpClient.post<ResponseValidate>(`${this.REQUEST_URL}/Validate/PinpadTicket`, model);
  }
}
