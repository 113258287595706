import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {Client} from '../../../../models/client';
import {Parameter} from '../../../../models/parameter';
import {RequestUpdateSolicitudCoco} from '../../../../models/solicitud';
import {ClientService} from '../../../../services/client.service';
import {ParameterService} from '../../../../services/parameter.service';
import {SolicitudService} from '../../../../services/solicitud.service';
import {AppConstants} from '../../../constants/app.constants';
import {AlertService, MessageSeverity} from '../../../services/alert.service';
import { InfoInsuranceModalComponent } from '../info-insurance-modal/info-insurance-modal.component';

@Component({
    selector: 'app-account-state-modal',
    templateUrl: './account-state-modal.component.html',
    styleUrls: ['./account-state-modal.component.scss'],
})
export class AccountStateModalComponent implements OnInit {

    data: any;
    client: Client = new Client();

    loading: boolean = false;

    modalSchedule: BsModalRef;

    radioDirection: number = 0;
    listEstadoCuentaPago = [];
    listEstadoCuentaRecepcion = [];
    listCardsType = [];
    listDatosTarjeta = [];

    stateAccountForm: FormGroup;
    stateAccountSubmit: boolean = false;

    stateAccountErrors = {
        fecpago: '',
        recepcion: '',
        anotherAdreesForm: '',
        cardType: '',
        clientpep: '',
        createsavingaccount: ''
    };

    labelRecepcion = '';
    labelRecepcion1 = '';
    labelRecepcion2 = '';
    somePlaceholder: string = 'Ingrese dirección';

    isView: boolean;

    public onClose: Subject<any>;

    Tcmessage1 = '';
    Tcmessage2 = '';
    Tcmessage3 = '';
    TcmessagePEP = '';

    showCuentaAhorro: boolean = false;
    rolAdmin: string = 'R0001'
    rolId: string = sessionStorage.getItem(AppConstants.Session.USERROLID);

    showSeguroTC: boolean = false;
    switchValueSeguroTC: string = "NO";
    textInfoTC = [];

    constructor(
        private formBuilder: FormBuilder,
        private modalfinancialdata: BsModalRef,
        private alertaService: AlertService,
        private parameterService: ParameterService,
        private clientService: ClientService,
        private modalService: BsModalService,
        private solicitudService: SolicitudService
    ) {
    }

    async ngOnInit() {
        this.onClose = new Subject();
        this.createForm();
        await this.callGetClient(this.data.solId);
        await this.GetParameters([
            AppConstants.ParameterCode.ESTADOCUENTA_FECHA_PAGO,
            AppConstants.ParameterCode.ESTADOCUENTA_RECEPCION,
            AppConstants.ParameterCode.CREDIT_CARDS_TYPE,
            AppConstants.ParameterCode.DATOS_TARJETA,
            AppConstants.ParameterCode.ADICIONAL_PARAMETERS,
            AppConstants.ParameterCode.TEXTO_SEGURO_TC,
            AppConstants.ParameterCode.PRODUCTOS_SEGURO_OPTATIVO,
        ]);
        this.configurateForm();
    }

    configurateForm()
    {
        //
        if(!this.showCuentaAhorro)
        {
            this.stateAccountForm.controls["aceptaCuentaAhorro"].clearValidators();
            this.stateAccountForm.controls["aceptaCuentaAhorro"].updateValueAndValidity();
        }

    }

    close() {
        this.modalfinancialdata.hide();
    }

    createForm() {

        this.stateAccountForm = this.formBuilder.group({
            fecpago: ['', Validators.required],
            recepcion: ['', Validators.required],
            anotherAdrees: [{value: '', disabled: true}],
            solComprasInternet: [{value: this.data.solComprasInternet, disabled: false}],
            solComprasExtranjero: [{value: this.data.solComprasExtranjero, disabled: false}],
            solRecibirNotificaciones: [{value: this.data.solRecibirNotificaciones, disabled: false}],
            solComentarioRestriccion: [{value: this.data.solComentarioRestriccion, disabled: false}],
            cardType: ['', Validators.required],
            cashprovision: [{value: this.data.cashprovision, disabled: false}],
            overdraft: [{value: this.data.overdraft, disabled: false}],
            notification: [{value: this.data.notification, disabled: false}],
            // isPEP: [{value: this.data.isPEP, disabled: false}],
            clientpep: ['', Validators.required],
            seguroTC: [this.data.solFlagSeguro],
            createsavingaccount: [''],
            aceptaCuentaAhorro: [this.data.creditCardClientAceptaCuentaAhorro],
            tipoCuentaAhorro: [this.data.creditCardTipoCuentaAhorro]
        });
    }

    stateAccountEvaluateError(formControlName: string, label: string): boolean {
        var Errores = this.stateAccountForm.get(formControlName).errors;
        var Dirty: boolean = this.stateAccountForm.get(formControlName).dirty;
        var Touched: boolean = this.stateAccountForm.get(formControlName).touched;

        if (formControlName != null) {
            if (Errores != null) {
                if (Dirty && Touched || this.stateAccountSubmit) {
                    if ((Errores['required'] || Errores['whitespace']) && label != '') {
                        this.stateAccountErrors[formControlName] = label;
                    }
                    return true;
                }
            }
        }
        return false;
    }

    changeEstado(input: number) {
        this.radioDirection = input;
        if (input == 1) {
            this.stateAccountForm.controls['anotherAdrees'].setValidators([Validators.required]);
            this.stateAccountForm.controls['anotherAdrees'].updateValueAndValidity();
            this.stateAccountForm.controls['anotherAdrees'].setValue('');
            this.stateAccountForm.controls['anotherAdrees'].enable();
        } else {
            this.stateAccountForm.controls['anotherAdrees'].clearValidators();
            this.stateAccountForm.controls['anotherAdrees'].updateValueAndValidity();
            this.stateAccountForm.controls['anotherAdrees'].setValue('');
            this.stateAccountForm.controls['anotherAdrees'].disable();
        }
    }

    UpdateSolicitudCoco(model: RequestUpdateSolicitudCoco) {
        this.loading = true;
        this.solicitudService.updateEstadoCuenta(model).subscribe((response) => {
            this.loading = false;
            this.onClose.next(this.client);
            this.modalfinancialdata.hide();
        },
        (error: HttpErrorResponse) => {
            this.loading = false;
            this.alertaService.showMessage(AppConstants.TitleModal.UPDATE_STATE_ACCOUNT, error.error.messages, MessageSeverity.error);
        });
    }

    async callGetClient(code: string) {
        this.loading = true;
        this.clientService.getClient(code).toPromise().then((response) => {
                this.client = response;
                this.stateAccountForm.patchValue({
                    fecpago: this.client.solDayPayment == 0 ? null : this.client.solDayPayment,
                    recepcion: this.client.estadoCuentaRecepcion == null ? 1 : this.client.estadoCuentaRecepcion, //Envio de Estado de Cuenta
                    anotherAdrees: this.client.direccion2,
                    solComprasInternet: this.client.creditCardOnlineShopping,
                    solComprasExtranjero: this.client.creditCardShoppingAbroad,
                    solRecibirNotificaciones: this.client.solRecibirNotificaciones,
                    cardType: this.client.creditCardCode = '' ? null : this.client.creditCardCode,
                    cashprovision: this.client.creditCardCashprovision,
                    overdraft: this.client.creditCardOverdraft,
                    notification: this.client.creditCardNotification,
                    // isPEP: this.client.creditCardClientPEP,
                    clientpep: this.client.creditCardClientPEP,
                    seguroTC: this.client.solFlagSeguro,
                    createsavingaccount: this.client.creditCardCreateSavingAccount,
                    aceptaCuentaAhorro: this.client.creditCardClientAceptaCuentaAhorro,
                    tipoCuentaAhorro: this.client.creditCardTipoCuentaAhorro
                });

                this.validateRadioButton(this.client.estadoCuentaFlag, this.client.estadoCuentaRecepcion);
                this.changeClientPEP();
                this.changeReception(this.stateAccountForm.value['recepcion']);

            },
            (error: HttpErrorResponse) => {
                this.loading = false;
            });
    }

    async GetParameters(array: Array<number>) {
        let modelRequest = {headerId: array};
        this.loading = true;
        await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
                this.setVariablesParameters(response);
                this.loading = false;
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
            });
    }

    setVariablesParameters(listParameter: Parameter[]) {

        this.textInfoTC = listParameter.filter(x => x.tabCabId == AppConstants.ParameterCode.TEXTO_SEGURO_TC);

        let listEstadoCuentaPagoTemp = (listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ESTADOCUENTA_FECHA_PAGO && f.tabDetState == AppConstants.StateFlag.Activo)).sort((a, b) => (a.tabDetCode003 < b.tabDetCode003 ? -1 : 1));
        this.listEstadoCuentaRecepcion = (listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ESTADOCUENTA_RECEPCION && f.tabDetState == AppConstants.StateFlag.Activo)).sort((a, b) => (a.tabDetCode003 < b.tabDetCode003 ? -1 : 1));

        listEstadoCuentaPagoTemp.forEach((element) => {
            let object = { tabDetCode001 : parseInt(element.tabDetCode001), tabDetCode002: element.tabDetCode002  };
            this.listEstadoCuentaPago.push(object);
        });

        this.listCardsType = (listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.CREDIT_CARDS_TYPE && f.tabDetState == AppConstants.StateFlag.Activo)).sort((a, b) => (a.tabDetCode003 < b.tabDetCode003 ? -1 : 1));
        this.listDatosTarjeta = (listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.DATOS_TARJETA && f.tabDetState == AppConstants.StateFlag.Activo)).sort((a, b) => (a.tabDetCode003 < b.tabDetCode003 ? -1 : 1));
        this.showCuentaAhorro = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS && f.tabDetCode001 == "08" && (f.tabDetCode006.includes(this.rolId) || this.rolId == this.rolAdmin)  && f.tabDetState == AppConstants.StateFlag.Activo).length > 0 ? true: false;
        this.showSeguroTC = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.PRODUCTOS_SEGURO_OPTATIVO && f.tabDetCode001 == this.client.offerProduct && f.tabDetState == AppConstants.StateFlag.Activo).length > 0 ? true : false;
        }

    changeReception(value: any) {
        this.radioDirection = 0;
        this.stateAccountForm.controls['anotherAdrees'].setValue('');
        this.stateAccountForm.controls['anotherAdrees'].disable();
        if (value == 1) {
            this.labelRecepcion = this.client.direccion;
            this.somePlaceholder = 'Ingrese dirección';

            this.Tcmessage1 = '(Costo adicional de';
            this.Tcmessage2 = 'S/ 9.90';
            this.Tcmessage3 = 'mensuales)';

        } else {
            this.labelRecepcion = this.client.email;
            this.somePlaceholder = 'Ingrese e-mail';

            this.Tcmessage1 = 'En este correo se enviará toda la documentación contractual, asi como los estados de la cuenta de la TC';
            this.Tcmessage2 = '';
            this.Tcmessage3 = '';
        }
    }

    updateAccountState() {
        this.stateAccountSubmit = true;
        if (this.stateAccountForm.valid) {

            var model = new RequestUpdateSolicitudCoco();
            model.solId = this.client.solId;
            model.solFechPago = this.stateAccountForm.value['fecpago'];
            model.solRecepcionEstadoCuenta = this.stateAccountForm.value['recepcion'];
            model.solEnvioEstadoCuenta = this.radioDirection;
            model.solNuevaDireccion = this.radioDirection == 1 ? this.stateAccountForm.value['anotherAdrees'] : '';
            model.solComprasExtranjero = this.stateAccountForm.value['solComprasExtranjero'];
            model.solComprasInternet = this.stateAccountForm.value['solComprasInternet'];
            model.solRecibirNotificaciones = this.stateAccountForm.value['solRecibirNotificaciones'];
            model.solComentarioRestriccion = this.stateAccountForm.value['solComentarioRestriccion'];
            model.creditCardCode = this.stateAccountForm.value['cardType'];
            model.cashprovision = this.stateAccountForm.value['cashprovision'];
            model.overdraft = this.stateAccountForm.value['overdraft'];
            model.notification = this.stateAccountForm.value['notification'];
            model.clientPEP = this.stateAccountForm.value['clientpep'];
            model.solFlagSeguro = this.stateAccountForm.value['seguroTC'];
            model.createSavingAccount = this.stateAccountForm.value['createsavingaccount'];
            model.aceptaCuentaAhorro = this.stateAccountForm.value['aceptaCuentaAhorro'];
            model.tipoCuentaAhorro = this.stateAccountForm.value['tipoCuentaAhorro'];
            this.UpdateSolicitudCoco(model);
        } else {
            return;
        }
    }

    validateRadioButton(input: number, estadoCuenta: string) {

        this.radioDirection = input;
        let state = estadoCuenta == null ? '1' : estadoCuenta;
        this.labelRecepcion = state == '1' ? this.client.direccion : this.client.email;

        if (input == 1) {
            this.stateAccountForm.controls['anotherAdrees'].enable();
            this.stateAccountForm.controls['anotherAdrees'].setValidators([Validators.required]);
            this.stateAccountForm.controls['anotherAdrees'].updateValueAndValidity();
        } else {
            this.stateAccountForm.controls['anotherAdrees'].disable();
            this.stateAccountForm.controls['anotherAdrees'].clearValidators();
            this.stateAccountForm.controls['anotherAdrees'].updateValueAndValidity();
        }
    }

    viewInfoTC() {
        const initialState = {
            data: this.textInfoTC
        }
        this.modalSchedule = this.modalService.show(InfoInsuranceModalComponent, {
            class: 'modal-dialog-centered',
            backdrop: 'static',
            keyboard: false,
            initialState
        });
    }

    changeClientPEP() {
        if(this.stateAccountForm.value['clientpep'] == true) {
            this.TcmessagePEP = 'Realizar proceso de clientes PEP';
        }
        else {
            this.TcmessagePEP = '';
        }
    }

    changeAceptaCuentaAhorro() {
        if(this.stateAccountForm.value['aceptaCuentaAhorro'] != true) {
            this.stateAccountForm.get('tipoCuentaAhorro').setValue(null);
        }
    }
}
