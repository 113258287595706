import { NgModule } from '@angular/core';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { LoadingComponent } from '../components/loading/loading.component';
import { OnlyLettersDirective } from '../../directives/only-letters.directive';
import { OnlyNumbersDirective } from '../../directives/only-numbers.directive';
import { OnlyDecimalDirective } from '../../directives/only-decimal.directive';
import { HelperService } from '../services/helper.service';
import { CleanBlanksDirective } from '../../directives/clean-blanks';
import { OnlyTextDirective } from '../../directives/only-text.directive';
import { OnPasteDirective } from '../../directives/on-paste-denied.directive';
import { OnlyAlphanumericDirective } from '../../directives/only-alphanumeric.directive';
import { OnlyTwoDecimalDirective } from '../../directives/only-two-decimal.directive';

import { EmailFormatPipe } from '../../pipes/email-format.pipe';
import { NumberFormatPipe } from '../../pipes/number-format.pipe';
import { MensajeFinalComponent } from '../components/mensaje-final/mensaje-final.component';
import { FormService } from '../services/validation.service';
import { NumberTwoDecimalsPipe } from '../../pipes/number-two-decimals.pipe';
import { PrettyPrintPipe } from '../../pipes/pretty-print.pipe';
import { RequestDetailModalComponent } from '../components/modals/request-detail-modal/request-detail-modal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { ClientDetailModalComponent } from '../components/modals/client-detail-modal/client-detail-modal.component';
import { DirectionDetailModalComponent } from '../components/modals/direction-detail-modal/direction-detail-modal.component';
import { ReferenceDetailModalComponent } from '../components/modals/reference-detail-modal/reference-detail-modal.component';
import { DisbursementDetailModalComponent } from '../components/modals/disbursement-detail-modal/disbursement-detail-modal.component';
import { AttachmentDetailModalComponent } from '../components/modals/attachment-detail-modal/attachment-detail-modal.component';
import { RequestStatusModalComponent } from '../components/modals/request-status-modal/request-status-modal.component';
import { ProductDetailModalComponent } from '../components/modals/product-detail-modal/product-detail-modal.component';
import { ProductEntidadDetailModalComponent } from '../components/modals/product-entidad-detail-modal/product-entidad-detail-modal.component';
import { TransferBankSchedulesComponent } from '../components/modals/transfer-bank-schedules/transfer-bank-schedules.component';
import { FinancialDataModalComponent } from '../components/modals/financial-data-modal/financial-data-modal.component';
import { FinancialSimulationModalComponent } from '../components/modals/financial-simulation-modal/financial-simulation-modal.component';
import { AccountStateModalComponent } from '../components/modals/account-state-modal/account-state-modal.component';
import { CocoCapptaModalComponent } from '../components/modals/coco-cappta-modal/coco-cappta-modal.component';
import { ConfirmationCancelSolicitudModalComponent } from '../components/modals/confirmation-cancel-solicitud-modal/confirmation-cancel-solicitud-modal.component';
import { TextT24Directive } from '../../directives/text-t24.directive';
import { OnbInputComponent } from '../components/onb-input/onb-input.component';
import { OnbTextareaComponent } from '../components/onb-textarea/onb-textarea.component';
import { OnbSelectComponent } from '../components/onb-select/onb-select.component';
import { OnbInformationUserComponent } from '../components/onb-information-user/onb-information-user.component';
import { OnbInformationUserFloatComponent } from '../components/onb-information-user-float/onb-information-user-float.component';
import { OnbSearchComponent } from '../components/onb-search/onb-search.component';
import { OnbInputCheckComponent } from '../components/onb-input-check/onb-input-check.component';
import { OnbCheckboxGroupComponent } from '../components/onb-checkbox-group/onb-checkbox-group.component';
import { OnbPinpadButtonComponent } from '../components/onb-pinpad-button/onb-pinpad-button.component';
import { ProductEfexModalComponent } from '../components/modals/product-efex-modal/product-efex-modal.component';
import { RequestAnulacionModalComponent } from '../components/modals/request-anulacion-modal/request-anulacion-modal.component';
import { InfoInsuranceModalComponent } from '../components/modals/info-insurance-modal/info-insurance-modal.component';
import { OnbClientReniecComponent } from '../components/onb-client-reniec/onb-client-reniec.component';
import { OnbFooterComponent } from '../components/onb-footer/onb-footer.component';
import { OnbDialogComponent } from '../components/onb-dialog/onb-dialog.component';
import { RequestProcessComponent } from '../components/modals/request-process/request-process.component';
import { SolicitudHeaderComponent } from '../components/solicitud-header/solicitud-header.component';
import { OnbValidacionPinpadComponent } from '../components/onb-validacion-pinpad/onb-validacion-pinpad.component';
import { SolicitudSuccessComponent } from '../components/solicitud-success/solicitud-success.component';
import { OnbValidacionDactilarComponent } from '../components/onb-validacion-dactilar/onb-validacion-dactilar.component';
import { DocumentValidatorDirective } from '../directives/document-validator.directive';
import { RequestStatusBoModalComponent } from '../components/modals/request-status-bo-modal/request-status-bo-modal.component';
import { OnbPinpadButtonTdComponent } from '../components/onb-pinpad-button-td/onb-pinpad-button-td.component';
import { SolicitudSupervisorComponent } from '../components/solicitud-supervisor/solicitud-supervisor.component';
import { ConfirmationModalComponent } from '../components/modals/confirmation-modal/confirmation-modal.component';
import { RequestProcessCancelModalComponent } from '../components/modals/request-process-cancel-modal/request-process-cancel-modal.component';



@NgModule({
    imports: [
        CommonModule,
        ModalModule.forRoot(),
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPaginationModule
    ],
    declarations: [
        LoadingComponent,
        RequestDetailModalComponent,
        ClientDetailModalComponent,
        DirectionDetailModalComponent,
        ReferenceDetailModalComponent,
        DisbursementDetailModalComponent,
        AttachmentDetailModalComponent,
        RequestStatusModalComponent,
        RequestStatusBoModalComponent,
        ProductDetailModalComponent,
        ProductEntidadDetailModalComponent,
        TransferBankSchedulesComponent,
        InfoInsuranceModalComponent,
        FinancialDataModalComponent,
        FinancialSimulationModalComponent,
        AccountStateModalComponent,
        CocoCapptaModalComponent,
        OnbInputComponent,
        ConfirmationCancelSolicitudModalComponent,
        OnlyLettersDirective,
        OnlyNumbersDirective,
        OnlyDecimalDirective,
        CleanBlanksDirective,
        TextT24Directive,
        OnlyTextDirective,
        OnPasteDirective,
        OnlyAlphanumericDirective,
        OnlyTwoDecimalDirective,
        EmailFormatPipe,
        NumberFormatPipe,
        MensajeFinalComponent,
        NumberTwoDecimalsPipe,
        PrettyPrintPipe,
        OnbTextareaComponent,
        OnbSelectComponent,
        OnbInformationUserComponent,
        OnbInformationUserFloatComponent,
        OnbSearchComponent,
        OnbInputCheckComponent,
        OnbCheckboxGroupComponent,
        OnbPinpadButtonComponent,
        OnbPinpadButtonTdComponent,
        ProductEfexModalComponent,
        RequestAnulacionModalComponent,
        OnbClientReniecComponent,
        OnbFooterComponent,
        OnbDialogComponent,
        RequestProcessComponent,
        SolicitudHeaderComponent,
        SolicitudSuccessComponent,
        SolicitudSupervisorComponent,
        OnbValidacionPinpadComponent,
        OnbValidacionDactilarComponent,
        DocumentValidatorDirective,
        OnbPinpadButtonTdComponent,
        ConfirmationModalComponent,
        RequestProcessCancelModalComponent
    ],
    providers: [
        FormService,
        HelperService
    ],
    exports: [
        CommonModule,
        ModalModule,
        NgxDatatableModule,
        FormsModule,
        ReactiveFormsModule,
        LoadingComponent,
        MensajeFinalComponent,
        OnlyLettersDirective,
        OnlyNumbersDirective,
        OnlyDecimalDirective,
        CleanBlanksDirective,
        OnlyTextDirective,
        OnPasteDirective,
        OnlyAlphanumericDirective,
        OnlyTwoDecimalDirective,
        NumberFormatPipe,
        EmailFormatPipe,
        NumberTwoDecimalsPipe,
        PrettyPrintPipe,
        TextT24Directive,
        NgxPaginationModule,
        OnbInputComponent,
        OnbTextareaComponent,
        OnbSelectComponent,
        OnbInformationUserComponent,
        OnbInformationUserFloatComponent,
        OnbSearchComponent,
        OnbInputCheckComponent,
        OnbCheckboxGroupComponent,
        OnbPinpadButtonComponent,
        OnbPinpadButtonTdComponent,
        OnbClientReniecComponent,
        OnbFooterComponent,
        SolicitudHeaderComponent,
        SolicitudSuccessComponent,
        SolicitudSupervisorComponent,
        OnbValidacionPinpadComponent,
        OnbValidacionDactilarComponent,
        DocumentValidatorDirective,
        OnbPinpadButtonTdComponent,
        ConfirmationModalComponent
    ],
    entryComponents: [RequestDetailModalComponent,
        ClientDetailModalComponent,
        DirectionDetailModalComponent,
        ReferenceDetailModalComponent,
        DisbursementDetailModalComponent,
        AttachmentDetailModalComponent,
        RequestStatusModalComponent,
        RequestStatusBoModalComponent,
        ProductDetailModalComponent,
        ProductEntidadDetailModalComponent,
        TransferBankSchedulesComponent,
        FinancialDataModalComponent,
        FinancialSimulationModalComponent,
        AccountStateModalComponent,
        CocoCapptaModalComponent,
        InfoInsuranceModalComponent,
        ConfirmationCancelSolicitudModalComponent,
        ProductEfexModalComponent,
        RequestAnulacionModalComponent,
        OnbDialogComponent,
        RequestProcessComponent,
        ConfirmationModalComponent,
        RequestProcessCancelModalComponent]
})

export class SharedModule {

}
