import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'solicitud-success',
  templateUrl: './solicitud-success.component.html',
  styleUrls: ['./solicitud-success.component.scss'],
})
export class SolicitudSuccessComponent implements OnInit {
  @Output() return = new EventEmitter();
  constructor() { }

  ngOnInit() {}

  onReturn() {
    this.return.emit();
  }

}
