import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { EmitterService } from '../../../services/emitter.service';

@Component({
    encapsulation: ViewEncapsulation.None,
  selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  subscription: Subscription;
  bandejaActive: boolean = false;
  constructor(private emitterService: EmitterService) {
    this.subscription = this.emitterService.getMessage().subscribe(message => {
      this.bandejaActive = message == "/bandeja" ? true : false;
    });
  }

  ngOnInit() {

  }

}
