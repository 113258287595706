export class Rechazo {
  solId: string;
  worFlowId: string;
  motivoRechazoId: string;
  motivoRechazo: string;
  userId: string;
  userRol: string;
  processId: number;
  channel: number;
  taskId: string;
}
