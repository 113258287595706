import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenModel } from '../models/user';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private router: Router
  ) { }

  public GetAuthTokenAsync(username: string, password: string): Observable<TokenModel> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
        'Accept': 'application/json'
      })
    };

    return this.httpClient.post<TokenModel>(environment.identityApiEndPointToken,
      `client_id=web&grant_type=mysqldb&username=${username}&password=${password}`,
      httpOptions);
  }

  logout() {
    sessionStorage.clear();
    this.router.navigate(['/login'], { queryParams: { returnUrl: '' } });
  }
}
