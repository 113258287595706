import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: "emailFormatPipe"
})

export class EmailFormatPipe implements PipeTransform {
  transform(value: string): string {
    if (value.length > 25) {
      return value.substr(0, 22) + "...";
    }
    return value;
  }
}
