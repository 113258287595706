import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class AlertPopupService {

  constructor(private alertController: AlertController) { }

  async Alert(title : string, subtitle: string, message: string) {
    const alert = await this.alertController.create({
      cssClass: 'class-popupnotification',
      header: title,
      subHeader: subtitle,
      message: message,
      buttons: ['Cerrar']
    });
    await alert.present();
  }
  
}
