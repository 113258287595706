import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Client } from '../../../../models/client';
import { ClientService } from '../../../../services/client.service';
import { ParameterService } from '../../../../services/parameter.service';
import { AppConstants } from '../../../constants/app.constants';

@Component({
    selector: 'app-client-detail-modal',
    templateUrl: './client-detail-modal.component.html',
    styleUrls: ['./client-detail-modal.component.css'],
})
export class ClientDetailModalComponent implements OnInit {
    clienteForm: FormGroup;
    data: any;

    disabled: boolean = true;
    typeEducation = [];
    typeOcupacion = [];
    typeActividad = [];
    estadoCivilList = [];

    imgUserCircle: string = './assets/images/icon-user-circle.png';

    loading: boolean = false;
    client: Client = new Client();

    constructor(
        private formBuilder: FormBuilder,
        private modalClientDetailRef: BsModalRef,
        private clientService: ClientService,
        private parameterService: ParameterService
    ) { }

    ngOnInit() {
        this.createForm();
        this.getDataSelects();
        this.callGetClient(this.data.numeroSolicitud);
    }

    createForm() {
        this.clienteForm = this.formBuilder.group({
            numeroDocumento: '',
            fecEmision: '',
            situacion: '',
            nombres: ['', Validators.compose([
                Validators.required,
                , this.ValidatorNoWhitespace
            ])],
            apellidoPaterno: ['', Validators.compose([
                Validators.required,
                , this.ValidatorNoWhitespace
            ])],
            apellidoMaterno: ['', Validators.compose([
                Validators.required,
                , this.ValidatorNoWhitespace
            ])],
            fecNacimiento: ['', Validators.required],
            genero: ['', Validators.required],
            nivelEducacion: ['', Validators.required],
            tipoOcupacion: ['', Validators.required],
            tipoActividad: ['', Validators.required],
            empresaLabora: ['', Validators.compose([
                Validators.required,
                , this.ValidatorNoWhitespace
            ])],
            email: ['', Validators.compose([
                Validators.required,
                Validators.pattern(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
            ])],
            phone: ['', Validators.compose([
                Validators.required,
                Validators.pattern('^9[0-9]{8}$')
            ])],
            estadoCivil: ['', Validators.required],
            middleName: ''
        });
    }

    close() {
        this.modalClientDetailRef.hide();
    }

    ValidatorNoWhitespace(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    getDataSelects() {
        this.getTypesEducation(AppConstants.ParameterCode.NIVEL_EDUCACION);
        this.getTypesActividad(AppConstants.ParameterCode.TIPO_ACTIVIDAD);
        this.getTypesOcupacion(AppConstants.ParameterCode.OCUPACION);
        this.getEstadoCivil(AppConstants.ParameterCode.ESTADO_CIVIL);
    }

    getTypesEducation(code: number) {
        this.loading = true;
        this.parameterService.getParameters(code).subscribe((response) => {
          this.typeEducation = response;
          this.loading = false;
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    getTypesActividad(code: number) {
        this.loading = true;
        this.parameterService.getParameters(code).subscribe((response) => {
          this.typeActividad = response;
          this.loading = false;
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    getTypesOcupacion(code: number) {
        this.loading = true;
        this.parameterService.getParameters(code).subscribe((response) => {
          this.typeOcupacion = response;
          this.loading = false;
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    getEstadoCivil(code: number) {

        this.loading = true;
        this.parameterService.getParameters(code).subscribe((response) => {
          this.estadoCivilList = response;
          this.loading = false;
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    callGetClient(code: string) {
        this.loading = true;
        this.clientService.getClient(code).subscribe((response) => {
            this.client = response;
            this.setValues();
            this.loading = false;
        },
        (error: HttpErrorResponse) => {
        this.loading = false;
        });
    }

    setValues() {
        this.clienteForm.patchValue({
          numeroDocumento: this.client.numeroDocumento,
          fecEmision: this.client.fecEmision,
          situacion: this.client.situacion,
          nombres: this.client.nombres,
          apellidoPaterno: this.client.apellidoPaterno,
          apellidoMaterno: this.client.apellidoMaterno,
          fecNacimiento: this.client.fecNacimiento,
          genero: this.client.genero,
          nivelEducacion: this.client.nivelEducacion,
          tipoOcupacion: this.client.profesion,
          tipoActividad: this.client.tipoActividad,
          empresaLabora: this.client.empresaLabora,
          email: this.client.email,
          phone: this.client.phone,
          estadoCivil: this.client.estadoCivil,
          middleName: this.client.middleName
        });
    }
}
