import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { Observation, Comments } from '../models/observation';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ObservationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "Observation";
  private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  createObservacion(model: Observation): Observable<Observation> {
    return this.httpClient.post<Observation>(`${this.REQUEST_URL}/Create`, model);
  }
  getComentarios(code: string): Observable<Comments[]> {
    return this.httpClient.get<Comments[]>(`${this.REQUEST_URL}/${code}`);
  }
}
