import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Console } from 'console';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { Client } from '../../../../models/client';
import { Parameter } from '../../../../models/parameter';
import { ClientService } from '../../../../services/client.service';
import { ParameterService } from '../../../../services/parameter.service';
import { AppConstants } from '../../../constants/app.constants';
import { AlertService, MessageSeverity } from '../../../services/alert.service';

@Component({
  selector: 'app-financial-data-modal',
  templateUrl: './financial-data-modal.component.html',
  styleUrls: ['./financial-data-modal.component.scss'],
})
export class FinancialDataModalComponent implements OnInit {

  data: any;
  client: Client = new Client();

  loading: boolean = false;

  questionForm: FormGroup;
  questionSubmit: boolean = false;
  questionTwoList = [];
  questionFourList = [];  

  datosFinancierosMessageErrors = {
    questionTwo: '',
    questionFour: ''
  };

  isView: boolean;

  public onClose: Subject<any>;

  constructor(
    private formBuilder: FormBuilder,
    private modalfinancialdata: BsModalRef,
    private alertaService: AlertService,
    private parameterService: ParameterService,
    private clientService: ClientService
  ) { }

  async ngOnInit() {
    console.log(this.data);
    this.onClose = new Subject();
    this.createForm();
    await this.callGetClient(this.data.solId);
    await this.GetParameters([
      AppConstants.ParameterCode.CLIENT_DATOS_FINANCIEROS_1,
      AppConstants.ParameterCode.CLIENT_DATOS_FINANCIEROS_2
    ]); 
  }

  createForm() {
    this.questionForm = this.formBuilder.group({
      questionTwo: ['', Validators.compose([
        Validators.required
      ])],
      questionFour: ['', Validators.compose([
        Validators.required
      ])],
    });
  }

  datosFinancierosEvaluateError(formControlName: string, label: string): boolean {
    var Errores = this.questionForm.get(formControlName).errors;
    var Dirty: boolean = this.questionForm.get(formControlName).dirty;
    var Touched: boolean = this.questionForm.get(formControlName).touched;

    if (formControlName != null) {
      if (Errores != null) {
        if (Dirty && Touched || this.questionSubmit) {
          if ((Errores['required'] || Errores['whitespace']) && label != '') {
            this.datosFinancierosMessageErrors[formControlName] = 'Ingrese ' + label + '';
          }
          return true;
        }
      }
    }
    return false;
  }

  async callGetClient(code: string) {
    this.loading = true;
    this.clientService.getClient(code).toPromise().then((response) => {
      this.client = response;
      this.questionForm.patchValue({
        questionTwo: this.client.clieDatoFinanciero01,
        questionFour: this.client.clieDatoFinanciero02
      });
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  async GetParameters(array: Array<number>) {
    let modelRequest = { headerId: array };
    this.loading = true;
    await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
      this.setVariablesParameters(response);
      this.loading = false;
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  setVariablesParameters(listParameter: Parameter[]) {

    this.questionTwoList = (listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.CLIENT_DATOS_FINANCIEROS_1 && f.tabDetState == AppConstants.StateFlag.Activo)).sort((a, b) => (a.tabDetCode003 < b.tabDetCode003 ? -1 : 1));
    this.questionFourList = (listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.CLIENT_DATOS_FINANCIEROS_2 && f.tabDetState == AppConstants.StateFlag.Activo)).sort((a, b) => (a.tabDetCode003 < b.tabDetCode003 ? -1 : 1));

  }

  close() {    
    this.modalfinancialdata.hide();
  }

  updateDatosFinancieros() {
    this.setDatosFinancieros(this.questionForm);
    this.UpdateClient(this.client);    
  }

  setDatosFinancieros(questionForm: FormGroup) {
    this.client.clieDatoFinanciero01 = questionForm.value["questionTwo"];
    this.client.clieDatoFinanciero02 = questionForm.value["questionFour"];
  }

  UpdateClient(model: Client) {
    this.loading = true;
    this.clientService.updateClient(model).subscribe((response) => {
      this.loading = false;
      this.alertaService.showMessage(AppConstants.TitleModal.UPDATE_DATA_FINANCIERA, AppConstants.MessageModal.UPDATE_DATA_FINANCIERA, MessageSeverity.success);
      this.onClose.next(this.client);
      this.modalfinancialdata.hide();
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.alertaService.showMessage(AppConstants.TitleModal.UPDATE_DATA_FINANCIERA, error.error.messages, MessageSeverity.error);
      });
  }

}
