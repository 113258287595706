import { Injectable } from '@angular/core';
import { ToastaService, ToastOptions } from 'ngx-toasta';
import { Subject, Observable } from 'rxjs';



@Injectable()
export class AlertService {


  constructor(private toastaService: ToastaService) {

  }

  private dialogs = new Subject<AlertDialog>();

  public showMessage(title, message, severity, timeout = 7000) {
    var toastOptions: ToastOptions = {
      title: title,
      msg: message,
      showClose: true,
      timeout: timeout,
      theme: 'bootstrap'
    };
    if (severity == MessageSeverity.info)
      this.toastaService.info(toastOptions);
    else if (severity == MessageSeverity.default)
      this.toastaService.default(toastOptions);
    else if (severity == MessageSeverity.success)
      this.toastaService.success(toastOptions);
    else if (severity == MessageSeverity.error)
      this.toastaService.error(toastOptions);
    else if (severity == MessageSeverity.warn)
      this.toastaService.warning(toastOptions);
    else if (severity == MessageSeverity.wait)
      this.toastaService.wait(toastOptions);
  }

  public showMessageSimulator(title, message, severity, timeout = 10000) {
    var toastOptions: ToastOptions = {
      title: title,
      msg: message,
      showClose: true,
      timeout: timeout,
      theme: 'bootstrap'
    };
    if (severity == MessageSeverity.info)
      this.toastaService.info(toastOptions);
    else if (severity == MessageSeverity.default)
      this.toastaService.default(toastOptions);
    else if (severity == MessageSeverity.success)
      this.toastaService.success(toastOptions);
    else if (severity == MessageSeverity.error)
      this.toastaService.error(toastOptions);
    else if (severity == MessageSeverity.warn)
      this.toastaService.warning(toastOptions);
    else if (severity == MessageSeverity.wait)
      this.toastaService.wait(toastOptions);
  }

  showDialog(message: string)
  showDialog(message: string, type: DialogType, okCallback: (val?: any) => any)
  showDialog(message: string, type: DialogType, okCallback?: (val?: any) => any, cancelCallback?: () => any, okLabel?: string, cancelLabel?: string, defaultValue?: string)
  showDialog(message: string, type?: DialogType, okCallback?: (val?: any) => any, cancelCallback?: () => any, okLabel?: string, cancelLabel?: string, defaultValue?: string) {

    if (!type)
      type = DialogType.alert;

    this.dialogs.next({ message: message, type: type, okCallback: okCallback, cancelCallback: cancelCallback, okLabel: okLabel, cancelLabel: cancelLabel, defaultValue: defaultValue });
  }

  getDialogEvent(): Observable<AlertDialog> {
    return this.dialogs.asObservable();
  }

}

export enum MessageSeverity {
  default,
  info,
  success,
  error,
  warn,
  wait
}


//******************** Dialog ********************//
export class AlertDialog {
  constructor(public message: string, public type: DialogType, public okCallback: (val?: any) => any, public cancelCallback: () => any,
    public defaultValue: string, public okLabel: string, public cancelLabel: string) {

  }
}

export enum DialogType {
  alert,
  confirm,
  prompt
}
//******************** End ********************//


