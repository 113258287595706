import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ControlContainer } from '@angular/forms';
import { Option } from '../../../models/option';

@Component({
  selector: 'onb-select',
  templateUrl: './onb-select.component.html',
  styleUrls: ['./onb-select.component.css'],
})
export class OnbSelectComponent implements OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() options: Option[];
  @Output() change = new EventEmitter<string>();

  control: FormControl;

  rules = {
    required: (props, label) => `Seleccione ${label}`,
    noWhiteSpaceValidator: (props, label) => `Ingrese ${label}`
  };

  constructor(private controlContainer: ControlContainer) {
  }
 

  ngOnInit(): void {
    const formGroup = this.controlContainer.control as FormGroup;
    this.control = formGroup.controls[this.name] as FormControl;
    //this.control.updateValueAndValidity();
  }

  isInvalid(): boolean {
    return this.control.invalid && this.control.touched;
  }

  onChange(event: Event) {    
    const selectedValue = (event.target as HTMLInputElement).value;;
    this.change.emit(selectedValue);
  }

  getErrorMessage() {
    let message = '';
    for (const error in this.control.errors) {
      // Si el error actual tiene una función de mensaje de error correspondiente
      if (this.rules[error]) {
        // Llama a la función de mensaje de error con los detalles del error
        message = this.rules[error](this.control.errors[error], this.label);
        break;
      }
    }
    return message;
  }
}