import { Directive, ElementRef, HostListener } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[CleanBlanks]'
})
export class CleanBlanksDirective {
  constructor(private element: ElementRef, private control: NgControl) { }

  oldValue: string;

  @HostListener('keydown', ['$event'])
  onkeydown(event: any) {
    this.oldValue = event.target.value;
  }

  @HostListener('input', ['$event'])
  oninput(event: any) {
      this.control.control.setValue(this.control.control.value.trim());
  }
}
