import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-confirmation-cancel-solicitud-modal',
  templateUrl: './confirmation-cancel-solicitud-modal.component.html',
  styleUrls: ['./confirmation-cancel-solicitud-modal.component.scss'],
})
export class ConfirmationCancelSolicitudModalComponent implements OnInit {

  data: any;
  public onClose: Subject<any>;

  constructor(
    private modalConfirmation: BsModalRef,
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  buttonAccep(data : any){
    this.onClose.next(data);
      this.modalConfirmation.hide();
  }

  close() {    
    this.modalConfirmation.hide();
  }

}
