import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AppConstants, PINPAD_ERROR_CODE, TipoSolicitudBO } from '../../constants/app.constants';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { ParameterService } from '../../../services/parameter.service';
import { ResponseValidate } from '../../../models/solicitud';
import { HttpErrorResponse } from '@angular/common/http';
import { Parameter } from '../../../models/parameter';
import { SolicitudBoSession } from '../../../models/solicitud-bo';

@Component({
  selector: 'onb-validacion-pinpad',
  templateUrl: './onb-validacion-pinpad.component.html',
  styleUrls: ['./onb-validacion-pinpad.component.scss'],
})
export class OnbValidacionPinpadComponent implements OnInit {

  loading: boolean = false;
  @Input() solicitud: SolicitudBoSession;
  @Output() return = new EventEmitter();
  @Output() success = new EventEmitter();

  tipoAutenticacion: string = "";
  listaParameters = [];
  attempst: number = 0;
  maxAttempts: number;
  pinpadAction: boolean = true;
  pinpadParameters:string = "";
  textoLegalData: Parameter[] = [];
  messageLegal: string = '';

  byPassPinPad: boolean = false;

  constructor(
    private alertaService: AlertService,
    private parameterService: ParameterService,
  ) { }

  async ngOnInit() {
    this.pinpadParameters = this.solicitud.solicitudBOId;
    await this.GetParameters([
      AppConstants.ParameterCode.ADICIONAL_PARAMETERS,
      AppConstants.ParameterCode.TEXT_LEGAL_PINPAD
    ]);
  }

  onStartx() {
    this.loading = true;
    this.validacionPipad().subscribe(response =>{
      if(response){
        this.success.emit();
        this.loading = false;
      }
    })
  }

  onReturn(){
    this.return.emit();
  }

  validacionPipad(){
    return of(true).pipe(
      delay(3000) // Retrasa la emisión por 3 segundos
    );
  }

  processAttempts(): boolean {
    let lastAttempt: boolean = false;
    this.attempst++;

    lastAttempt = this.attempst >= this.maxAttempts;
    if(lastAttempt)
    {
      this.alertaService.showMessage(AppConstants.TitleModal.VALIDATION_PINPAD, AppConstants.MessageModal.MESSAGE_ERROR_ATTEMPTS, MessageSeverity.error);
      this.pinpadAction = false;
    }

    return lastAttempt;
  }

  async onStart(result: ResponseValidate) {
    this.loading = true;
    console.log('resultado final:', result);

    if (result.isValid) {
        this.success.emit();
        this.loading = false;
    } else {
        this.loading = false;
        this.handleError(result);
    }
  }

  handleError(result: ResponseValidate) {
    const errorMessages = {
        [PINPAD_ERROR_CODE.ERROR_VALIDATE_PIN]: AppConstants.MessageModal.MESSAGE_ERROR_PIN,
        [PINPAD_ERROR_CODE.ERROR_VALIDATE_CARD_NUMBER]: AppConstants.MessageModal.MESSAGE_ERROR_VALIDATE_CARD_NUMBER,
        [PINPAD_ERROR_CODE.ERROR_EMPTY_CARD_NUMBER]: AppConstants.MessageModal.MESSAGE_PINPAD_SERVICE_ERROR,
        [PINPAD_ERROR_CODE.ERROR_EMPTY_MASKPAN]: AppConstants.MessageModal.MESSAGE_PINPAD_SERVICE_ERROR,
        [PINPAD_ERROR_CODE.ERROR_EMPTY_CODE_RESULT]: AppConstants.MessageModal.MESSAGE_PINPAD_SERVICE_ERROR,
        [PINPAD_ERROR_CODE.ERROR_VALIDATE_SERVICE_TICKET]: AppConstants.MessageModal.MESSAGE_PINPAD_SERVICE_ERROR,
        [PINPAD_ERROR_CODE.ERROR_REGISTER_TRACE]: AppConstants.MessageModal.MESSAGE_PINPAD_SERVICE_ERROR,
        [PINPAD_ERROR_CODE.ERROR_EXCEPTION_RESPONSE_PINPAD]: result.message,
        [PINPAD_ERROR_CODE.ERROR_HARDWARE]: AppConstants.MessageModal.MESSAGE_ERROR_HARDWARE_PINPAD
    };

    const message = errorMessages[result.resultCode] || AppConstants.MessageModal.MESSAGE_ERROR_CONNECT_PINPAD + (result.resultCode ? `:${result.resultCode}` : "");
    this.alertaService.showMessage(AppConstants.TitleModal.VALIDATION_PINPAD, message, MessageSeverity.error);

  }


  async GetParameters(array: Array<number>) {
    let modelRequest = { headerId: array };
    this.loading = true;
    await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
      this.listaParameters = response;
      this.setVariablesParameters(response);
      this.loading = false;
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  getCodProd(tipoSolicitud: number){
    let codProd = "000";
    if(tipoSolicitud == TipoSolicitudBO.IncrementoLinea)
      codProd = AppConstants.Products.IL
    return codProd;
  }

  setVariablesParameters(listParameter: Parameter[]) {
    this.maxAttempts = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS && f.tabDetCode001 == AppConstants.AdicionalParameterCode.REINTENTOS_PINPAD && f.tabDetState == AppConstants.StateFlag.Activo)[0]["tabDetCode003"];

    this.textoLegalData = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.TEXT_LEGAL_PINPAD && f.tabDetState == AppConstants.StateFlag.Activo);
    const textoLegal = this.textoLegalData.find(f=>f.tabDetCode001 == this.getCodProd(this.solicitud.tipoSolicitud) && f.tabDetCode003 == this.solicitud.tipoSolicitud);
    if(textoLegal){
      const message = (textoLegal.tabDetCode006 || '') + (textoLegal.tabDetCode007 || '') + (textoLegal.tabDetCode008 || '') + (textoLegal.tabDetCode009 || '');
      this.messageLegal = this.prepararDatos(message);
    }
  }

  prepararDatos(datos) {
    let result = datos.split('-SALTO').join('<br />');
    return result;
  }

}
