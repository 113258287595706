import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { Rechazo } from '../models/rechazo';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RechazoService {

    constructor(
        private httpClient: HttpClient
    ) { }

    private POST_ENDPOINT: string = "Rechazo";
    private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    createRechazo(model: Rechazo): Observable<Rechazo> {
        return this.httpClient.post<Rechazo>(`${this.REQUEST_URL}/Create`, model);
    }
}
