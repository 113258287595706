import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import { HelperService } from '../shared/services/helper.service';
import { environment } from '../../environments/environment';
import { VerificationSMS, VerificationValidateSMS, VerificationDactilar, VerificationDactilarResponse, Accept, AuthenticationInitiation, AuthenticationUpdation } from '../models/verification';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VerificationService {

  constructor(
      private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "Verification";
  private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  sendSMS(model: VerificationSMS): Observable<VerificationSMS> {
    return this.httpClient.post<VerificationSMS>(`${this.REQUEST_URL}/SMS`, model);
  }

  sendValidateSMS(model: VerificationValidateSMS): Observable<VerificationValidateSMS> {
    return this.httpClient.post<VerificationValidateSMS>(`${this.REQUEST_URL}/SMS/Validate`, model);
  }

  validateDactilar(model: VerificationDactilar): Observable<VerificationDactilarResponse> {
    return this.httpClient.post<VerificationDactilarResponse>(`${this.REQUEST_URL}/Dactilar`, model);
  }

  accept(model: Accept): Observable<Response> {
    return this.httpClient.post<Response>(`${this.REQUEST_URL}/Accept`, model);
  }

  validateCaptcha(token: string) {

    let httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*'
      })
    };
    return this.httpClient.post<Boolean>(`${this.REQUEST_URL}/captcha`, JSON.stringify(token), httpOptions);
  }

  createAuthentication(model: AuthenticationInitiation) {
    return this.httpClient.post<boolean>(`${this.REQUEST_URL}/Authentication/initiation`, model);
  }

  updateAuthentication(model: AuthenticationUpdation) {
    return this.httpClient.post<boolean>(`${this.REQUEST_URL}/Authentication/updation`, model);
  }

}
