import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ControlContainer } from '@angular/forms';

@Component({
  selector: 'onb-input',
  templateUrl: './onb-input.component.html',
  styleUrls: ['./onb-input.component.css'],
})
export class OnbInputComponent implements OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() type: string;
  @Input() maxlength: number;
  @Input() disabled: boolean = false;

  control: FormControl;
  error:string;


  rules = {
    required: (props, label) => `Ingrese ${label}`,
    noWhiteSpaceValidator: (props, label) => `Ingrese ${label}.`,
    email: (props, label) => `Ingrese un email válido.`,
    notOnlyLetters: (props, label) => `El campo ${label} solo permite letras.`,
    min: (props, label) => `El valor mínimo permitido es ${props.min} y el valor actual es ${props.actual}.`,
    max: (props, label) => `El valor mínimo permitido es ${props.max} y el valor actual es ${props.actual}.`,
  };

  constructor(private controlContainer: ControlContainer) {
  }

  ngOnInit(): void {
    const formGroup = this.controlContainer.control as FormGroup;
    if (!formGroup.controls[this.name]) {
      this.error = `${this.name} is undefined.`;
      return;
    }
    this.control = formGroup.controls[this.name] as FormControl;
    this.control.updateValueAndValidity();
  }

  isInvalid(): boolean {
    return this.control.invalid && this.control.touched;
  }

  getErrorMessage() {
    let message = '';
    for (const error in this.control.errors) {
      // Si el error actual tiene una función de mensaje de error correspondiente
      if (this.rules[error]) {
        // Llama a la función de mensaje de error con los detalles del error
        message = this.rules[error](this.control.errors[error], this.label);
        break;
      }
    }
    return message;
  }
}