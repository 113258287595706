import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {EmitterService} from '../../services/emitter.service';
import {AppConstants} from '../../shared/constants/app.constants';

declare const hideMessage: any;
declare const showMessage: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

    agencyCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE);
    agencyName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME);
    usersessionId: string = sessionStorage.getItem(AppConstants.Session.USERID);
    channelName: string = sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION);

    constructor(private router: Router,
                private emitterService: EmitterService) {
    }

    ngOnInit() {
        if (sessionStorage.getItem(AppConstants.Session.USERROLID) == AppConstants.RolesCode.GESTOR || sessionStorage.getItem(AppConstants.Session.USERROLID) == AppConstants.RolesCode.ASESOR || sessionStorage.getItem(AppConstants.Session.USERROLID) == AppConstants.RolesCode.CALLCENTER) {
            this.router.navigateByUrl('productsList');
        }

        this.emitterService.sendMessage('/home');
    }

    showMessage() {
        showMessage();
    }

    hideMessage() {
        hideMessage();
    }

}
