import { BaseModel } from '../shared/models/base.model';

export class User {
    userId: string;
    nombreUsuario: string;
    passwordUsuario: string;
    emailUsuario: string;
    empresaId: string;
    estadoUsuario: number;
    estadoDesc: string;
    paisUsuario: string;
    agenciaUsuario: string;
    rolId: string;
    usuarioCreate: string;
    usuarioUpdate: string;
    usuarioCreateTime: string;
    usuarioUpdateTime: string;
    numeroDocumento: string;
    tipoDocumento: string;
    codigoBanco: string;
    saleschannel: string;
    salesSubChannelId: number;
    cancelSolicitud: number;
    locked: number;
    color: string;
}

export class TokenModel {
  access_token: string;
  expires_in: number;
  token_type: string;
}

export class UserValidateRequest {
    userId: string;
    userPassword: string;
}

export class UserValidateResponse {
    userId: string;
    userName: string;
    rolID: string
    rolNombre: string;
}

export class UserPasswordModel extends BaseModel {
    userId: string;
    currentPassword: string;
    newPassword: string;
    confirmNewPassword: string;
}


