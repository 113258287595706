import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appOnlyNumbersv2]'
})
export class OnlyNumbersDirectivev2 {
  constructor(private el: ElementRef) {}

  @HostListener('input', ['$event'])
  onInput(event: any) {
    this.el.nativeElement.value = this.el.nativeElement.value.replace(/\D/g, ''); // Elimina todo lo que no sea número
  }

  @HostListener('keypress', ['$event'])
  onKeyPress(event: KeyboardEvent) {
    if (event.key < '0' || event.key > '9') {
      event.preventDefault(); // Bloquea caracteres no numéricos en el teclado
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault(); // Previene el pegado de contenido no deseado
    const pastedText = event.clipboardData ? event.clipboardData.getData('text') : '';
    const sanitizedText = pastedText.replace(/\D/g, ''); // Filtra solo números
    document.execCommand('insertText', false, sanitizedText); // Inserta solo los números permitidos
  }
}