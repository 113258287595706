import {CurrencyPipe} from '@angular/common';
import {HttpErrorResponse} from '@angular/common/http';
import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs';
import {Client, UpdateClientSimulationReprograming} from '../../../../models/client';
import {Parameter} from '../../../../models/parameter';
import {Producto, RequestSimulatorReprograming} from '../../../../models/simular';
import {ClientService} from '../../../../services/client.service';
import {ParameterService} from '../../../../services/parameter.service';
import {SimulatorService} from '../../../../services/simulator.service';
import {AppConstants} from '../../../constants/app.constants';
import {AlertService, MessageSeverity} from '../../../services/alert.service';
import {HelperService} from '../../../services/helper.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-financial-simulation-modal',
    templateUrl: './financial-simulation-modal.component.html',
    styleUrls: ['./financial-simulation-modal.component.scss'],
})
export class FinancialSimulationModalComponent implements OnInit {

    data: any;
    client: Client = new Client();
    imgProducto: string = '../../../../assets/images/icon_s_RB2.png';
    imgSimulacion: string = './assets/images/icon-simulacion-2.png';

    loading: boolean = false;
    sproducto: Producto = JSON.parse(sessionStorage.getItem(AppConstants.ObjectCliente.PRODUCTO));
    productName = '';
    products = [];
    offerMaximo;
    diasgraciaProduct = [];
    mesesgracia: number = 0;
    cuotanueva: number = 0;
    txtMonto: boolean = true;
    monto: number = 0;
    rowsSimulacion = [];
    columnsSimulacion = [];
    disableMonto: true;
    tableClass: string = 'table-1-loan';
    ammountMinReeganche = [];
    disablePerGrace: boolean = false;

    public onClose: Subject<any>;

    constructor(
        private modalfinancialsimulation: BsModalRef,
        private alertaService: AlertService,
        private parameterService: ParameterService,
        private clientService: ClientService,
        private helperService: HelperService,
        private simulatorService: SimulatorService,
        private currencyPipe: CurrencyPipe
    ) {
    }

    async ngOnInit() {
        this.onClose = new Subject();
        this.createColumnsTable();
        this.imgProducto = this.sproducto.Imagen_m;
        await this.callGetClient(this.data.solId);
        await this.GetParameters([
            AppConstants.ParameterCode.SUB_PRODUCS,
            AppConstants.ParameterCode.DIAG_GRACIA_PRODUCT,
            AppConstants.ParameterCode.MONTO_MIN_REENGANCHE,
            AppConstants.ParameterCode.MODO_DESEMBOLSO_BLOQUEO
        ]);
    }

    private createColumnsTable(): void {
        this.columnsSimulacion = [
            {
                prop: 'quota',
                name: 'Nº de Cuotas',
                width: 120,
                headerClass: 'text-center',
                resizeable: false,
                cellClass: 'text-center',
                draggable: false
            },
            {
                prop: 'ammountQuotaFormat',
                name: 'Monto de Cuota',
                width: 140,
                headerClass: 'text-center',
                resizeable: false,
                cellClass: 'text-center',
                draggable: false
            },
            {
                prop: 'teaFormat',
                name: 'TEA',
                width: 120,
                headerClass: 'text-center',
                resizeable: false,
                cellClass: 'text-left',
                draggable: false
            },
            {
                prop: 'tceaFormat',
                name: 'TCEA',
                width: 130,
                headerClass: 'text-center',
                resizeable: false,
                cellClass: 'text-center',
                draggable: false
            },
            {
                prop: 'interestsFormat',
                name: 'Intereses',
                width: 130,
                headerClass: 'text-center',
                resizeable: false,
                cellClass: 'text-center',
                draggable: false
            },
            {
                prop: 'totalFormat',
                name: 'Importe Total',
                width: 120,
                headerClass: 'text-center',
                resizeable: false,
                cellClass: 'text-center',
                draggable: false
            },
            {
                prop: 'firstPaymentFormat',
                name: 'Primer Pago',
                width: 120,
                headerClass: 'text-center',
                resizeable: false,
                cellClass: 'text-center',
                draggable: false
            },
        ];
    }

    async callGetClient(code: string) {
        this.loading = true;
        this.clientService.getClient(code).toPromise().then((response) => {
                this.client = response;
                this.offerMaximo = this.transform(this.client.solAmount, this.client.solMoneda);
                this.monto = this.client.solAmount;
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
            });
    }

    async GetParameters(array: Array<number>) {
        let modelRequest = {headerId: array};
        this.loading = true;
        await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
                this.setVariablesParameters(response);
                this.loading = false;
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
            });
    }

    setVariablesParameters(listParameter: Parameter[]) {
        this.products = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.SUB_PRODUCS && f.tabDetState == AppConstants.StateFlag.Activo);
        if (this.sproducto.Codigo === AppConstants.Products.REENGANCHESEF) {
            this.productName = this.helperService.getNameProduct(this.client.offerProduct, this.client.offerCodBase, this.products, this.sproducto.indicadorOrigenSef, this.sproducto.BASE_ORIGEN);
        } else {
            this.productName = this.helperService.getNameProduct(this.client.offerProduct, this.client.offerCodBase, this.products, this.client.ofeIndConvPef, this.sproducto.BASE_ORIGEN);
        }

        // this.productName = this.helperService.getNameProduct(this.data.offerProduct, this.data.offerCodBase, this.products, this.data.ofeIndConvPef);
        this.diasgraciaProduct = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.DIAG_GRACIA_PRODUCT && f.tabDetState == AppConstants.StateFlag.Activo && f.tabDetCode001 == this.data.offerProduct).sort((a, b) => (a.tabDetCode003 < b.tabDetCode003 ? -1 : 1));
        this.ammountMinReeganche = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.MONTO_MIN_REENGANCHE && f.tabDetState == AppConstants.StateFlag.Activo);
        this.disablePerGrace = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.MODO_DESEMBOLSO_BLOQUEO && f.tabDetState == AppConstants.StateFlag.Activo && f.tabDetCode001 == this.data.offerProduct && this.data.offerCodBase.trim().toUpperCase().includes(f.tabDetCode002)).length > 0 ? true : false;
    }

    close() {
        this.modalfinancialsimulation.hide();
    }

    transform(monto: number, tipoMoneda: number) {
        return this.formatNumberFixedPointSeparator(String(monto), 2, '.', ',', tipoMoneda);
    }

    formatNumberFixedPointSeparator(decimals: string, decimal_fixed?: number, decimal_point?: string, thousands_separator?: string, moneda?: number): string {
        decimal_fixed = typeof decimal_fixed !== 'undefined' ? decimal_fixed : 2;
        decimal_point = typeof decimal_point !== 'undefined' ? decimal_point : '.';
        thousands_separator = typeof thousands_separator !== 'undefined' ? thousands_separator : ',';
        if (decimals != null) {
            decimals = decimals.replace(new RegExp('[' + thousands_separator + ']', 'g'), '');
            if (decimals.indexOf(decimal_point) === -1) {
                decimals = decimals.concat(decimal_point);
            }
            var parts = decimals.split(decimal_point);
            var decimal_fijo = Number(decimal_fixed);
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, thousands_separator);
            if (parts[1].length < decimal_fijo) {
                parts[1] = parts[1] + (new Array(decimal_fijo - parts[1].length + 1).join('0'));
            } else {
                parts[1] = parts[1].slice(0, decimal_fijo);
            }
            if (decimal_fijo === 0) {
                decimals = parts[0];
            } else {
                decimals = parts[0].concat(decimal_point, parts[1]);
            }
            return moneda == 1 ? 'S/. ' + decimals : '$ ' + decimals;
        } else {
            return '';
        }
    }

    changeSimulador() {

    }

    GetSimulacion() {
        if (this.cuotanueva == 0) {
            this.alertaService.showMessage(AppConstants.TitleModal.REPROGRAMING_SIMULATOR, AppConstants.MessageModal.SIMULACION_ERROR_NOT_NEW_CUOTA, MessageSeverity.error);
            return;
        }

        let ammmounMinConfigurate = this.ammountMinReeganche.filter(f => f.tabDetCode001 == this.client.offerProduct /*&& f.tabDetCode008 == this.rowLoan.loanProductCode && (f.tabDetCode006 != '' || f.tabDetCode006 != 'undefined' || f.tabDetCode006 != null)*/);

        if (ammmounMinConfigurate.length == 0) {
            this.alertaService.showMessage(AppConstants.TitleModal.LOAN_ACCEP, `No existe monto mínimo para el producto selecionado`, MessageSeverity.error);
            return;
        }

        let ammounMin = +ammmounMinConfigurate[0].tabDetCode006;

        if (this.monto < ammounMin) {
            this.alertaService.showMessage(AppConstants.TitleModal.LOAN_ACCEP, `El Monto mínimo permitido es de ${this.transformAmount(ammounMin, this.client.solMoneda)}`, MessageSeverity.error);
            return;
        }

        if (!this.validarPlazos()) {
            this.alertaService.showMessage(AppConstants.TitleModal.SIMULACION_TITLE, `La cuota ingresada debe estar entre ${this.client.offerPlazoMin} y ${this.client.offerPlazoMax}`, MessageSeverity.warn);
            return;
        }

        if (!this.validatePeriodoGracia(this.client.ofeGracePeriod, this.mesesgracia)) {
            this.alertaService.showMessage(AppConstants.TitleModal.SIMULACION_TITLE, `El periodo de gracia debe ser menor o igual a ${this.client.ofeGracePeriod}`, MessageSeverity.warn);
            return;
        }

        var model = new RequestSimulatorReprograming();
        model.ammount = this.monto;
        model.monthsGrace = this.mesesgracia;
        model.quota = this.cuotanueva;
        model.currency = this.client.solMoneda,
            model.contractNumber = this.client.offeNumCuen;
        model.productCode = this.client.offerProduct;
        model.tasaTem = this.client.offerTEM;
        model.classCode = this.client.offerClase;
        this.postSimulatorClient(model);
    }

    postSimulatorClient(model: RequestSimulatorReprograming) {
        this.loading = true;
        this.simulatorService.postGetSimulacionReprogramming(model).subscribe((response) => {
                this.rowsSimulacion = response;
                this.loading = false;
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
                this.alertaService.showMessage(AppConstants.TitleModal.REPROGRAMING_SIMULATOR, error.error.messages, MessageSeverity.error);
            });
    }

    updateSimulation() {
        if (this.rowsSimulacion.length > 0) {
            var model = new UpdateClientSimulationReprograming();
            model.solId = this.client.solId;
            model.solQuotaNumber = this.rowsSimulacion[0].quota;
            model.solValueQuota = this.rowsSimulacion[0].ammountQuota;
            model.solPeriodGra = this.mesesgracia;
            model.solDateFirstPayment = this.rowsSimulacion[0].firstPaymentFormat;

            model.simulationAmmountQuota = this.rowsSimulacion[0].ammountQuota;
            model.simulatorAmmount = this.client.simulatorAmmount;
            model.simulationTEA = this.rowsSimulacion[0].tea;
            model.simulationTCEA = this.rowsSimulacion[0].tcea;
            model.simulationIntereses = this.rowsSimulacion[0].interests;
            model.simulationImportTotal = this.rowsSimulacion[0].total;
            model.simulationFirstPayment = this.rowsSimulacion[0].firstPaymentFormat;

            model.tCBicfi = this.rowsSimulacion[0].bicfi;
            model.tCBankPartyIdentification = this.rowsSimulacion[0].bankPartyIdentification;
            model.tCAmmount = this.rowsSimulacion[0].amount;
            model.tCDocumentIdentification = this.rowsSimulacion[0].documentIdentification;

            this.updateSimulationReprogrammingQuota(model);
        } else {
            this.alertaService.showMessage(AppConstants.TitleModal.REPROGRAMING_SIMULATOR, AppConstants.MessageModal.SIMULACION_ERROR_NOT_GENERED, MessageSeverity.error);
        }
    }

    updateSimulationReprogrammingQuota(model: UpdateClientSimulationReprograming) {
        this.loading = true;
        this.clientService.updateClientSimulationQuota(model).subscribe((response) => {
                this.alertaService.showMessage(AppConstants.TitleModal.UPDATE_SOLICITUDD, AppConstants.MessageModal.CREDIT_MANAGEMENT_OK, MessageSeverity.success);
                this.loading = false;
                this.onClose.next(this.client);
                this.modalfinancialsimulation.hide();
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
                this.alertaService.showMessage(AppConstants.TitleModal.UPDATE_SOLICITUDD, error.error.messages, MessageSeverity.error);
            });
    }

    transformAmount(montoEvaluar: number, tipoMoneda: number) {
        if (tipoMoneda == 1) {
            return this.currencyPipe.transform(montoEvaluar, 'S/ ', true, '1.2-2');
        } else {
            return this.currencyPipe.transform(montoEvaluar, '$ ', true, '1.2-2');
        }
    }

    validarPlazos() {
        return Number(this.cuotanueva) >= this.client.offerPlazoMin && Number(this.cuotanueva) <= this.client.offerPlazoMax;
    }

    validatePeriodoGracia(input: string, plazo: number) {
        return isNaN(input as any) ? plazo <= 0 : plazo <= Number(input);
    }


}
