import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[OnlyTwoDecimal]'
})
export class OnlyTwoDecimalDirective {
  constructor(private element: ElementRef) { }

  @HostListener('keypress', ['$event'])
  onKeypressHandler(event: any) {
     
    var valueInputText: string = event.currentTarget.value;
    var val = valueInputText.substr(0, event.currentTarget.selectionStart) + event.key + valueInputText.substr(event.currentTarget.selectionEnd);
    const charCode = (event.which) ? event.which : event.keyCode; 

    //Valid '.' decimal
    if (valueInputText.indexOf(".") != -1 && charCode == 46) {
      return false;
    } else if (val == '' && charCode == 46) {
      return false;
    }

    //Valid first '.'
    if (valueInputText.indexOf(".") == -1 && charCode == 46) {
      return true;
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    } 

    if (val.indexOf(".") != -1) {
      var numberPart = val.split(".");
      if (numberPart.length > 2) {
        return false;
      }
      if (numberPart[1].length > 2) {
        return false;
      }
    }

    return true;
  }

  @HostListener('focusout', ['$event'])
  focusOutHandler(event: any) { 
    event.currentTarget.value = this.localeString(event.currentTarget.value); 
  }

  missingOneDecimalCheck(nStr) {
    nStr += '';
    const x = nStr.split('.')[1];
    if (x && x.length === 1) return true;
    return false;
  }

  missingAllDecimalsCheck(nStr) {
    nStr += '';
    const x = nStr.split('.')[1];
    if (!x) return true;
    return false;
  }

  localeString(nStr) {
    
    if (nStr === '') return '';
    let x, x1, x2, rgx, y1, y2;
    nStr += '';
    x = nStr.split('.');
    x1 = x[0];
    x2 = x.length > 1 ? '.' + x[1] : '';
    rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
      x1 = x1.replace(rgx, '$1' + ',' + '$2');
    }

    /** If value was inputed by user, it could have many decimals(up to 7)
        so we need to reformat previous x1 results */
    if (x1.indexOf('.') !== -1) {
      y1 = x1.slice(x1.lastIndexOf('.')).replace(/\,/g, '');

      y2 = x1.split('.');
      x = y2[0] + y1;
    } else {
      x = x1 + x2;
      if (this.missingOneDecimalCheck(x)) return x += '0';
      if (this.missingAllDecimalsCheck(x)) return x += '.00';
    }

    return x;
  }
}
