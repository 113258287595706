import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  message: string ;
  onConfirmAction: (() => void) | undefined; // Callback para acción de confirmación
  onCancelAction: (() => void) | undefined; // Callback para acción de cancelación

  constructor(public modalRef: BsModalRef) {}

  onConfirm() {
    if (this.onConfirmAction) {
      this.onConfirmAction();
    }
    this.modalRef.hide();
  }

  onCancel() {
    if (this.onCancelAction) {
      this.onCancelAction();
    }
    this.modalRef.hide();
  }
}
