import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Client } from '../../../../models/client';
import { ClientService } from '../../../../services/client.service';
import { ParameterService } from '../../../../services/parameter.service';
import { AppConstants } from '../../../constants/app.constants';

@Component({
    selector: 'app-direction-detail-modal',
    templateUrl: './direction-detail-modal.component.html',
    styleUrls: ['./direction-detail-modal.component.css'],
})
export class DirectionDetailModalComponent implements OnInit {
    directionForm: FormGroup;
    imgHouseCircle: string = './assets/images/icon-house-circle.png';

    loading: boolean = false;
    client: Client = new Client();
    departamentList = [];
    provinciaList = [];
    distritoList = [];
    departamento: string = "";
    provincia: string = "";
    distrito: string = "";
    data: any;

    constructor(
        private formBuilder: FormBuilder,
        private modalDirectionDetailRef: BsModalRef,
        private clientService: ClientService,
        private parameterService: ParameterService
    ) { }

    ngOnInit() {
        this.createForm();
        this.callGetClientDirection(this.data.numeroSolicitud);
        this.getDepartamento(AppConstants.ParameterCode.DEPARTAMENT);
    }

    createForm() {
        this.directionForm = this.formBuilder.group({
            departamento: ['', Validators.required],
            provincia: ['', Validators.required],
            distrito: ['', Validators.required],
            direccion: ['', Validators.compose([
                Validators.required,
                , this.ValidatorNoWhitespace
            ])],
            telephoneCode: '',
            telephone: ''
        });
    }

    close() {
        this.modalDirectionDetailRef.hide();
    }

    ValidatorNoWhitespace(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    callGetClientDirection(code: string) {
        this.loading = true;
        this.clientService.getClient(code).subscribe((response) => {
          this.client = response;
          this.setValues();
          this.getProvincia(+this.client.departamento);
          this.getDistrito(this.client.provincia);
          this.loading = false;
    
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    getDepartamento(code: number) {
        this.loading = true;
        this.parameterService.getParameters(code).subscribe((response) => {
          this.departamentList = response;
          this.loading = false;
          this.departamento = this.departamentList.find(x => x.tabDetCode001 == this.client.departamento)["tabDetCode002"];
          
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    getProvincia(code: number) {
        this.distritoList = [];
        this.loading = true;
        this.parameterService.getParameters(AppConstants.ParameterCode.PROVINCIA).subscribe((response) => {
          this.provinciaList = response.filter(f => f.tabDetCode003 == code);
          this.loading = false;
          this.provincia = this.provinciaList.find(x => x.tabDetCode001 == this.client.provincia)["tabDetCode002"];
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }
    
      getDistrito(code: string) {
        this.loading = true;
        this.parameterService.getParameters(AppConstants.ParameterCode.DISTRITO).subscribe((response) => {
          this.distritoList = response.filter(f => f.tabDetCode006 == code);
          this.loading = false;
          this.distrito = this.distritoList.find(x => x.tabDetCode001 == this.client.distrito)["tabDetCode002"];
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    setValues() {    
        this.directionForm.patchValue({
          departamento: this.client.departamento,
          provincia: this.client.provincia,
          distrito: this.client.distrito,
          direccion: this.client.direccion,
          telephoneCode: this.client.telephoneCode,
          telephone: this.client.telephone
        });
    }
}
