import { Directive, ElementRef, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appDocumentValidator]'
})
export class DocumentValidatorDirective implements OnChanges {

  @Input() tipoDocumento: string = '';

  private regex: RegExp = new RegExp('^[a-zA-Z0-9]*$'); // Permitir letras y números (sin espacios ni caracteres especiales)
  private maxLength: number = 8; // Longitud por defecto

  constructor(private el: ElementRef) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes['tipoDocumento']) {
      this.setValidationRules();
    }
  }

  private setValidationRules() {
    // Reglas según el tipo de documento
    if (this.tipoDocumento === '1') { // DNI
      this.regex = new RegExp('^[0-9]*$'); // Solo números
      this.maxLength = 8; // Máximo 8 caracteres
    } else if (this.tipoDocumento === '2') { // C.E o CARNÉ
      this.regex = new RegExp('^[a-zA-Z0-9]*$'); // Letras y números
      this.maxLength = 12; // Máximo 12 caracteres
    } else {
      this.regex = new RegExp('^[0-9]*$'); // Solo números para otros casos
      this.maxLength = 8; // Máximo 8 caracteres por defecto
    }
  }

  @HostListener('input', ['$event'])
  onInput(event: Event) {
    const input = event.target as HTMLInputElement;
    let value = input.value;

    // Usar regex para eliminar caracteres no permitidos
    value = value.replace(new RegExp(`[^${this.regex.source}]`, 'g'), ''); // Solo permitir caracteres válidos

    // Limitar la longitud máxima
    if (value.length > this.maxLength) {
      value = value.slice(0, this.maxLength); // Cortar a la longitud máxima permitida
    }

    // Evitar recursión infinita: solo asignar si el valor ha cambiado
    if (input.value !== value) {
      input.value = value;
      // Disparar el evento para notificar cambios
      input.dispatchEvent(new Event('input'));
    }
  }
}
