export const AppConstants = {
     APP_VERSION: '2.4.6',
    Paginado: {
        DEFAULT_PAGE_INDEX_PAGINATION: 0,
        DEFAULT_PAGE_SIZE_PAGINATION: 10
    },

    Configuration: {
        CHANNEL_DEFAULT: '17',
        ENTERPRISE_DEFAULT: '1'
    },

    TitleModal: {
        CONSULT_OFFER_RUO: 'Consulta de Ofertas',
        PROSPECT_ADD_TITLE: 'Registrar Prospecto',
        SIMULATOR_GET_TITLE: 'Obtener Simulación',
        LOGIN_TITLE: 'Inicio Sesión.',
        BIOMETRICO_TITLE: 'Verificación Biométrica',
        OTP_TITLE: 'Verificación SMS',
        SIMULACION_TITLE: 'Simulador Cuotas',
        ADD_CLIENT: 'Agregar Cliente',
        UPDATE_CLIENT: 'Actualizar Cliente',
        ADD_REFERENCE: 'Agregar Referencia',
        ADD_DESEMBOLSE_MODE: 'Agregar Modo de desembolso',
        OTP_SEND: 'Enviar Token SMS',
        OTP_VALIDATE: 'Validación de Token',
        SEND_SUPERVISOR: 'Enviar a Supervisor',
        SEND_RECHAZO: 'Rechazar Solicitud',
        SEND_OBSERVACION: 'Enviar Solicitud',
        CREDIT_MANAGEMENT: 'Gestión de Créditos',
        ADVISOR_MANAGEMENT: 'Gestión de Asesor',
        SUPERVISOR_MANAGEMENT: 'Gestión de Supervisor',
        UPDATE_PRODUCT_SOLICITUD: 'Actualización de Producto',
        UPDATE_MODE_DESEMBOLSO: 'Actualizar Modo Desembolso',
        UPLOAD_FILES: 'Adjuntar Archivos',
        UPLOAD_FILES_DELETE: 'Eliminar Archivo Adjunto',
        DOWNLOAD_FILE: 'Descargar Archivo',
        ADD_COMENTARIO: 'Agregar Comentario',
        ANNULMENT_PRODUCT: 'Anulación de Producto',
        ADD_COMPANY: 'Agregar Empresa',
        UPDATE_COMPANY: 'Actualizar Empresa',
        USER_VALIDATE: 'Validar Usuario',
        ADD_USER: 'Agregar Usuario',
        UPDATE_USER: 'Actualizar Usuario',
        ADD_PARAMETER: 'Agregar detalle de parametro',
        UPDATE_PARAMETER: 'Actualizar detalle de Parametro',
        DACTILAR_VALIDATE: 'Verificación Dactilar',
        UPDATE_SOLICITUDD: 'Actualizar Solicitud',
        SERVICE_BRMS: 'Servicio de Políticas',
        CHANGE_PASSWORD: 'Cambiar contraseña.',
        REQUIRED_CHANGE_PASSWORD: 'Es necesario que cambie su contraseña.',
        EXPIRED_PASSWORD: 'Su contraseña ya expiró, es necesario que la cambie.',
        NOTIFICATION: 'Envío Notificación',
        ERROR: 'Error',
        VIEW_LOG: 'Visualizar detalle de Log',
        LOAN_CLIENT_HEADER: 'Obtener préstamos vigentes.',
        LOAN_CLIENT: 'Consultar detalle del préstamo.',
        LOAN_ACCEP: 'Validación de Préstamo',
        VALIDATE_ASESOR: 'Validación de Usuario en T24',
        ERROR_ACTUALIZAR_AGENCIA: 'El usuario no tiene una agencia registrada en T24 o es diferente de ONB, favor de actualizarlo y volver a intentar.',
        ERROR_UPDATEUSER_AGENCY: 'No se actualizo la agencia del usuario',
        ERROR_GETINFO_USERFROM_ONB: 'No se obtuvo informacion de ONB',
        BANKS_SCHEDULE: 'Obtener Hoarios de Bancos',
        VARIABLE_CONTROL: 'Obtener Variable Control',
        VALIDACION_DACTILAR: 'Validacion Dactilar',
        VALIDACION_TOKEN_SMS: 'Validacion Token SMS',
        VALIDACION_BANK: 'Validacion Institucion Financiera',
        VALIDACION_COMISION: 'Validacion Comisión Interbancaria',
        VALIDACION_OFFER_REENGANCHE: 'Validacion de Oferta Reenganche',
        REPROGRAMING_CLIENT_HEADER: 'Obtener deudas vigentes.',
        REPROGRAMING_SIMULATOR: 'Simulación de Reprogramación de Deuda',
        UPDATE_DATA_FINANCIERA: 'Actualizar Datos Financieros',
        REGLA_NEGOCIO_COCO: 'Regla Negocio - COCO',
        UPDATE_STATE_ACCOUNT: 'Actualizar Estado de Cuenta',
        VALIDATE_REPROGRAMMING: 'Validación Reprogramación',
        VIEW_LOGIN_DETAIL: 'Visualizar detalle de Inicio de Sesión',
        PROCESS_MANAGEMENT: 'Proceso de Alta',
        VIEW_CONSULT_DETAIL_CREDIT_CARD: 'Consulta Detalle y Movimientos',
        VIEW_CONSULT_DETAIL_LOAN: 'Consulta Detalle del Préstamo',
        SOLICITUD_VALIDATE: 'Validar Solicitud',
        CLIENT_VERIFICACION: 'Validación de Cliente',
        SEGURO_VIDA_OPTATIVO: 'Consulta de Oferta de Seguro VIda',
        REGISTER_AUTHENTICATION: 'Registro de Autenticación',
        VALIDACION_OFFER: 'Validacion de Oferta',
        UNLOCK_USER: 'Desbloquear Usuario',
        VALIDACION_TIPO_OFERTA: 'Validación de Tipo de Oferta',
        VALIDATE_TOKEN_MODE_TITLE: 'Error',
        OTP_SEND_EMAIL: 'Enviar Token',
        VALIDATION_PINPAD: 'Validación PinPad',
        EFEX_ACTIVE_PRODUCT: 'Consulta producto EFEX',
        CONSULT_PRODUCTS: 'Consulta de Productos',
        DATA_PROTECTION: 'Protección de datos',
        CONSULTA_VIEW_DETAIL_CONTACT: 'Consulta del indicador de Envío de EECC',
        VALIDATION_CLIENT_DATA: 'Validación de Datos del Cliente',
        VALIDACION_EMAIL_T24: 'Validación Email T24'
    },

    MessageModal: {
        PROSPECT_ADD_MESSAGE_OK: 'Se registro el prospecto con éxito.',
        PROSPECT_ADD_MESSAGE_ERROR: 'Ocurrio un error al registrar el prospecto',
        ENTER_USERNAME_PASSWORD_MESSAGE: 'Ingresar usuario y contraseña.',
        LOGIN_FAIL_MESSAGE: 'Credenciales Incorrectas.',
        OTP_SUCCES_MESSAGE: 'Se envió el SMS correctamente.',
        USER_DOESNT_EXIST: 'El usuario o la contraseña son incorrectos.',
        PASSWORD_DOESNT_CORRECT: 'El usuario o la contraseña son incorrectos.',
        USER_IS_INACTIVE: 'El usuario o la contraseña son incorrectos.',
        SIMULACION_ERROR_NOT_MONT: 'Ingrese Monto',
        SIMULACION_ERROR_NOT_NEW_CUOTA: 'Ingrese Cuota Nueva',
        SIMULACION_ERROR_NOT_NEW_PAY_DAY: 'Ingrese el Día de Pago',
        SIMULACION_ERROR_MONT: 'El Monto Solicitado debe ser menor o igual al Monto Ofertado.',
        SIMULACION_ERROR_DUPLICATE: 'Ya existe una simulacion para la cuota nueva ingresada.',
        SIMULACION_ERROR_NOT_GET: 'Para continuar debe selecionar una opción del Simulador.',
        ADD_CLIENT_MESSAGE_OK: 'Se registró el cliente Correctamente',
        UPDATE_CLIENT_MESSAGE_OK: 'Se actualizó el cliente Correctamente',
        ADD_REFERENCE_MESSAGE_OK: 'Se registró la referencia Correctamente',
        ADD_MODO_DESEMBOLSO_MESSAGE_OK: 'Se registró el modo de activación Correctamente',
        ADD_MODO_DESEMBOLSO_NOT_SELECT: 'Para continuar debe selecionar el tipo de abono en cuenta',
        ADD_MODO_DESEMBOLSO_EXISTS_ACCOUNT: 'Seleccione la cuenta existente',
        OTP_SEND_TOKEN_OK: 'Se envió el Token SMS correctamente.',
        OTP_VALIDATE_TOKEN_OK: 'Se validó el Token SMS correctamente.',
        SEND_SUPERVISOR_OK: 'Se envió la solicitud al suepervisor correctamente.',
        SEND_RECHAZO_OK: 'Se rechazó la solicitud correctamente.',
        SEND_OBSERVACION_OK: 'Se envió la solicitud correctamente.',
        CREDIT_MANAGEMENT_OK: 'Se actualizó correctamente la solicitud.',
        PRODUCT_UPDATE_OK: 'Se actualizó correctamente el producto.',
        MODE_DESEMBOLSO_UPDATE_OK: 'Se actualizó correctamente el modo de desembolso.',
        UPLOAD_FILES_MESSAGE_OK: 'Se adjuntarón los archivos correctamente.',
        UPLOAD_FILE_INCORRECT: 'Debe seleccionar un archivo .PNG , .JPG o PDF',
        UPLOAD_FILE_EXIST: 'El archivo seleccionado ya existe.',
        SEND_COMENTARIO_OK: 'Se ingresó el comentario correctamente.',
        ANNULMENT_PRODUCT_NOT_EXIST: 'No se puede anular el producto porque aún no se genera ninguna solicitud.',
        ANNULMENT_PRODUCT_NOT_USER_CREATION: 'La solicitud solo puede ser anulada por el usuario que la creo.',
        ADD_COMPANY_OK: 'Se creó la empresa correctamente.',
        ADD_USER_OK: 'Se creó al usuario correctamente.',
        ADD_PARAMETER_OK: 'Se creó al párametro correctamente',
        UPDATE_COMPANY_OK: 'Se actualizó la empresa correctamente.',
        CREATE_USER_FAILED: 'Completar los campos Requeridos.',
        UPDATE_USER_OK: 'Se actualizó el usuario correctamente.',
        UPDATE_PARAMETER_OK: 'Se actualizó el parametro correctamente.',
        VALIDATE_USER_EMPY_FORM: 'Debe ingresar el Usuario y Contraseña.',
        VALIDATE_USER_NOT_PERFIL: 'El usuario ingresado no tiene el perfil Supervisor.',
        VALIDATE_USER_DIFERENCE_USER: 'El usuario ingresado no es el logeado.',
        SIMULACION_ERROR_MONT_MIN: 'El monto mínimo de la oferta es de ',
        SEND_PARAMETER_FAIL: 'Ya existe el registro con el mismo codigo.',
        SEND_PARAMETER_DETAIL: 'Ingresar al menos un detalle.',
        SEND_PAIS_EMPRESA: 'Ingrese país.',
        SEND_PAIS_USUARIO: 'Ingrese país.',
        SEND_ROL_USUARIO: 'Ingrese rol.',
        SEND_EMPRESA_USUARIO: 'Ingrese empresa.',
        SEND_AGENCIA_USUARIO: 'Ingrese agencia.',
        ERROR_DATE_REPORT_EXCEL: 'Debe selecionar una fecha correcta.',
        NO_FILES_ENTIDAD: 'Debe adjuntar al menos un archivo.',
        PASSWORDS_INCORRECTS: 'Las constraseñas no son iguales.',
        CHANGE_PASSWORD_CORRECT: 'La contraseña se actualizó con exito.',
        REGISTER_NOTIFICATION_OK: 'Se envió Correctamente.',
        REGISTER_NOTIFICATION_ERROR: 'Ocurrio un error en el envío.',
        LOGIN_ERROR_SERVICES: 'Ocurrio un error de conexión.',
        APP_CAPPTA_NOT_INSTALL: 'La aplicación de Cappta no se encuentra instalada.',
        PRODUCT_ERROR_MODALIDAD_ACTIVACION: 'Flujo de venta no aplicable al canal',
        PRODUCT_ERROR_PERFIL_NO_AUTORIZADO: 'Perfil no autorizado para vender este producto.',
        PRODUCT_ERROR_NOT_SELECT_MODO_DESEMBOLSO: 'Debe seleccionar un modo de desembolso.',
        PRODUCT_ERROR_NOT_SELECT_ACCOUNT: 'Debe seleccionar un número de cuenta.',
        PRODUCT_ERROR_BANK_INCORRECT: 'EL número de cuenta CCI no es correcto.',
        ERROR_FINISH_SOLICITUD: 'Ocurrio un error al actualizar la Solicitud.',
        ERROR_INTITUCION_FINANCIERA: 'Entidad Financiera no permitida.',
        ERROR_CALCULATE_COMISION: 'Ocurrio un error al calcular la comisión interbancarios, vuelva a intentar.',
        ERROR_VALIDATE_LOAN: 'Ocurrio un error al validar el préstamo, volver a intentar.',
        ERROR_VALIDATE_OFFER: 'Ocurrio un error al validar la oferta, volver a intentar.',
        ERROR_REPROGRAMMING_SIMULATION: 'Debe volver a simular.',
        UPDATE_DATA_FINANCIERA: 'Se actualizó correctamente los datos.',
        SIMULACION_ERROR_NOT_GENERED: 'Debe simular una cuota para continuar.',
        CANCEL_SOLICITUD: 'Se canceló correctamente la solicitud.',
        ERROR_NUMERO_CUENTA: 'El numero de cuenta interbancario debe tener 20 digitos.',
        ERROR_RESTRICTITION_CLIENT_PEP: 'Venta a cliente PEP restringido para este Perfil',
        SOLICITUD_ANULADA: 'La solicitud fue anulada, volver a consultar.',
        ERROR_BIOMATCH: 'Ocurrio un error con el Servicio Biométrico, Validar Conexión.',
        ERROR_RESTRICTITION_CLIENT_PEP_TC: 'Venta a cliente PEP restringido.',
        ERROR_UPDATE_BOQUED: 'La solicitud no se puede rechazar, se encuentra en proceso de alta.',
        PRODUCT_ERROR_AGENCY_NO_AUTORIZADO: 'Agencia no autorizada para vender este producto.',
        ERROR_CREATE_AUTHENTICATION: 'Ocurrio un error al registrar la autenticación, volver a intentar.',
        ERROR_UPDATE_AUTHENTICATION: 'Ocurrio un error al actualizar la autenticación, volver a intentar.',
        ERROR_VALIDACION_TC_GENERIC: 'Tipo de Oferta no permitido actualmente.',
        USER_IS_LOCKED: 'Usuario bloqueado.',
        UNLOCKED_USER_OK: 'Se desbloqueó el usuario correctamente.',
        UNLOCKED_USER_ERROR: 'Ocurrio un error al desbloquar el usuario.',
        ERROR_REPROGRAMACION_TC: 'Los [DIAS_MORA] días de atraso actuales de la TC no se encuentran dentro del rango permitido ( de [DIAS_MIN] hasta [DIAS_MAX] días)',
        OFERTA_NO_VIGENTE: 'La oferta actual no se encuentra vigente.',
        CARACTER_NO_VALIDO: 'Caracteres no válidos.',
        MENSAJE_TIPO_OFERTA: 'El Cliente posee actualmente una Tarjeta de Crédito vigente.',
        VALIDATE_TOKEN_MODE_MESSAGE: 'No se detecto el tipo de activación token',
        OTP_SEND_TOKEN_EMAIL_OK: 'Se envió el Token al correo electrónico correctamente.',
        OTP_VALIDATE_TOKEN_EMAIL_OK: 'Se validó el Token correctamente.',
        MESSAGE_ERROR_CONNECT_PINPAD: 'Ocurrió un error al validar el PIN',
        MESSAGE_ERROR_PIN: 'Validación Incorrecta PIN, indicar que porfavor ingrese nuevamente su PIN',
        MESSAGE_ERROR_AMMOUNT_EFEX: 'No se pudo recuperar la línea efectiva',
        MESSAGE_ERROR_PRODUCT_ACTIVE: 'No se pudo recuperar la información del producto',
        MESSAGE_ERROR_PRODUCT_RATE: 'No se pudo recuperar las tasas',
        SELECT_TOKEN_MODE_MESSAGE: 'Debe seleccionar un tipo de validación',
        MESSAGE_ERROR_DATA_CONTRACT: 'Ha ocurrido un error al recuperar lo datos del contrato',
        MESSAGE_ERROR_TOKEN_ATTEMPTS: 'Usted superó el número de reintentos, favor comunicarse con el área de soporte',
        MESSAGE_ERROR_DIFERIDO: 'No se pudo recuperar el atributo diferido EFEX',
        MEESSAGE_SIMULATION_DIFERIDO_ERROR : 'Debe seleccionar el campo Diferido.',
        MEESSAGE_SIMULATION_PLAZO_ERROR : 'Debe seleccionar el Plazo.',
        MEESSAGE_SIMULATION_DIA_PAGO_ERROR : 'No se cuenta con el Día de Pago.',
        MESSAGE_PINPAD_ERROR: 'No se pudo recuperar el ticket PINPAD',
        MESSAGE_PINPAD_SERVICE_ERROR: 'Ocurrió un error al validar el ticket PINPAD',
        MESSAGE_ERROR_VALIDATE_CARD_NUMBER: 'La Tarjeta de Crédito ingresada no coincide con la del Cliente, favor de a validar.',
        MESSAGE_ERROR_ATTEMPTS: 'Usted ha superado el número máximo de intentos permitidos para la validación de Pin Pad.',
        MESSAGE_ERROR_CONSULT_PRODUCTS:'Se ha presentado un inconveniente al consultar los productos del cliente, favor de volver a intentar nuevamente.',
        MESSAGE_VALIDATION_DATA_PROTECTION: 'Debe seleccionar el consentimiento de protección de datos.',
        MESSAGE_VALIDATION_ROLE: 'Producto no configurado para simulación.',
        MESSAGE_USER_CONSULT: 'No se puede continuar el flujo Derivar con asesor de banco',
        MESSAGE_ERROR_HARDWARE_PINPAD: 'Se ha presentado problemas en reconocer el equipo de PIN PAD, favor de volver a reintentar o probar conectando y desconectando el equipo.',
        MESSAGE_VALIDACION_EMAIL_TITLE: 'Cliente no cuenta con correo!',
        MESSAGE_VALIDACION_EMAIL_T24: 'El Cliente no tiene registrado su correo personal, favor de actualizar sus datos'
    },
    QuestionModal: {},

    Remember: {
        USER: 'USER',
        PASSWORD: 'PASSWORD'
    },

    Session: {
        ACCESS_TOKEN: 'ACCESS_TOKEN',
        USERID: 'USERID',
        USERNAME: 'USERNAME',
        TIMESECONDS: 'TIMESECONDS',
        CHANGEPASSWORD: 'CHANGEPASSWORD',
        EXPIREDPASSWORD: 'EXPIREDPASSWORD',
        USERROLID: 'ROLID',
        USERROLNAME: 'ROLNAME',
        EMAIL: 'CORREO',
        ACCESS_TOKEN_RIPLEY: 'ACCESS_TOKEN_RIPLEY',
        AGENCYCODE: 'AGENCY_CODE',
        AGENCYNAME: 'AGENCY_NAME',
        SALESCHANNEL: 'SALES_CHANNEL',
        SALES_CHANNEL_DESCRIPTION: 'SALES_CHANNEL_DESCRIPTION',
        FLAG_CANCEL_SOLICITUD: 'FLAG_CANCEL_SOLICITUD',
        CONFIGURATION_BUS: 'CONFIGURATION_BUS',
        SELECT_TOKEN: 'SELECT_TOKEN',
        USERDOCUMENTNUMBER: 'DOCUMENTNUMBER',
        AGENCYCODEFROMT24:'AGENCYCODEFROMT24'
    },

    SessionProducto: {
        NOMBRE_PRODUCTO: 'NOMBRE_PRODUCTO',
        NOMBRE_CLIENTE: 'NOMBRE_CLIENTE',
        NUMERO_DOCUMENTO: 'NUMERO_DOCUMENTO',
        APELLIDO_PATERNO: 'APELLIDO_PATERNO',
        APELLIDO_MATERNO: 'APELLIDO_MATERNO',
        SALDO_PRODUCTO: 'SALDO_PRODUCTO',
        CONTRATO_PRODUCTO: 'CONTRATO_PRODUCTO',
        FECHA_INICIO: 'FECHA_INICIO',
        FECHA_FIN: 'FECHA_INICIO',
        TEM: 'TEM',
        TEA: 'TEA'
    },

    UserParameter: {
        TIMESECONDS: '2',
        NUMERO_ENTIDADES: 15
    },

    ObjectCliente: {
        CLIENTE: 'CLIENTE',
        PRODUCTO: 'PRODUCTO',
        CUOTA: 'CUOTA',
        ENTIDAD: 'ENTIDAD',
        SIMULACION: 'SIMULACION',
        VERIFICACION: 'VERIFICACION',
        BANDEJA: 'BANDEJA',
        VARIABLE_CONTROL: 'VARIABLE_CONTROL',
        ENTIDADCONVENIO: 'ENTIDADCONVENIO',
        RENIEC: 'RENIEC',
        BIOMETRICA: 'BIOMETRICA'
    },

    ParameterCode: {
        MODALIDADES_DESEMBOLSO: 82,
        DOCUMENT_TYPE: 1,
        POLITICAS: 2,
        FACTORES: 3,
        USER_REQUEST_BIOMATCH: 4,
        BIOMATCH_RESPONSE_GATEWAY: 5,
        BIOMATCH_RESPONSE_RENIEC: 6,
        SUB_PRODUCS: 7,
        VARIABLES_CONTROL: 8,
        MOTIVOS: 9,
        MODO_ACTIVACION: 10,
        TIPO_ACTIVIDAD: 11,
        OCUPACION: 12,
        ESTADO_CIVIL: 13,
        NIVEL_EDUCACION: 14,
        DEPARTAMENT: 15,
        PROVINCIA: 16,
        DISTRITO: 17,
        PAIS: 23,
        AGENCIA: 22,
        TIPO_CALLCENTER: 90,

        SR_CLIENTE: 24,
        MESSAGE_MODAL: 25,
        PESO_ARCHIVO: 27,
        PROTECTION_DATOS_TITLE: 35,
        PRODUCT_SECCION: 34,
        PROTECTION_DATOS_BODY: 36,
        PROTECTION_DATOS_CIERRE: 37,
        SIMULACIONPYOFF: 38,
        SECCION_CLIENTE: 40,
        MONTO_MIN_REENGANCHE: 42,
        USER_TRANSACCION: 43,
        TEXT_LEGAL_BIOMETRICO: 47,
        ENTIDADES_FINANCIERAS: 51,
        FLAG_REENGANCHE_CONVENIO: 52,
        VARIABLE_CONTROL_HEADER: 45,
        DIAG_GRACIA_PRODUCT: 53,
        MENSAJES_TRANSFERENCIA_INTERBANCARIA: 54,
        OFFER_DINAMICA: 44,
        CLIENT_DATOS_FINANCIEROS_1: 62,
        CLIENT_DATOS_FINANCIEROS_2: 63,
        RESUMEN_SOLICITUD: 64,
        MODO_DESEMBOLSO_BLOQUEO: 69,
        ESTADOCUENTA_FECHA_PAGO: 70,
        ESTADOCUENTA_RECEPCION: 71,
        COCO_PARAMETROS: 76,
        REPROGRAMACION_SEF_TRADICIONAL: 77,
        REPROGRAMACION_SEF_ALIVIO: 78,
        NACIONALIDAD: 80,
        PRODUCTS: 81,
        DESEMBOLSE_TYPE: 82,
        PRODUCTOS_PERFIL: 85,
        BANCOS_TRANSFERENCIA_INTERBANCARIA: 87,
        EDICION_CLIENTE_PERMITIDA_PERFIL: 88,
        PLAN_DESGRAVAMEN: 94,
        LIMITE_MAX_DESEMBOLSO_PERFIL: 100,
        RESTRICCION_VENTA_CLIENT_PEP: 101,
        CREDIT_CARDS_TYPE: 104,
        VIEW_FOTO_CLIENT: 105,
        VALIDACION_VENTA_TC_AGENCIA: 108,
        PRODUCTOS_SEGURO_OPTATIVO: 109,
        ADICIONAL_PARAMETERS: 93,
        TEXT_LEGAL_TOKEN_SMS: 111,
        PROTECTION_DATOS_SUPERIOR: 117,
        TIPO_OFERTA_TC: 112,
        SUB_PRODUCTOS_TC: 113,
        RELATIONSHIP_BANK: 107,
        GRADO_PARENTESCO: 114,
        TYPE_SALE_TC: 115,
        PRODUCTOS_AUTO_CANCEL_TOKEN_SMS: 118,
        TC_REMISION_REACTIVACION_ESPECIAL: 121,
        DATOS_TARJETA: 127,
        DIAS_ATRASO_TC: 116,
        VALIDACION_CARACTERES_ESPECIALES: 20,
        TIPO_RESPUESTA_TC: 130,
        TIPO_BLOQUEO_TC: 131,
        LISTA_DIFERIDO: 123,
        MODO_HABILITACION_TOKEN: 126,
        REEMISION_OPCIONES: 128,
        ANULACION_OPERACIONES_EFEX : 133,
        FLUJO_ALTERNO_TOKEN : 135,
        TEXTO_SEGURO_TC: 136,
        ROLES_BLOQUEAR_SIMULACION: 137,
        FLUJO_CONTACTABILIDAD: 150,
        TEXT_LEGAL_PINPAD: 140,
        MOTIVO_BLOQUEO_TD: 152,
        CAMBIO_PRODUCTO_TC: 139,
        VALIDACION_AGENCIA_PRODUCTOS: 21,
        FUNCIONALIDADES_POSTVENTA: 153,
        FLUJOS_POSTVENTA:154
    },

    AdicionalParameterCode:
    {
        VERSION_OB: '13',
        REINTENTOS_PINPAD: '16',
        BOTON_SEND_AGENCY: '17',
        SEGUROTCFLUJO: '14',
    },
    SolicitudParameter: {
        SOL_PAIS: 604,
        SOL_EMP: '001'
    },

    WFTareas: {
        EVA_PRODUCT: '10',
        VERIF_CLIENT: '20',
        APPROVED: '30',
        CLOSE: '40'
    },

    Channel: {
        MOVIL: 10,
        WEB: 20
    },

    Process: {
        ONBOARDING: 10
    },

    SolicitudState: {
        PROC_EVALUACION: 1,
        PROC_CLIENTE: 2,
        PROC_AUTENTIFICACION: 3,
        PEND_AUTORIZACION: 4,
        PEND_APROBACION: 5,
        OBSERVADO: 6,
        CANCELADO: 7,
        FINALIZADO: 8,
        RECHAZADO: 9,
        RECHAZADO_VIGENCIA: 10,
        PEND_VALIDACION_PIN_PAD: 16,
        ANULACION_EFECTIVO_EXPRESS : 15,
		PENDIENTE_VALIDACION_PINPAD : 16,
		PENDIENTE_APROBACION_ANULACION : 17
    },
    RolesCode: {
        ADMINISTRADOR: 'R0001',
        GESTOR: 'R0002',
        SUPERVISOR: 'R0003',
        ANALISTA: 'R0004',
        ASESOR: 'R0005',
        CALLCENTER: 'R0006',
        PROVEEDORCALLCENTER: 'R0013',
        SUP_CALLCENTER: 'R0014',
        CAJERO_TIENDA: 'R0015',
        SUP_CAJEROTIENDA: 'R0016',
        GESTION_USUARIOS: 'R0011',
        CAJERO: 'R0018',
        CONSULTA_SIMULACION: 'R0026',
    },

    StateFlag: {
        Activo: 1,
        ActivoString: '1',
        Inactivo: 0,
        InactivoString: '0',
        ActivoStr: 'SI',
        InactivoStr: 'NO',
        Optional: 'SEGURO_OPCIONAL',
        BiometricoFlagSeguroVidaInactivo: 1,
        BiometricoFlagSeguroVidaActivo: 2,
    },

    Contactability: {
        TipoEnvio: 'Tipo Envio',
        TipoModalidad: 'Tipo Modalidad'
    },

    TipoMonedaId: {
        SOLES: 1,
        DOLARES: 2
    },
    TipoMonedaISO: {
        SOLES: 'PEN',
        DOLARES: 'USD'
    },
    TipoMonedaValor: {
        SOLES: 'S/',
        DOLARES: '$'
    },
    //ControlVariables: {
    //  PEP: '8',
    //  CDD: '9',
    //  CALL: '10',
    //  CALLNEW: '11'
    //},
    AdjuntoModo: {
        Cliente: 1,
        Entidades: 2,
        EvidenciaCelular:3
    },
    Comenatio: {
        Generico: '0',
        Adjunto: '99'
    },
    VariableControl: {
        PEP: 'PEP',
        CDD: 'CDD',
        CALLCENTER: 'CALL',
        CALLCENTERNEW: 'CALL-RB1A'
    },
    Products: {
        PYA: '003',
        CDD: '007',
        CAPTACION: '002',
        REENGANCHECDD: '026',
        CONVENIOREENGANCHE: '025',
        COLABORADORREENGANCHE: '024',
        REPROTC: '006',
        REPROSEF: '019',
        REENGANCHESEF: '032',
        SEFPLUS: '035',
        SEF: '001',
        EFEX: '005',
        IL:'004',
        CAMBPROD: '010',
        //PostVenta
        SERVASOC: '09'
    },
    FlagIntent: {
        FLAG_APP: 'FLAG_APP',
        FLAG_DNI: 'FLAG_DNI'
    },

    LOAN: {
        REEGANCHE: 'REENGANCHE',
        REPROGRAMACIONES: 'REPROGRAMACIONES',
        REPROGRAMACIONESSEF: 'REPROGRAMACIONES-SEF',
        REENGANCHESEF: 'REENGANCHE-SEF',
        SEFPLUS: 'SEF-PLUS'
    },

    REPROGRAMACION_SEF_ALIVIO: {
        MontoMinimoReprogramar: '1',
        PeriodoGracia: '2',
        CantidadMaxReprogramacionesAño: '3',
        IntervaloEntreReprogramaciones: '4'
    },

    REPROGRAMACION_SEF_TRADICIONAL: {
        MontoMinimoReprogramar: '1',
        PeriodoGracia: '2',
        CantidadMaxReprogramacionesAño: '3',
        IntervaloEntreReprogramaciones: '4'
    },

    ProductTypeName: {
        ALL: 'ALL',
        SEF: 'SEF',
        TC: 'TC'
    },
    AuthenticationMode: {
        Biometrico: 1,
        Supervisor: 2,
        TokenSMS: 3,
        Creditos: 4,
        PIN: 5,
        Facial: 6,
        TokenEmail: 7,
        SinToken: 8
    },

    PageTitle:{
        TIPO_CONTACTO_TC: 'Tipo de Contacto',
        RESPUESTA_TC: 'Respuesta',
        MOTIVO_BLOQUEO_TC:'Motivo de Observación'
    },


    TIPOS_DESEMBOLSO: {
        CUENTA_EXISTENTE: 1,
        CUENTA_NUEVA: 2,
        EFECTIVO: 3,
        INTERBANCARIO_DIFERIDO: 4
    },

    ACTIVATION_MODE:
    {
        TOKEN_SMS: 1,
        TOKEN_EMAIL: 2
    },

    RESULTADO:
    {
        OK : "OK",
        ERROR : "ERROR",
    },

    EstadoTarjetaDebito: {
      Activa: "90",
      BloqueoTemporal: "97"
    },

    TipoTarjeta:{
      Debito: 1,
      Credito:2
    },

    TypeCard: {
        CREDIT: "TC",
        DEBIT: "TD"
    }

};

export const ConstantMessages = {
    EmpresaErrorMessages: {
        nombre: {
            required: 'Ingrese Empresa.'
        },
        pais: {
            required: 'Ingrese País.'
        },
        estado: {
            min: 'Seleccione un estado',
            required: 'Seleccione un estado'
        }
    },

    UsuarioErrorMessages: {
        codigo: {
            required: 'Ingrese el codigo'
        },
        nombre: {
            required: 'Ingrese el usuario'
        },
        password: {
            required: 'Ingrese una contraseña.',
            minlength: 'Ingrese una contraseña minima de 8 caracteres.',
            pattern: 'La contraseña debe contener mayusculas, minusculas, números y caracteres especiales.'
        },
        email: {
            required: 'Ingrese el correo'
        },
        numeroDocumento: {
            required: 'Ingrese el número de documento'
        },
        tipoDocumento: {
            required: 'Seleccione el tipo de documento'
        },
        codigoBanco: {
            required: 'Ingrese el código de banco',
            maxlength: 'La longitud debe ser 15 caractéres'
        },
        empresa: {
            required: 'Ingrese empresa'
        },
        rol: {
            required: 'Ingrese rol'
        },
        pais: {
            required: 'Ingrese país'
        },
        estado: {
            required: 'Ingrese estado'
        },
        agencia: {
            required: 'Ingrese agencia'
        },
        saleschannel: {
            required: 'Seleccione un canal de venta'
        },
        salessubchannel: {
            required: 'Seleccione un sub canal de venta'
        }
    },

    LoginErrorMessages: {
        usuario: {
            required: 'Ingrese su usuario.'
        },
        contrasena: {
            required: 'Ingrese su contraseña.'
        },

        currentPassword: {
            required: 'Ingrese su contraseña actual.',
            minlength: 'Ingrese una contraseña minima de 8 caracteres.',
            pattern: 'La contraseña debe contener mayusculas, minusculas, números y caracteres especiales.'
        },
        newPassword: {
            required: 'Ingrese su nueva contraseña.',
            minlength: 'Ingrese una contraseña minima de 8 caracteres.',
            pattern: 'La contraseña debe contener mayusculas, minusculas, números y caracteres especiales.'
        },
        confirmNewPassword: {
            required: 'Confirme su nueva contraseña.',
            minlength: 'Ingrese una contraseña minima de 8 caracteres.',
            pattern: 'La contraseña debe contener mayusculas, minusculas, números y caracteres especiales.'
        }
    },

    NotificationErrorMessages: {
        canalForm: {
            min: 'Seleccione un estado',
            required: 'Seleccione un Canal'
        },
        stateForm: {
            min: 'Seleccione un estado',
            required: 'Seleccione un estado'
        },
        selectedItemsForm: {
            min: 'Seleccione un estado',
            required: 'Seleccione un usuario'
        },
        notificationTitleForm: {
            required: 'Ingrese Título Notificación.'
        },
        notificationBodyForm: {
            required: 'Ingrese Mensaje Notificación.'
        },
    },
};

export const LoginMessages = {
    ACTIVIDAD_SOSPECHOSA: 'Error al indentificarse. Se detectó actividad sospechosa.'
};

export const ConstantValidateUserMessage = {
    UserValidateMessages: {
        userId: {
            required: 'El campo es requerido'
        },
        userPassword: {
            required: 'El campo es requerido'
        }
    }
};

export const SalesChannel = {
    FUERZA_VTA_EXTERNA: '2'
};

export const AUDITORIA_GENERAL_TIPO = {
    SIMULACION: 1,
    PAYOFF: 2,
    BIOMETRICO: 3
};

export const TIPO_OFERTA_TC = {
    PRE_EMISION: "01",
    REACTIVACION_ESPECIAL: "02",
    REEMISION_ESPECIAL: "03",
}

export const REEMISION_OPCION = {
    OPCION1: "01",
    OPCION2: "02",
    OPCION3: "03",
}

export const GenericConstants ={
    TitleModal : {
        ADD: (label) => `Agregar ${label}`,
        CREATE: (label) => `Crear ${label}`,
        NEW: (label) => `Nuevo ${label}`,
        UPDATE: (label) => `Actualizar ${label}`,
        EDIT: (label) => `Editar ${label}`,
        DELETE: (label) => `Eliminar ${label}`,
    },
    MessageModal : {
        OK:{
            CREATE: `Se creo el registro correctamente.`,
            DELETE: `Se elimino el registro correctamente.`,
            UPDATE: `Se actualizó el registro correctamente.`,
            UPDATE_EL: (label) => `Se actualizó el ${label} correctamente.`,
            UPDATE_LA: (label) => `Se actualizó la ${label} correctamente.`,
            CREATE_EL: (label) => `Se creó el ${label} correctamente.`,
            CREATE_LA: (label) => `Se creó la ${label} correctamente.`,
        }
    },
}

export const MESSAGE_TOKEN_SELECT =
{
    TOKEN_SMS: "Por SMS",
    TOKEN_EMAIL: "Por Email"
}

export const MESSAGE_FLOW_VALIDATION =
{
    CASHIER : "!Su operación se ha enviado a la ventanilla¡",
}

export const PIN_PAD =
{
    URL : "https://localhost:8450/PinPad/PinPad",
    TIMEOUT: '120000',
    TYPE : 'CHIP EMV CARD READ AND RESPONSE',
    WSS_USERNAME : 'WLP-U100',
    WSS_PASSWORD : '8N85wckfv$#%WMT@Or3t5SUfw#$a@%Oo',
    TRANSACTION_ID : 'TT24055HTQKQ',
    VALIDATE_PIN: 'VALIDATE PIN',
    CHANGE_PIN: 'CHANGE PIN'
}

//export const VALIDATEPIN_TYPES = ["1", "2", "3", "4", "5", "7", "8", "9", "10", "11", "12","13", "14", "15","16", "17", "18", "19"];
export const CAMBIOCLAVE_TYPES = ["6"];

export const EFEX_MOTIVES =
{
    CONTINGENCIA_SUPERVISOR: 1,
    APROBACION_ANULACION: 2
}

export const MONITOR_ANSWER_CODE =
{
    APPROVED_GENERAL: "000",
    DENIED_GENERAL: "001",
    BIOMETRIC_VALIDATION_INCORRECT: "108",
    OTP_VALIDATION_INCORRECT: "101",
    OTP_VALIDATION_MULTIPLE_FAILURE: "109",
    PIN_INCORRECT: "104",
    PIN_VALIDATION_MULTIPLE_FAILURE: "109",
    MANUAL_CANCELATION: "106",
}

export const MONITOR_AUTENTICATION_TYPE =
{
    BIOMETRIC: "03",
    OTP: "01",
    PIN: "02",
    FUNCIONARIE: "07",
}

export const OFERTA  =
{
    SIN_DIFERIDO: "0",
    CON_DIFERIDO: "2",
}

export const PINPAD_ERROR_CODE =
{
    ERROR_VALIDATE_SERVICE_TICKET : "99",
    ERROR_REGISTER_TRACE : "98",
    ERROR_VALIDATE_CARD_NUMBER : "97",
    ERROR_EMPTY_CARD_NUMBER : "96",
    ERROR_EMPTY_CODE_RESULT : "95",
    ERROR_EMPTY_MASKPAN : "94",
    ERROR_EXCEPTION_RESPONSE_PINPAD : "XX",
    ERROR_HARDWARE : "100",
    ERROR_VALIDATE_PIN : "55",
}

export const PINPAD_VALIDATE_CODE =
{
    PINPAD_VALIDATE_SERVICE : "Ticket generado",
}

export const Orientation = {
  Horizontal: "H",
  Vertical: "V",
}

export const DialogType = {
  Warinng: "warinng",
  Info: "info",
  Success: "success",
  Error: "error",
}

export const TipoSolicitudBO = {
  Contactabilidad: 1,
  IncrementoLinea: 2,
  AsociarCuenta: 3,
  BloqueoDesbloqueo: 4,
  ServiciosAsociados: 5,
  CambioClave: 6,
  Mantenimiento: 7,
  HistoricoIndicadores: 8,
  CambioProducto:101,
  ServiciosAsociadosTC: 9,
}

export const TipoEnvio = {
  Fisico: 1,
  Virtual: 2,
  Ambos: 3
}

export const TipoModalidad = {
  Personal: 1,
  Laboral: 2,
  Ambos: 3
}

export const TipoValidacion = {
  Biometrico: 1,
  PinPad: 2
}