import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { SolicitudPut } from '../../../../models/solicitud';
import { VerificationDactilar } from '../../../../models/verification';
import { SolicitudService } from '../../../../services/solicitud.service';
import { VerificationService } from '../../../../services/verification.service';
import { AppConstants, MONITOR_ANSWER_CODE, MONITOR_AUTENTICATION_TYPE } from '../../../constants/app.constants';
import { AlertService, MessageSeverity } from '../../../services/alert.service';
import { ClientService } from '../../../../services/client.service';
import { StorageService } from '../../../services/storage.service';
import { Platform } from '@ionic/angular';
import * as FileSaver from 'file-saver';
import { NotificationService } from '../../../../services/notification.service';
import { Client } from '../../../../models/client';
import { ParameterService } from '../../../../services/parameter.service';
import { OnboardingIntegrationService } from '../../../../services/onboarding-integration.service';
import { UserService } from '../../../../services/user.service';
import { Parameter } from '../../../../models/parameter';
import { Cliente } from '../../../../models/simular';
import { timeout } from 'rxjs/operators';												   

@Component({
    selector: 'app-request-status-modal',
    templateUrl: './request-status-modal.component.html',
    styleUrls: ['./request-status-modal.component.css'],
})
export class RequestStatusModalComponent implements OnInit {
    arrayMessages = [];
    isLoadingProcess = false;
    model: SolicitudPut;
    type: string;
    verificacion: VerificationDactilar;
    messagestatusResult = '';
    classmessage = '';
    nameClient = '';
    codeCoco = '';
    isCoco = false;
    arrayConstancias: string[] = [];
    errorProcess = false;
    maxAttempts = false;
    maxiError = 0;
    codeProduct: string;
    tipoAutenticacion: string = "";
    userrolid: string = sessionStorage.getItem(AppConstants.Session.USERROLID);
    client: Client = new Client();
    
    
    pValidacionAgenciaProductos= [];
    codigoAsesorAlta: string;
    productoAlta: string;
    modalidadDesembolsoAlta: string;
    dniAsesorAlta:string;
    rolIDAlta: string;
    agencyCodeAlta: string;
    //scliente: Cliente = JSON.parse(sessionStorage.getItem(AppConstants.ObjectCliente.CLIENTE));
    //numeroSolicitudAlta: any = this.scliente.NumeroSolicitud;
    scliente: Cliente = JSON.parse(sessionStorage.getItem(AppConstants.ObjectCliente.CLIENTE) || '{}');
    numeroSolicitudAlta: any;

    constructor(
        private alertaService: AlertService,
        private solicitudService: SolicitudService,
        private router: Router,
        public modalRef: BsModalRef,
        private verificationService: VerificationService,
        private clientService: ClientService,
        private storageService: StorageService,
        private platform: Platform,
        private notificationService: NotificationService,
        private parameterService: ParameterService,
        private onboardingIntegrationService: OnboardingIntegrationService,
        private userService: UserService,
		
    ) {
    }

    async ngOnInit() {
        this.isLoadingProcess = true;
        this.numeroSolicitudAlta = this.scliente.NumeroSolicitud;
        if (this.numeroSolicitudAlta == null || this.numeroSolicitudAlta === '') {
            const bandejaData = JSON.parse(sessionStorage.getItem(AppConstants.ObjectCliente.BANDEJA) || '{}');
            this.numeroSolicitudAlta = bandejaData.NumeroSolicitud;
        }
        await this.GetParameters([AppConstants.ParameterCode.VALIDACION_AGENCIA_PRODUCTOS]);
        try {
            await this.callGetClientTransaccion(this.numeroSolicitudAlta);
            await this.ObtenerDatosUsuarioxIdONB(this.codigoAsesorAlta);
            await this.ObtenerModalidadAlta(this.numeroSolicitudAlta);
        } catch (error) {
            console.error('Error al inicializar los datos necesarios para actualizar la agencia', error);
        }
        
        const validacionExitosa = await this.validacionAgenciaONByT24(this.productoAlta);
        console.log('Resultado de validacionExitosa:', validacionExitosa);
        if (!validacionExitosa) {
            this.alertaService.showMessage('ERROR', 'El usuario no tiene una agencia registrada en T24 o es diferente de ONB, favor de actualizarlo y volver a intentar.', MessageSeverity.error);
            this.closeAlertModal();
            }else{
            this.initializeParams();
            this.CallFinalizar();
        }
    }

    downloadConstances(): void {
        for (const contance of this.arrayConstancias) {
            this.isLoadingProcess = true;
            this.clientService.downloadConstanciaRestriction(contance).subscribe(
                (response) => {
                    const data: Blob = new Blob([response], {
                        type: response.type
                    });
                    const nameFile = `${contance}.pdf`;
                    if (!this.platform.is('cordova')) {
                        FileSaver.saveAs(data, nameFile);
                    } else {
                        this.storageService.WriteFileMovil(nameFile, data);
                    }

                },
                (error) => {
                    this.alertaService.showMessage(AppConstants.TitleModal.DOWNLOAD_FILE, error.messages, MessageSeverity.error);
                }
            ).add(() => {
                this.isLoadingProcess = false;
            });
        }
    }

    FinalizarSolicitud(mode: SolicitudPut) {
        console.log('FinalizarSolicitud');
        console.log(mode);
        this.solicitudService.updateStateFinish(this.model).subscribe(async (response) => {
            this.arrayMessages = [...response.messages.filter(f => f.status !== 'NA')];
            console.log(this.arrayMessages);
            this.maxAttempts = response.maxAttempts;
            this.maxiError = 0;
            if (response.codigo === 400) {
                this.errorProcess = true;
                this.isLoadingProcess = false;
                this.classmessage = 'modal-texto-error';
                this.messagestatusResult = this.maxAttempts === true ? 'Usted superó el número de reintentos, favor comunicarse con el área de soporte.' : 'Finalizado con Error, porfavor vuelva a intentar';
            } else if (response.pending && !response.finish) {
                this.errorProcess = false;
                this.CallFinalizar();
            } else if (!response.pending && response.finish) {
                this.errorProcess = false;
                this.classmessage = 'modal-texto-exito';
                this.messagestatusResult = 'FINALIZADO CON EXITO';
                this.nameClient = response.cocoClientFullName;
                this.codeCoco = response.cocoCode;
                this.isCoco = response.isCoco;

                if (response.ConstanciasActivacion && response.ConstanciasActivacion.length > 0) {
                    this.arrayConstancias = response.ConstanciasActivacion.filter(x => x);
                } else {
                    this.arrayConstancias = [];
                }

                this.isLoadingProcess = false;
                switch (this.type) {
                    case 'TRAY_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT,
                            AppConstants.MessageModal.CREDIT_MANAGEMENT_OK, MessageSeverity.success);
                        this.router.navigateByUrl('bandeja');
                        break;
                    case 'VALIDATION_VIEW':
                        this.router.navigateByUrl('validacion/succes');
                        break;
                    case 'TRAY_VIEW_SUPERVISOR':
                        // tslint:disable-next-line:no-shadowed-variable
                        // await this.verificationService.validateDactilar(this.verificacion).toPromise().then((response) => {
                        // });
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT,
                            AppConstants.MessageModal.CREDIT_MANAGEMENT_OK, MessageSeverity.success);
                        this.router.navigateByUrl('bandeja');
                        break;
                }
            } else {
                this.isLoadingProcess = false;
            }
        },
            (error: HttpErrorResponse) => {
                // this.loading = false;
                this.isLoadingProcess = false;
                this.errorProcess = true;
                this.maxiError = this.maxiError + 1;
                if (this.maxiError == 3) {
                    this.maxAttempts = true;
                }
                let responseError = error.error;
                console.log('responseError error', error);
                if (responseError) {
                    if (responseError.code === 409) {
                        this.errorProcess = true;
                        this.isLoadingProcess = false;
                        this.classmessage = 'modal-texto-error';
                        this.messagestatusResult = responseError.messages[0];
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT, this.messagestatusResult, MessageSeverity.error);
                        return;
                    }
                    if (responseError.code === 999) {
                        console.log('ERROR',responseError);
                        this.errorProcess = true;
                        this.isLoadingProcess = false;
                        this.classmessage = 'modal-texto-error';
                        this.messagestatusResult = 'Finalizado con Error, porfavor vuelva a intentar';
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT, responseError.messages[0], MessageSeverity.error);
                        let resp = JSON.parse(responseError.messages[1]);
                        this.arrayMessages = [...resp.messages.filter(f => f.status !== 'NA')];
                        return;
                    }
                }
                switch (this.type) {
                    case 'TRAY_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                    case 'VALIDATION_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                    case 'TRAY_VIEW_SUPERVISOR':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                }
            });
    }

    CallFinalizar() {

        if (this.codeProduct == AppConstants.Products.EFEX) {
            this.FinalizarSolicitudEFEX(this.model);
        } else {
            this.FinalizarSolicitud(this.model);
        }
    }

    async ObtenerDatosUsuarioxIdONB(codigoUsuario: string): Promise<void> {
        this.isLoadingProcess = true;
        const modelRequest = { userId: codigoUsuario };
    
        try {
            const response = await this.parameterService.ObtenerDatosUsuarioxId(modelRequest).toPromise();
            this.dniAsesorAlta = response.userDocumentNumber;
            this.agencyCodeAlta = response.agencyCode;
        } catch (error) {
            this.alertaService.showMessage(
                AppConstants.TitleModal.ERROR_GETINFO_USERFROM_ONB, 
                error.error.messages, 
                MessageSeverity.error
            );
        } finally {
            this.isLoadingProcess = false;
        }
    }
    

    async ObtenerModalidadAlta(nroSolicitud: string): Promise<void> {
        this.isLoadingProcess = true;
        const modelRequest = { solId: nroSolicitud };
    
        try {
            const response = await this.solicitudService.getWorkFlowSummary(modelRequest).toPromise();
            this.modalidadDesembolsoAlta = response.modoDesembolso.substring(2); // Eliminar los primeros dos caracteres '00'
        } catch (error) {
            this.alertaService.showMessage(
                AppConstants.TitleModal.ERROR_GETINFO_USERFROM_ONB, 
                error.error.messages, 
                MessageSeverity.error
            );
        } finally {
            this.isLoadingProcess = false;
        }
    }
    

    async callGetClientTransaccion(code: string): Promise<void> {
        this.isLoadingProcess = true;
        try {
            const response = await this.clientService.getClient(code).toPromise();
            this.client = response;
            this.codigoAsesorAlta = response.userCreation;
            this.productoAlta = response.offerProduct;
        } catch (error) {
            console.error('Error al obtener la transacción del cliente', error);
        } finally {
            this.isLoadingProcess = false;
        }
    }

      async UpdateAgencyUserFromParameter(code: string, idasesor: string) {
        let model = { agencyCode: code, userId: idasesor };
        await this.userService.updateAgencyFromParameter(model).toPromise().then((response) => {

        },
            (error: HttpErrorResponse) => {
                this.alertaService.showMessage(
                    AppConstants.TitleModal.ERROR_UPDATEUSER_AGENCY, 
                    error.error.messages, 
                    MessageSeverity.error
                );
            });
    }

    async GetParameters(array: Array<number>) {
        let modelRequest = { headerId: array };
        await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
            this.setVariablesParameters(response);
        },
            (error: HttpErrorResponse) => {
            });
    }


    setVariablesParameters(listParameter: Parameter[]) {
        this.pValidacionAgenciaProductos=listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.VALIDACION_AGENCIA_PRODUCTOS && f.tabDetState == AppConstants.StateFlag.Activo);
    }

    

    validarProductoModalidad(codigoproducto: string): boolean {
        const modalidadId = this.modalidadDesembolsoAlta;
        return this.pValidacionAgenciaProductos.some(f => 
            f.tabDetCode001 === codigoproducto && 
            f.tabDetCode007 && 
            f.tabDetCode007.includes(`|${modalidadId}|`)
        );
    }

    async validacionAgenciaONByT24(codigoproducto: string): Promise<boolean> {
        const request = {
            documentNumber: this.dniAsesorAlta
        };
        this.isLoadingProcess = true;
    
        try {
            if (this.validarProductoModalidad(codigoproducto)) {
                
                const response = await this.onboardingIntegrationService.GetUserAgencyFromParameters(request).toPromise();
                
                if (response.agencyCode !== this.agencyCodeAlta) {
                    await this.UpdateAgencyUserFromParameter(response.agencyCode, this.codigoAsesorAlta);
                    // Mensaje de éxito si lo deseas.
                }
            }
            return true; // Devolver true si todo salió bien.
        } catch (error) {
            // Capturamos el error y mostramos el mensaje.
            console.error('Se presento un error:', error);
        //     this.alertaService.showMessage(
        //         AppConstants.TitleModal.ERROR_ACTUALIZAR_AGENCIA, 
        //         error.error.messages || 'Error al actualizar la agencia',
        //         MessageSeverity.error
        //     );
            return false; // Devolver false si hay un error.
        } finally {
            // Desactivar la bandera de carga independientemente de si hay error o no.
            this.isLoadingProcess = false;
        }
    }
    


    closeAlertModal() {
        this.modalRef.hide();
    }

    retryAgain() {
        this.isLoadingProcess = true;
        this.errorProcess = false;
        this.arrayMessages = [];
        this.messagestatusResult = '';
        this.classmessage = '';
        this.CallFinalizar();
    }

    FinalizarSolicitudEFEX(mode: SolicitudPut) {
        this.solicitudService.updateStateFinishEFEX(this.model).subscribe(async (response) => {
            this.arrayMessages = [...response.messages.filter(f => f.status !== 'NA')];
            console.log(this.arrayMessages);
            this.maxAttempts = response.maxAttempts;
            this.maxiError = 0;
            if (response.codigo === 400) {
                this.errorProcess = true;
                this.isLoadingProcess = false;
                this.classmessage = 'modal-texto-error';
                this.messagestatusResult = this.maxAttempts === true ? 'Usted superó el número de reintentos, favor comunicarse con el área de soporte.' : 'Finalizado con Error, porfavor vuelva a intentar';

                if (this.maxAttempts) {
                    this.sendMonitor(MONITOR_ANSWER_CODE.PIN_VALIDATION_MULTIPLE_FAILURE, this.tipoAutenticacion, this.model.solId);
                } else {
                    this.sendMonitor(MONITOR_ANSWER_CODE.DENIED_GENERAL, this.tipoAutenticacion, this.model.solId);
                }

            } else if (response.pending && !response.finish) {
                this.errorProcess = false;
                this.CallFinalizar();
            } else if (!response.pending && response.finish) {
                this.errorProcess = false;
                this.classmessage = 'modal-texto-exito';
                this.messagestatusResult = 'FINALIZADO CON EXITO';
                this.nameClient = response.cocoClientFullName;
                this.codeCoco = response.cocoCode;
                this.isCoco = response.isCoco;

                if (response.ConstanciasActivacion && response.ConstanciasActivacion.length > 0) {
                    this.arrayConstancias = response.ConstanciasActivacion.filter(x => x);
                } else {
                    this.arrayConstancias = [];
                }

                this.sendMonitor(MONITOR_ANSWER_CODE.APPROVED_GENERAL, this.tipoAutenticacion, this.model.solId);


                this.isLoadingProcess = false;
                switch (this.type) {
                    case 'TRAY_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT,
                            AppConstants.MessageModal.CREDIT_MANAGEMENT_OK, MessageSeverity.success);
                        this.router.navigateByUrl('bandeja');
                        break;
                    case 'VALIDATION_VIEW':
                        this.router.navigateByUrl('validacion/succes');
                        break;
                    case 'TRAY_VIEW_SUPERVISOR':
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT,
                            AppConstants.MessageModal.CREDIT_MANAGEMENT_OK, MessageSeverity.success);
                        this.router.navigateByUrl('bandeja');
                        break;
                }
            } else {
                this.isLoadingProcess = false;
            }
        },
            (error: HttpErrorResponse) => {
                // this.loading = false;
                this.isLoadingProcess = false;
                this.errorProcess = true;
                this.maxiError = this.maxiError + 1;
                if (this.maxiError == 5) {
                    this.maxAttempts = true;
                    if (this.maxAttempts) {
                        this.sendMonitor(MONITOR_ANSWER_CODE.PIN_VALIDATION_MULTIPLE_FAILURE, this.tipoAutenticacion, this.model.solId);
                    }
                } else {
                    this.sendMonitor(MONITOR_ANSWER_CODE.DENIED_GENERAL, this.tipoAutenticacion, this.model.solId);
                }
                let responseError = error.error;
                console.log('responseError error', error);
                if (responseError) {
                    if (responseError.code === 409) {
                        this.errorProcess = true;
                        this.isLoadingProcess = false;
                        this.classmessage = 'modal-texto-error';
                        this.messagestatusResult = responseError.messages[0];
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT, this.messagestatusResult, MessageSeverity.error);
                        return;
                    }
                    if (responseError.code === 999) {
                        console.log('ERROR',responseError);
                        this.errorProcess = true;
                        this.isLoadingProcess = false;
                        this.classmessage = 'modal-texto-error';
                        this.messagestatusResult = 'Finalizado con Error, porfavor vuelva a intentar';
                        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT, responseError.messages[0], MessageSeverity.error);
                        let resp = JSON.parse(responseError.messages[1]);
                        this.arrayMessages = [...resp.messages.filter(f => f.status !== 'NA')];
                        return;
                    }
                }
                switch (this.type) {
                    case 'TRAY_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                    case 'VALIDATION_VIEW':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                    case 'TRAY_VIEW_SUPERVISOR':
                        this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                            MessageSeverity.error);
                        break;
                }
            });
    }

    async sendMonitor(codRespuesta: string, tipoAutenticacion: string, solId: string) {
        await this.notificationService.postRegisterMonitor(codRespuesta, tipoAutenticacion, solId).toPromise().then();
    }

    initializeParams() {

        if (this.codeProduct == AppConstants.Products.EFEX) {
            if (this.userrolid == AppConstants.RolesCode.PROVEEDORCALLCENTER) {
                this.tipoAutenticacion = MONITOR_AUTENTICATION_TYPE.OTP;
            } else {
                this.tipoAutenticacion = `${MONITOR_AUTENTICATION_TYPE.BIOMETRIC}${MONITOR_AUTENTICATION_TYPE.OTP}${MONITOR_AUTENTICATION_TYPE.PIN}`;
            }
        }
    }

}
