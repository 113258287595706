import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'localDateFormat' })
export class FormatDateHourToLocal extends DatePipe implements PipeTransform {
  transform(dateToFormat: string) {
    var split = dateToFormat.split(' ');
    var dateTryFormat = new Date(dateToFormat).toString();
    if (dateTryFormat.toUpperCase() == "INVALID DATE") {
      return dateToFormat;
    }

    if (split[0].length < 10)
      return dateToFormat;

    var result = super.transform(dateToFormat, "dd/MM/yyyy HH:mm:ss","UTC");
    return result;
  }
}

export class FormatDateToLocal extends DatePipe implements PipeTransform {
  transform(dateToFormat: string) {
    var dateTryFormat = new Date(dateToFormat).toString();
    if (dateTryFormat.toUpperCase() == "INVALID DATE") {
      return dateToFormat;
    }
    var result = super.transform(dateToFormat, "dd/MM/yyyy");
    return result;
  }
}

@Pipe({ name: 'localDateFormat' })
export class FormatDateHourToLocalBO extends DatePipe implements PipeTransform {
  transform(dateToFormat: string) {
    var split = dateToFormat.split(' ');
    var dateTryFormat = new Date(dateToFormat).toString();
    if (dateTryFormat.toUpperCase() == "INVALID DATE") {
      return dateToFormat;
    }

    if (split[0].length < 10)
      return dateToFormat;

    var result = super.transform(dateToFormat, "dd/MM/yyyy HH:mm:ss","locale");
    return result;
  }
}
