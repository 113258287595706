import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HelperService } from '../../shared/services/helper.service';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { HistoricoTD, ResponseProcessSolicitudBO, SolicitudBOAsoCta, SolicitudBOContact, SolicitudBOCredito, SolicitudBODebito, SolicitudBoSession, SolicitudBOTCredito, SolicitudHistoricoTD } from '../../models/solicitud-bo';
import { ConfigSolicitud, ControlValidation, RequestValidatePinpadTicket, ResponseValidate, SolicitudPut, ValidateStateSolicitud2 } from '../../models/solicitud';
import { TipoSolicitudBO } from '../../shared/constants/app.constants';
import { map } from "rxjs/operators";
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class SolicitudService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "Solicitud";
  private BASE_URL: string = HelperService.buildRequestURL(environment.msBackofficeApi);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;
  private SolicitudSessionKey: string = 'solicitudBo';

  setSolicitudBoSession(model: SolicitudBoSession){
    sessionStorage.setItem(this.SolicitudSessionKey, JSON.stringify(model));
  }
  getSolicitudBoSession() : SolicitudBoSession{
    const solicitudSession = sessionStorage.getItem(this.SolicitudSessionKey);
    if (solicitudSession) {
      const solicitud = JSON.parse(solicitudSession);
      return { ...solicitud } as SolicitudBoSession;
    }
    return new SolicitudBoSession();
  }

  createSolicitudCredito(request: SolicitudBOCredito) {
    return this.httpClient.post<string>(`${this.REQUEST_URL}/TarjetaCredito/Create`, request);
  }
  updateSolicitudCredito(request: SolicitudBOCredito) {
    return this.httpClient.post<string>(`${this.REQUEST_URL}/TarjetaCredito/Update`, request);
  }

  getSolicitudCredito(solicitudId: string): Observable<SolicitudBOCredito> {
    return this.httpClient.get<SolicitudBOCredito>(`${this.REQUEST_URL}/TarjetaCredito/Get?SolicitudId=${solicitudId}`);
  }

  createSolicitudDebito(request: SolicitudBODebito) {
    return this.httpClient.post<string>(`${this.REQUEST_URL}/TarjetaDebito/Create`, request);
  }

  updateSolicitudDebito(request: SolicitudBODebito) {
    return this.httpClient.post<string>(`${this.REQUEST_URL}/TarjetaDebito/Update`, request);
  }

  updateSolicitudTarjCredito(request: SolicitudBOTCredito) {
    return this.httpClient.post<string>(`${this.REQUEST_URL}/TarjetaCredito/Update`, request);
  }

  getSolicitudDebito(solicitudId: string): Observable<SolicitudBODebito> {
    return this.httpClient.get<SolicitudBODebito>(`${this.REQUEST_URL}/TarjetaDebito/Get?SolicitudId=${solicitudId}`);
  }

  getSolicitudTarjCredito(solicitudId: string): Observable<SolicitudBOTCredito> {
    return this.httpClient.get<SolicitudBOTCredito>(`${this.REQUEST_URL}/TarjetaCredito/Get?SolicitudId=${solicitudId}`);
  }

  createAsociarCuenta(request: SolicitudBOAsoCta) {
    return this.httpClient.post<string>(`${this.REQUEST_URL}/AsociarCuenta/Create`, request);
  }

  getAsociarCuenta(solicitudId: string): Observable<SolicitudBOAsoCta> {
    return this.httpClient.get<SolicitudBOAsoCta>(`${this.REQUEST_URL}/AsociarCuenta/Get?SolicitudId=${solicitudId}`);
  }

  createContactabilidad(request: SolicitudBOContact) {
    return this.httpClient.post<string>(`${this.REQUEST_URL}/Contactabilidad/Create`, request);
  }

  updateContactabilidad(request: SolicitudBOContact) {
    const model = {
      solicitudBOId: request.solicitudBOId,
      tipoEnvio: request.tipoEnvio,
      modalidad: request.modalidad,
      numeroCuenta: request.numeroCuenta
    }
    return this.httpClient.post<string>(`${this.REQUEST_URL}/Contactabilidad/Update`, model);
  }

  getContactabilidad(solicitudId: string): Observable<SolicitudBOContact> {
    return this.httpClient.get<SolicitudBOContact>(`${this.REQUEST_URL}/Contactabilidad/Get?SolicitudId=${solicitudId}`);
  }

  solicitudProcess(solicitudId: string): Observable<ResponseProcessSolicitudBO>{
    const model ={
      solId:solicitudId,
      userId: "",
      userRol:"",
      userRolId:"",
      token: ""
    }
    return this.httpClient.put<ResponseProcessSolicitudBO>(`${this.REQUEST_URL}/Update/finish`, model);
  }

  getConfigSolicitud(tipoSolicitud: number): ConfigSolicitud | undefined {
    /*
    Control 1 = Biometrico
    Control 2 = PinPad
    */
    const configMap: { [key: number]: ConfigSolicitud } = {
      [TipoSolicitudBO.Contactabilidad]: new ConfigSolicitud(
        "FORMA DE ENVÍO DE EE.CC.",
        "envio-eecc.png",
        "/contactabilidad/edit",
        "/contactabilidad",
        [
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.IncrementoLinea]: new ConfigSolicitud(
        "INCREMENTO DE LÍNEA",
        "incremento-linea.png",
        "/productsList/lineIncrement",
        "/productsList",
        [
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.AsociarCuenta]: new ConfigSolicitud(
        "ASOCIAR CUENTA A TARJETA",
        "asociar-cta.png",
        "/debitcard/asociar-cuenta-validate",
        "/debitcard/asociar-cuenta",
        [
          new ControlValidation(1, 2, true),
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.BloqueoDesbloqueo]: new ConfigSolicitud(
        "BLOQUEO Y DESBLOQUEO DE TD",
        "bloqueo-td.png",
        "/debitcard/bloqueo-edit",
        "/debitcard/bloquear-td",
        [
          new ControlValidation(1, 2, true),
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.ServiciosAsociados]: new ConfigSolicitud(
        "SERVICIOS ASOCIADOS",
        "serv-asoc.png",
        "/debitcard/servicios-asociados-edit",
        "/debitcard/servicios-asociados",
        [
          new ControlValidation(1, 2, true),
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.CambioClave]: new ConfigSolicitud(
        "CAMBIO DE CLAVE",
        "cclave.png",
        "/debitcard/cambio-clave-edit",
        "/debitcard/cambio-clave",
        [
          new ControlValidation(1, 2, true),
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.Mantenimiento]: new ConfigSolicitud(
        "MANTENIMIENTO DE CUENTAS DE TD",
        "mtto-td.png",
        "/debitcard/mantenimiento-td-edit",
        "/debitcard/mantenimiento-td",
        [
          new ControlValidation(1, 2, true),
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.CambioProducto]: new ConfigSolicitud(
        "CAMBIO DE PRODUCTO",
        "icon_cp.png",
        "/productsList/cambioProd",
        "/productsList",
        [
          new ControlValidation(1, 0, true)
        ]
      ),
      [TipoSolicitudBO.ServiciosAsociadosTC]: new ConfigSolicitud(
        "SERVICIOS ASOCIADOS",
        "serv-asoc3.png",
        "/debitcard/servicios-asociados-edit",
        "/debitcard/servicios-asociados",
        [
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.BloqueoDesbloqueoRSAT]: new ConfigSolicitud(
        "BLOQUEO Y DESBLOQUEO DE PRODUCTO",
        "bloqueo-td.png",
        "/creditcard/bloqueo-edit",
        "/creditcard/bloqueo",
        [
          new ControlValidation(1, 0, true)
        ]
      ),
      [TipoSolicitudBO.NotificacionesTCTD]: new ConfigSolicitud(
        "NOTIFICACIONES",
        "bloqueo-td.png",
        "/creditcard/notific-edit",
        "/creditcard/notificaciones",
        [
          new ControlValidation(1, 2, true),
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.CancelacionCuentaTC]: new ConfigSolicitud(
        "CANCELACION DE CUENTA",
        "serv-asoc3.png",
        "/creditcard/cancel-edit",
        "/creditcard/cancelacion",
        [
          new ControlValidation(2, 0, true)
        ]
      ),
      [TipoSolicitudBO.TarjetaAdicional]: new ConfigSolicitud(
        "TARJETAS ADICIONALES",
        "adic-tc-m.png",
        "/creditcard/adicional-tc-edit",
        "/creditcard/adicional-tc",
        [
          new ControlValidation(1, 0, true)
        ]
      ),
      [TipoSolicitudBO.RemisionImpresion]: new ConfigSolicitud(
        "HABILITAR TARJETA",
        "mtto-td.png",
        "/creditcard/habilitar-edit",
        "/creditcard/habilitar",
        [
          new ControlValidation(2, 0, true)
        ]
      )
    };

    return configMap[tipoSolicitud];
  }

  getHistoricoTD(numeroCuenta: string, numeroTarjeta: string): Observable<HistoricoTD[]> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/Historico/Get?NumeroCuenta=${numeroCuenta}&NumeroTarjeta=${numeroTarjeta}`).pipe(
      map( response =>
        response.listadoHistoricoTD.map(apiData => ({
          numeroTarjeta : apiData.numeroTarjeta,
          numeroCuenta : apiData.numeroCuenta,
          solicitudBOId : apiData.solicitudBOId,
          fechaInicio : moment(apiData.fechaInicio).format('DD/MM/YYYY'),
          usuarioId : apiData.usuarioId,
          consumosInternet : apiData.consumosInternet,
          consumosExtranjero : apiData.consumosExtranjero,
          disposicionEfectivo : apiData.disposicionEfectivo
        }))
      )
    );
  }

  validatePinpadTicket(model: RequestValidatePinpadTicket): Observable<ResponseValidate> {
    return this.httpClient.post<ResponseValidate>(`${this.REQUEST_URL}/Validate/PinpadTicket`, model);
  }

  getValidateStateSolicitud(solicitudId: string) {
    return this.httpClient.get<ValidateStateSolicitud2>(`${this.REQUEST_URL}/ValidateState/${solicitudId}`)
  }

  updateStateSolicitud(model: SolicitudPut): Observable<SolicitudPut> {
    return this.httpClient.put<SolicitudPut>(`${this.REQUEST_URL}/Update/State`, model);
  }

}
