import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'solicitud-supervisor',
  templateUrl: './solicitud-supervisor.component.html',
  styleUrls: ['./solicitud-supervisor.component.scss'],
})
export class SolicitudSupervisorComponent implements OnInit {
  @Output() return = new EventEmitter();
  constructor() { }

  ngOnInit() {
    sessionStorage.removeItem('dataServAsoc');
    sessionStorage.removeItem('CLIENTE');
    sessionStorage.removeItem('PRODUCTO');
    sessionStorage.removeItem('datosBloDesbTC');
    sessionStorage.removeItem('searchState');
    sessionStorage.removeItem('observacionesBloqueo');
    sessionStorage.removeItem('solicitudBo');
    sessionStorage.removeItem('motivoBloqueo');
    sessionStorage.removeItem('activeProducts');
    sessionStorage.removeItem('clientInfo');
    sessionStorage.removeItem('notificEditData');
    sessionStorage.removeItem('selectedRow');
    sessionStorage.removeItem('tableDataNotis');
  }

  onReturn() {
    this.return.emit();
  }

}
