import { Injectable } from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { Platform } from '@ionic/angular';
import { FCM } from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import { BehaviorSubject } from 'rxjs';
import { AlertPopupService } from '../shared/services/alert-popup.service';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {

  constructor(
    private fcm: FCM,
    private afMessaging: AngularFireMessaging,
    private notificationService: NotificationService,
    private platform: Platform,
    private alertPopupService: AlertPopupService 
  ) { }

  currentMessage = new BehaviorSubject(null);
  token : string;
  messageResponse : any;

  requestPushNotificationsPermission(channelId : number){
    this.afMessaging.requestToken // getting tokens
      .subscribe(
        (token) => { // USER-REQUESTED-TOKEN
          this.token = token;

          var model = { deviceToken : this.token, channelId : channelId};
          this.notificationService.postRegisterDeviceToken(model).subscribe((response) => {
          },
          (error) => {
          });

        },
        (error) => {
          console.error(error);
        }
      );
      return this.token;
  }

  async receiveMessage() {


    await this.platform.ready();

    if (!this.platform.is('cordova')) {   
      this.afMessaging.messages.subscribe((payload) => {
        this.messageResponse = payload;
        this.currentMessage.next(payload);

        if(this.messageResponse.notification != undefined || this.messageResponse.notification != null){
          //this.alertService.showMessage(this.messageResponse.notification.title, this.messageResponse.notification.body, MessageSeverity.warn);
          this.alertPopupService.Alert(this.messageResponse.notification.title, "",this.messageResponse.notification.body);
        }
      },
      (error) => {
        console.error(error);
      });      
    } else {
      this.fcm.onNotification().subscribe(data => {
        if(!data.wasTapped){
          if(data.title != undefined || data.title != null){
            //this.alertService.showMessage(data.title, data.body, MessageSeverity.warn);
            this.alertPopupService.Alert(data.title, "",data.body);
          }
        }
      }, (error) => {
      });
    }
    
    return this.messageResponse;
  }

  async requestTokenMovil(channelId : number){
    this.fcm.getToken().then((newToken) => {
      this.token = newToken;
      var model = { deviceToken : this.token, channelId : channelId};
      this.notificationService.postRegisterDeviceToken(model).subscribe((response) => {
      },
      (error) => {
      });

    }, (error) => {
    });
    return this.token;
  }

}
