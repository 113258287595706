import { Component, Input, OnInit } from '@angular/core';
import { BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { AlertService } from '../../../services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
    selector: 'app-info-insurance-modal',
    templateUrl: './info-insurance-modal.component.html',
    styleUrls: ['./info-insurance-modal.component.scss']
})
export class InfoInsuranceModalComponent implements OnInit {

    loading: boolean = false;
    baksScheduleList = [];
    infoText1: string[] = [];
    infoText2: string[] = [];
    infoText3: string[] = [];

    constructor(
        private modalShedule: BsModalRef,
        public options: ModalOptions,
        private alertaService: AlertService
    ) { 
        console.log(this.options.initialState);
    }

    ngOnInit() {
        this.getBanksSchedule();
    }

    getBanksSchedule() {
    
    const resp: any = this.options.initialState
    const parraf1 = resp.data[0];
    const parraf2 = resp.data[1];
    const parraf3 = resp.data[2];
    const fieldsToPush = ["tabDetCode006", "tabDetCode007", "tabDetCode008","tabDetCode009"];

    for (const field of fieldsToPush) {
        if (parraf1[field] !== undefined && parraf1[field] !== null) {
            this.infoText1.push(parraf1[field]);
        }
        if (parraf2[field] !== undefined && parraf2[field] !== null) {
            this.infoText2.push(parraf2[field]);
        }
        if (parraf3[field] !== undefined && parraf3[field] !== null) {
            this.infoText3.push(parraf3[field]);
        }
    }
    }

    close() {
        this.modalShedule.hide();
    }
}
