import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-coco-cappta-modal',
  templateUrl: './coco-cappta-modal.component.html',
  styleUrls: ['./coco-cappta-modal.component.scss'],
})
export class CocoCapptaModalComponent implements OnInit {

  data: any;
  public onClose: Subject<any>;

  constructor(
    private modalfinancialdata: BsModalRef,
  ) { }

  ngOnInit() {
    this.onClose = new Subject();
  }

  buttonAccep(data : any){
    this.onClose.next(data);
      this.modalfinancialdata.hide();
  }

}
