import { Component, OnInit } from '@angular/core';
import { AppConstants } from '../../constants/app.constants';

@Component({
  selector: 'onb-information-user',
  templateUrl: './onb-information-user.component.html',
  styleUrls: ['./onb-information-user.component.css']
})
export class OnbInformationUserComponent implements OnInit {

  agencyCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE);
  agencyName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME);
  usersessionId: string = sessionStorage.getItem(AppConstants.Session.USERID);
  channelName: string = sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION);

  constructor() { }

  ngOnInit() {
  }

}
