import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { DialogType } from '../../constants/app.constants';

@Component({
  selector: 'onb-dialog',
  templateUrl: './onb-dialog.component.html',
  styleUrls: ['./onb-dialog.component.css']
})
export class OnbDialogComponent implements OnInit {
  
  title: string;
  type: string;
  subTitle: string;
  message: string;
  buttons: { text: string, class?: string, width?:string, handler: () => void }[];
  onClose: (() => void) | undefined;

  image: string;
  showIcon: boolean;

  constructor(
    private bsModalRef: BsModalRef
  ) {
    this.title = "";
    this.subTitle = "";
    this.message = "";
    this.buttons = [];
  }

  ngOnInit(): void {    
    switch(this.type){
      case DialogType.Success:
        this.image = "../assets/images/success-img.png";
        break;
      case DialogType.Warinng:
      case DialogType.Error:
        this.image = "../assets/images/warning-img.png";
        break;
    }
    this.showIcon = (this.type == DialogType.Success || this.type == DialogType.Warinng || this.type == DialogType.Error);    
  }
  close() {
    if (this.onClose) {
      this.onClose(); // Llama a la función de callback
    }
    this.bsModalRef.hide();
  }


}
