import { Component, EventEmitter, Input, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'onb-search',
  templateUrl: './onb-search.component.html',
  styleUrls: ['./onb-search.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => OnbSearchComponent),
      multi: true
    }
  ]
})
export class OnbSearchComponent implements ControlValueAccessor {
  @Input() label: string;
  @Input() placeHolder: string = '';
  @Output() search = new EventEmitter<string>();
  @Output() clear = new EventEmitter();

  value = '';

  // Función para guardar la referencia a la función onChange
  onChange: any = () => {};

  // Función para guardar la referencia a la función onTouch
  onTouch: any = () => {};

  // Método que se llama cuando el valor del campo cambia
  onInput(event: any) {
    this.value = event.target.value;
    this.onChange(event.target.value);
    this.onTouch();
  }

  onSearch() {
    this.search.emit(this.value);
  }
  onClear() {
    this.clear.emit();
  }

  // Las siguientes funciones son parte de la implementación de ControlValueAccessor

  writeValue(value: any) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}