import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DeviceObject } from '../models/device';
import { HelperService } from '../shared/services/helper.service';
import { Device } from '@ionic-native/device/ngx';

@Injectable({
  providedIn: 'root'
})
export class DeviceService {

  deviceObj: DeviceObject;

  constructor(
    private httpClient: HttpClient,
    private device: Device
  ) { }

  private POST_ENDPOINT: string = 'Client';
  private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;
  private SERVER_ONE_IP: string = HelperService.buildRequestURL(environment.serviceIP_ONE);
  private SERVER_TWO_IP: string = HelperService.buildRequestURL(environment.serviceIP_TWO); 

  private async getIP(server : string, principalServer : boolean) {    
      await this.httpClient.get(server).toPromise()
      .then(element => {
          let obj = new DeviceObject();
          if(principalServer) {
            obj.city = element['city'];
            obj.timezone = element['timezone'];
            obj.isp = element['isp'];
            obj.region = element['region'];
            obj.country = element['country'];
            obj.ip = element['ip'];
            obj.continentCode = element['continent_code'];
            obj.countryCode = element['country_code'];
          }
          else 
          {
            obj.ip = element['ip'];
          }
          obj.status = true;
          this.deviceObj = obj;     
              
      })
      .catch(() => {
        let obj = new DeviceObject();
            obj.ip = '';
            obj.status = false;
            this.deviceObj = obj;
        return this.deviceObj;
      });   
    
    return this.deviceObj;
  }

  public async getDevicePropertis(codova : boolean) {
    let model = await this.getIP(this.SERVER_ONE_IP, true);
    if(model.status == false) {
      model = await this.getIP(this.SERVER_TWO_IP, false);
    }

    if(codova) {
      model.deviceUuid = this.device.uuid;
      model.deviceManufacturer = this.device.manufacturer;
      model.deviceVersion = this.device.version;
      model.deviceModel = this.device.model;
      model.platform = "MOVIL";
    }
    else {
      model.agent = window.navigator.userAgent;
      model.platform = "WEB";
    }

    return model;
  }

}
