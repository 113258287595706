import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { OnboardingIntegrationService } from '../../../../services/onboarding-integration.service';
import { AppConstants } from '../../../constants/app.constants';
import { AlertService, MessageSeverity } from '../../../services/alert.service';

@Component({
  selector: 'app-transfer-bank-schedules',
  templateUrl: './transfer-bank-schedules.component.html',
  styleUrls: ['./transfer-bank-schedules.component.scss'],
})
export class TransferBankSchedulesComponent implements OnInit {

  loading: boolean = false;
  baksScheduleList = [];

  constructor(
    private modalShedule: BsModalRef,
    private onboardingIntegrationService: OnboardingIntegrationService,
    private alertaService: AlertService
  ) { }

  ngOnInit() {
    this.getBanksSchedule();
  }

  getBanksSchedule() {
    this.loading = true;
    this.onboardingIntegrationService.getBanksSchedule().subscribe((response) => {
      this.baksScheduleList = response;
      this.loading = false;
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.alertaService.showMessage(AppConstants.TitleModal.BANKS_SCHEDULE, error.error.messages, MessageSeverity.error);
      });
  }

  close() {
    this.modalShedule.hide();
  }


}
