import { Injectable } from '@angular/core';
import { AppConstants, PIN_PAD } from '../constants/app.constants';
import { KeyConfigurationResponse } from '../../models/onboarding-integration';
import { OnboardingIntegrationService } from '../../services/onboarding-integration.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PinpadConnectService {

  Configuration = new KeyConfigurationResponse();
  WSParametersMap = [];
  pinpadOperationType = "";
  URLLocal = "";
  pinPadtimeOut = 0;
  CrsProcessResponseObj: any;
  pinpadError = false;
  localResponseObjJSON: any;
  t24TxnId: string;
  message: string = "";
  OperationTypeText = "OperationType=";
  txnIdtext = "t24TransactionId=";
  txtServiceData = "t24ServiceKey=";
  logMessage: string;
  pinpadErrorMessage = "";
  responseObjJSON: any;

  logInfo = true;
  logError = true;
  logTrace = true;
  logDebug = true;
  usersessionId: string = sessionStorage.getItem(AppConstants.Session.USERID);

  constructor(
    private onboardingIntegrationService: OnboardingIntegrationService) {
    this.WSParametersMap = this.getWsParameters(); 
  }

  async ConnectPINPAD() {
    let result;
    let model = {
      UserId: this.usersessionId
    }

    //await this.onboardingIntegrationService.getKeyConfiguration(model).toPromise().then((data) => {
    //this.Configuration = data;
    //sessionStorage.setItem(AppConstants.Session.CONFIGURATION_BUS, JSON.stringify(this.Configuration));
    result = await this.Initialize();    
    /*},
      (error: HttpErrorResponse) => {
        console.log(error);
      })*/

    return result;
  }

  async Initialize() {

    let result = null;
    this.t24TxnId = PIN_PAD.TRANSACTION_ID; 
    this.pinpadOperationType = PIN_PAD.TYPE; 
    this.WSParametersMap = this.getWsParameters(); 

    if (this.t24TxnId !== null && this.pinpadOperationType !== null && (this.t24TxnId.length > 0 && this.pinpadOperationType.length > 0)) {
      if (this.pinpadOperationType == "CHIP EMV CARD READ AND RESPONSE") {
        this.URLLocal = this.WSParametersMap["PINPAD.URL"];
        if (this.WSParametersMap["PINPAD.TIMEOUT"] != undefined) {
          this.pinPadtimeOut = this.WSParametersMap["PINPAD.TIMEOUT"];
        }
      }

      switch (this.pinpadOperationType) {
        case "CHIP EMV CARD READ AND RESPONSE":
          this.pinpadOperationType = "93";
          result = await this.Process95();
          break;
      }
    } else {
      console.log('NO EXISTE LA CONFIGURACION');
    }

    return result;
  }

  getWsParameters() {
    var WSMap = [];
    WSMap["PINPAD.URL"] = PIN_PAD.URL;
    WSMap["PINPAD.TIMEOUT"] = PIN_PAD.TIMEOUT;
    WSMap["wsse:Username"] = PIN_PAD.WSS_USERNAME;
    WSMap["wsse:Password"] = PIN_PAD.WSS_PASSWORD; 
    return WSMap;
  }

  async Process95() {
    let ticketResult = "";
    let CrsystemTicketNumberT24Obj = await this.ConnectLocalPostTypeHttp_V3(this.t24TxnId);  //  CR-IRD-020-08072021
    console.log("ticket", CrsystemTicketNumberT24Obj);

    if (this.pinpadError == false) {
      return CrsystemTicketNumberT24Obj;
    }

    return CrsystemTicketNumberT24Obj;
  }

  async ConnectLocalPostTypeHttp_V3(t24TxnId) {

    let ticketResult = "";
    try {
      
      let txtServiceDataText = encodeURIComponent(this.WSParametersMap["wsse:Password"]);
      this.message = this.OperationTypeText + this.pinpadOperationType + "&";
      this.message = this.message + this.txnIdtext + t24TxnId + "&";
      this.message = this.message + this.txtServiceData + txtServiceDataText + "&";

      const request = this.message;
      const url = this.URLLocal; 
      console.log('request pinpad',request);
      console.log('url pinpad',url);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: request,
      });
      console.log('response pinpad',response);
      if (!response.ok) {
        throw new Error("HTTP error! Status:" + response.status);
      }

      ticketResult = await response.text();
      return ticketResult;

    } catch (error) {
      console.error('Error generating ticket:', error.message);
      return null;
    }

  }


  async generateTicket() {
    try {
      let ticketResult = "";
      const XMLObject = this.generateXMLObject(); 
      const url = environment.serviceBus;

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'text/xml',
        },
        body: XMLObject,
      });

      if (!response.ok) {
        throw new Error("HTTP error! Status:" + response.status);
      }

      console.log('response', response);
      const text = await response.text();
      console.log('text', text);
      const res = text.replace(" <", "").replace(">", "").replace(" ", "");
      const pos = 742;
      ticketResult = res.substring(pos, pos + 24);

      if (ticketResult.length === 24) {
        console.log(ticketResult);

      } else {

      }

      return ticketResult;
    } catch (error) {
      console.error('Error generating ticket:', error.message);
      return null;
    }
  }

  generateXMLObject() {
    //this.Configuration =JSON.parse(sessionStorage.getItem(AppConstants.Session.CONFIGURATION_BUS));
    //console.log('credentials', this.Configuration.user, this.Configuration.password, this.Configuration.nonce);
    let UsernameToken = "UsernameToken-3543ED6FA02415371D15464502829641";
    let Username = "WLP-U100D";
    let Password = "Bc0R8nS2019";
    let Nonce = "z6jrUssvC4ajqjqHwrNdjg==";
    var xmlToSend = "<SOAP-ENV:Envelope xmlns:SOAP-ENV='http://schemas.xmlsoap.org/soap/envelope/' xmlns:v11='http://bancoripley.com.pe/srv/CRSystemsOperationsTicket/v1.0/' xmlns:v4='http://bancoripley.com.pe/srv/generic/messageFormat/v4.0/'>";
    xmlToSend += "  <SOAP-ENV:Header>";
    xmlToSend += "  <wsse:Security xmlns:wsse='http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-secext-1.0.xsd' xmlns:wsu='http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-wssecurity-utility-1.0.xsd' SOAP-ENV:mustUnderstand='1'>";
    xmlToSend += "     <wsse:UsernameToken wsu:Id='" + UsernameToken + "'>";
    xmlToSend += "        <wsse:Username>" + Username + "</wsse:Username>";
    xmlToSend += "        <wsse:Password Type='http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-username-token-profile-1.0#PasswordText'>" + Password + "</wsse:Password>";
    xmlToSend += "        <wsse:Nonce EncodingType='http://docs.oasis-open.org/wss/2004/01/oasis-200401-wss-soap-message-security-1.0#Base64Binary'>" + Nonce + "</wsse:Nonce>";
    xmlToSend += "        <wsu:Created>2021-02-04T09:21:43.951Z</wsu:Created>";
    xmlToSend += "     </wsse:UsernameToken>";
    xmlToSend += "  </wsse:Security>";
    xmlToSend += "  <HeaderRequest xmlns='v1'>";
    xmlToSend += "     <request>";
    xmlToSend += "        <serviceId>CRSystemsOperationsTicket</serviceId>";
    xmlToSend += "        <consumerId>155</consumerId>";
    xmlToSend += "        <moduleId>ModCaja</moduleId>";
    xmlToSend += "        <channelCode>04009</channelCode>";
    xmlToSend += "        <messageId>2020020415290450000</messageId>";
    xmlToSend += "        <timestamp>2020-02-047T09:21:43.953Z</timestamp>";
    xmlToSend += "        <countryCode>PE</countryCode>";
    xmlToSend += "        <groupMember>20</groupMember>";
    xmlToSend += "        <referenceNumber>TK02030</referenceNumber>";
    xmlToSend += "     </request>";
    xmlToSend += "     <identity>";
    xmlToSend += "        <netId>SB</netId>";
    xmlToSend += "        <userId>USRCLISBL</userId>";
    xmlToSend += "        <supervisorId>USRCLISBL</supervisorId>";
    xmlToSend += "        <deviceId>bqa2lb01p.bancoripley.pe</deviceId>";
    xmlToSend += "        <serverId>10.167.21.80</serverId>";
    xmlToSend += "        <branchCode>825</branchCode>";
    xmlToSend += "     </identity>";
    xmlToSend += "  </HeaderRequest>";
    xmlToSend += "</SOAP-ENV:Header>";
    xmlToSend += "<SOAP-ENV:Body>";
    xmlToSend += "  <v11:CreateITSystemOperatingSessionTicket>";
    xmlToSend += "     <ServiceRequestor>";
    xmlToSend += "        <PartyRole>POS</PartyRole>";
    xmlToSend += "     </ServiceRequestor>";
    xmlToSend += "     <BankingTransaction>";
    xmlToSend += "        <TransactionIdentifier>123124</TransactionIdentifier>";
    xmlToSend += "        <TransactionType>93</TransactionType>";
    xmlToSend += "        <TransactionSerialNumber>2345678901234561</TransactionSerialNumber>";
    xmlToSend += "     </BankingTransaction>";
    xmlToSend += "     <Event>";
    xmlToSend += "        <Identification>00</Identification>";
    xmlToSend += "        <Description>APROBADO</Description>";
    xmlToSend += "     </Event>";
    xmlToSend += "     <PaymentCard>";
    xmlToSend += "        <Number>5367620000000001</Number>";
    xmlToSend += "        <GenerateIndicatorBIN>5A03DE10A3802DB9</GenerateIndicatorBIN>";
    xmlToSend += "     </PaymentCard>";
    xmlToSend += "     <AdditionalInformationList>";
    xmlToSend += "        <AdditionalInformation>";
    xmlToSend += "           <Key>track2</Key>";
    xmlToSend += "           <Value>X</Value>";
    xmlToSend += "        </AdditionalInformation>";
    xmlToSend += "        <AdditionalInformation>";
    xmlToSend += "           <Key>ksn</Key>";
    xmlToSend += "           <Value>DDDD9876542222E0026F</Value>";
    xmlToSend += "        </AdditionalInformation>";
    xmlToSend += "        <AdditionalInformation>";
    xmlToSend += "           <Key>EmvTags</Key>";
    xmlToSend += "           <Value>XX</Value>";
    xmlToSend += "        </AdditionalInformation>";
    xmlToSend += "        <AdditionalInformation>";
    xmlToSend += "           <Key>GenerationMode</Key>";
    xmlToSend += "           <Value>XX</Value>";
    xmlToSend += "        </AdditionalInformation>";
    xmlToSend += "     </AdditionalInformationList>";
    xmlToSend += "     <BankTransaction>";
    xmlToSend += "        <BankOperation>";
    xmlToSend += "           <OperationTypeCode>35212</OperationTypeCode>";
    xmlToSend += "        </BankOperation>";
    xmlToSend += "     </BankTransaction>";
    xmlToSend += "     <Status>";
    xmlToSend += "       <StatusReason>";
    xmlToSend += "           <ReasonCode>00</ReasonCode>";
    xmlToSend += "        </StatusReason>";
    xmlToSend += "     </Status>";
    xmlToSend += "  </v11:CreateITSystemOperatingSessionTicket>";
    xmlToSend += "</SOAP-ENV:Body>";
    xmlToSend += "</SOAP-ENV:Envelope>";


    return xmlToSend;
  }
}


