import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from '@angular/router';
import { Cliente } from '../../../models/simular';
import { Client } from '../../../models/client';
import { AppConstants, MESSAGE_FLOW_VALIDATION } from '../../constants/app.constants';
import { AlertService } from '../../services/alert.service';
import { ClientService } from '../../../services/client.service';
import { HttpErrorResponse } from '@angular/common/http';
import { EmitterService } from '../../../services/emitter.service';

declare const hideMessage: any;
declare const showMessage: any;

@Component({
  selector: 'app-mensaje-final',
  templateUrl: './mensaje-final.component.html'
})

export class MensajeFinalComponent implements OnInit {

  client: Client = new Client();
  scliente: Cliente = JSON.parse(sessionStorage.getItem(AppConstants.ObjectCliente.CLIENTE));
  loading: boolean = false;

  messageCondition: string = '';
  stateSolicitud: number = 0;

  agencyCode: string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE);
  agencyName: string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME);
  usersessionId: string = sessionStorage.getItem(AppConstants.Session.USERID);
  channelName: string = sessionStorage.getItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION);

  isCambProd:boolean = false;
  modoRptaCP:string = '0';

  constructor(
    private router: Router,
    private alertaService: AlertService,
    private clientService: ClientService,
    private activatedRoute: ActivatedRoute,
    private emitterService: EmitterService
  ) { }

  ngOnInit() {
    this.callGetClient(this.scliente.NumeroSolicitud);
    this.emitterService.sendMessage("/validacion/succes");
  }


  callGetClient(code: string) {
    this.loading = true;
    this.clientService.getClient(code).subscribe((response) => {
      this.client = response;
      const products = [AppConstants.Products.CAPTACION, AppConstants.Products.IL];
      let name = products.includes(this.client.offerProduct) ? '' : 'de préstamo ';
      name = this.client.offerProduct == AppConstants.Products.CAMBPROD ? 'de Cambio de Producto ' : name;

      this.messageCondition = this.client.solState == AppConstants.SolicitudState.FINALIZADO ? `¡Tu solicitud ${name}ha sido aprobada de manera exitosa!` :
        this.client.solState == AppConstants.SolicitudState.PEND_AUTORIZACION ? `¡Tu solicitud ${name}se envío al supervisor para su futura autorización!` :
          this.client.solState == AppConstants.SolicitudState.PEND_APROBACION ? `¡Tu solicitud ${name}se envió al área de créditos para su futura aprobación!` :
          this.client.solState == AppConstants.SolicitudState.PEND_VALIDACION_PIN_PAD ? MESSAGE_FLOW_VALIDATION.CASHIER : '' ;

      if (this.client.offerProduct == AppConstants.Products.CAMBPROD){
        name = '';
        this.modoRptaCP = '0';
        this.isCambProd = true;
        this.messageCondition = this.client.solState == AppConstants.SolicitudState.FINALIZADO ? `¡Tu solicitud ${name}ha sido realizado de manera exitosa!` :
        this.client.solState == AppConstants.SolicitudState.PEND_AUTORIZACION ? `¡Tu solicitud ${name}se envió al supervisor para su aprobación!` : '';
        if (this.client.solState == AppConstants.SolicitudState.FINALIZADO) this.modoRptaCP = '2';
        if (this.client.solState == AppConstants.SolicitudState.PEND_AUTORIZACION) this.modoRptaCP = '1';
      }

      this.loading = false;
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  return() {
    this.router.navigateByUrl("productsList");
  }

  showMessage() {
    showMessage();
  }

  hideMessage() {
    hideMessage();
  }

}
