import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { of } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AppConstants, TipoSolicitudBO } from '../../constants/app.constants';
import { AuthenticationInitiation, AuthenticationUpdation, ResponseBiomatchWS, VerificationDactilar } from '../../../models/verification';
import { Parameter, VariableControl } from '../../../models/parameter';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { VerificationService } from '../../../services/verification.service';
import { ParameterService } from '../../../services/parameter.service';
import { Platform } from '@ionic/angular';
import { SolicitudBoSession } from '../../../models/solicitud-bo';
import { Cliente } from '../../../models/simular';
import { SolicitudPut } from '../../../models/solicitud';
import { SolicitudService as SolicitudServiceBO } from '../../../services/ms-backoffice/solicitud.service'
import { SolicitudService } from '../../../services/solicitud.service';

declare function autenticacionBiometrica(coProceso: string, ipConsulta: string, nuDocUsuario: string, nuDocCliente: string, tiDocUsuario: string, tiDocCliente: string): any;

declare function demoAsync(coProceso: string, ipConsulta: string, nuDocUsuario: string, nuDocCliente: string, tiDocUsuario: string, tiDocCliente: string): any;

declare let cordova: any;

@Component({
  selector: 'onb-validacion-dactilar',
  templateUrl: './onb-validacion-dactilar.component.html',
  styleUrls: ['./onb-validacion-dactilar.component.scss'],
})
export class OnbValidacionDactilarComponent implements OnInit {

  typeBiometrico: boolean = false;
  userRequestReniec: Parameter[] = [];
  messageResponseBiomatchGateway: Parameter[] = [];
  messageResponseBiomatchReniec: Parameter[] = [];
  responseBiomatch = new ResponseBiomatchWS();
  textoLegalData: Parameter[] = [];
  messageLegal: string = '';

  loading: boolean = false;
  @Input() solicitud: SolicitudBoSession;
  @Output() return = new EventEmitter();
  @Output() success = new EventEmitter();
  @Output() supervisor = new EventEmitter();

  statePenAut: number = AppConstants.SolicitudState.PEND_AUTORIZACION;
  conditionValidateStateSolicitud: boolean = false;
  scliente: Cliente = JSON.parse(sessionStorage.getItem(AppConstants.ObjectCliente.CLIENTE));
  rolId: string = sessionStorage.getItem(AppConstants.Session.USERROLID);

  constructor(
    private alertaService: AlertService,
    private router: Router,
    private verificationService: VerificationService,
    private parameterService: ParameterService,
    private platform: Platform,
    private solicitudService: SolicitudService,
    private solicitudServiceBO: SolicitudServiceBO,

  ) { }

  async ngOnInit() {
    await this.GetParameters([
      AppConstants.ParameterCode.USER_REQUEST_BIOMATCH,
      AppConstants.ParameterCode.BIOMATCH_RESPONSE_GATEWAY,
      AppConstants.ParameterCode.BIOMATCH_RESPONSE_RENIEC,
      AppConstants.ParameterCode.TEXT_LEGAL_BIOMETRICO
    ]);

    if(this.solicitud.tipoSolicitud == TipoSolicitudBO.IncrementoLinea ||
       this.solicitud.tipoSolicitud == TipoSolicitudBO.CambioProducto
    ){
      this.sendVerification(AppConstants.SolicitudState.PROC_AUTENTIFICACION);
    }
  }

  onStartx() {
    this.loading = true;
    this.validacionDactilar().subscribe(response =>{
      if(response){
        this.success.emit();
        this.loading = false;
      }
    })
  }

  onReturn(){
    this.return.emit();
  }

  validacionDactilar(){
    return of(true).pipe(
      delay(3000) // Retrasa la emisión por 3 segundos
    );
  }

  async GetParameters(array: Array<number>) {
    let modelRequest = { headerId: array };
    this.loading = true;
    await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {
        this.setVariablesParameters(response);
        this.loading = false;
    },
        (error: HttpErrorResponse) => {
            this.loading = false;
        });
  }

  getCodProd(tipoSolicitud: number){
    let codProd = "000";
    if(tipoSolicitud == TipoSolicitudBO.IncrementoLinea)
      codProd = AppConstants.Products.IL
    if(tipoSolicitud == TipoSolicitudBO.CambioProducto)
      codProd = AppConstants.Products.CAMBPROD;
    if(tipoSolicitud == TipoSolicitudBO.BloqueoDesbloqueoRSAT)
      codProd = AppConstants.Products.BLOQUEOYDESBLOQUEOTC;
    if(tipoSolicitud == TipoSolicitudBO.NotificacionesTCTD)
      codProd = AppConstants.Products.NOTIFICACIONES;
    return codProd;
  }

  setVariablesParameters(listParameter: Parameter[]) {
      this.userRequestReniec = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.USER_REQUEST_BIOMATCH && f.tabDetState == AppConstants.StateFlag.Activo);
      this.messageResponseBiomatchGateway = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.BIOMATCH_RESPONSE_GATEWAY && f.tabDetState == AppConstants.StateFlag.Activo);
      this.messageResponseBiomatchReniec = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.BIOMATCH_RESPONSE_RENIEC && f.tabDetState == AppConstants.StateFlag.Activo);

      this.textoLegalData = listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.TEXT_LEGAL_BIOMETRICO && f.tabDetState == AppConstants.StateFlag.Activo);
      const textoLegal = this.textoLegalData.find(f=>f.tabDetCode001 == this.getCodProd(this.solicitud.tipoSolicitud) && f.tabDetCode003 == this.solicitud.tipoSolicitud);
      if(textoLegal){
        const message = (textoLegal.tabDetCode006 || '') + (textoLegal.tabDetCode007 || '') + (textoLegal.tabDetCode008 || '') + (textoLegal.tabDetCode009 || '');
        this.messageLegal = this.prepararDatos(message);
      }
  }

  prepararDatos(datos) {
    let result = datos.split('-SALTO').join('<br />');
    return result;
  }

  async onStart() {
    var documentTypeConsultReniec: string = this.userRequestReniec[0] == null ? '' : this.userRequestReniec[0].tabDetCode001;
    var documentNumberConsultReniec: string = this.userRequestReniec[0] == null ? '' : this.userRequestReniec[0].tabDetCode002;

    var model = new AuthenticationInitiation();
    model.solId = this.solicitud.solicitudBOId;
    model.authenticationMethod = AppConstants.AuthenticationMode.Biometrico;

    let validation = await this.registerAuditValidation(model);

    if (validation) {
        try {
            if (!this.platform.is('cordova')) {
                var tipoDoc1 = this.solicitud.tipoDocumento == 'C' ? '1' : this.solicitud.tipoDocumento;
                var tipoDoc2 = documentTypeConsultReniec == 'C' ? '1' : documentTypeConsultReniec;
                this.responseBiomatch = demoAsync(this.solicitud.solicitudBOId, '127.0.0.1', documentNumberConsultReniec, this.solicitud.numeroDocumento, tipoDoc1, tipoDoc2);
                this.validateReponseBiometrico(this.responseBiomatch);
            } else {
                this.captura(documentTypeConsultReniec, documentNumberConsultReniec, this.solicitud.solicitudBOId, this.solicitud.tipoDocumento, this.solicitud.numeroDocumento);
            }
        } catch (error) {
            console.log(error);
            this.alertaService.showMessage(AppConstants.TitleModal.BIOMETRICO_TITLE, AppConstants.MessageModal.ERROR_BIOMATCH, MessageSeverity.error);
        }
    }
    else {
        this.alertaService.showMessage(AppConstants.TitleModal.REGISTER_AUTHENTICATION, AppConstants.MessageModal.ERROR_CREATE_AUTHENTICATION, MessageSeverity.error);
    }
  }

  async registerAuditValidation(model: AuthenticationInitiation) {
    let status = false;
    this.loading = true;
    await this.verificationService.createAuthentication(model).toPromise().then((response) => {
        this.loading = false;
        status = response;
    },
        (error: HttpErrorResponse) => {
            this.loading = false;
            console.log(error.error.messages);
            return false;
        });

    return status;
  }

  validateReponseBiometrico(resulBiomatch: any) {
    console.log(resulBiomatch);
    var modelUpdate = new AuthenticationUpdation();
    modelUpdate.solId = this.solicitud.solicitudBOId;
    modelUpdate.authenticationMethod = AppConstants.AuthenticationMode.Biometrico;
    modelUpdate.reponseCode = 0;
    modelUpdate.transactionState = 0;

    if (resulBiomatch.coMensaje === '8000') {
        if (resulBiomatch.codResul === '70006') {
            modelUpdate.reponseCode = 1;
            modelUpdate.transactionState = 1;

            var messagePersonalizedSucces = this.messageResponseBiomatchReniec.find(f => f.tabDetCode001 === resulBiomatch.codResul) == null ? resulBiomatch.deResult : this.messageResponseBiomatchReniec.find(f => f.tabDetCode001 === resulBiomatch.codResul).tabDetCode007;

            // this.registerAuditoriaGeneralSimulacion(true, resulBiomatch.coMensaje, messagePersonalizedSucces);

            this.alertaService.showMessage(AppConstants.TitleModal.DACTILAR_VALIDATE, messagePersonalizedSucces, MessageSeverity.success);
            var model = new VerificationDactilar();
            model.solId = this.solicitud.solicitudBOId;
            model.indQA = resulBiomatch.indQA;
            model.numTxn = resulBiomatch.numTxn;
            model.validationId = +resulBiomatch.codResul;
            model.validationDes = messagePersonalizedSucces;

            ///#region - Validacion dactilar
            this.loading = true;
            this.verificationService.validateDactilar(model).subscribe((response) => {
                this.loading = false;

                //antes: this.return.emit(); 
                this.success.emit();
            },
                (error: HttpErrorResponse) => {
                    // this.contadotReintento = this.contadotReintento + 1;
                    // this.validateShow();
                    this.loading = false;
                    this.alertaService.showMessage(AppConstants.TitleModal.BIOMETRICO_TITLE, error.error.messages, MessageSeverity.error);
                });
            ///#endregion

        } else {
            var messagePersonalizedSuccesFailed = this.messageResponseBiomatchReniec.find(f => f.tabDetCode001 === resulBiomatch.codResul) == null ? resulBiomatch.deResult : this.messageResponseBiomatchReniec.find(f => f.tabDetCode001 === resulBiomatch.codResul).tabDetCode007;
            // this.registerAuditoriaGeneralSimulacion(false, resulBiomatch.coMensaje, messagePersonalizedSuccesFailed);
            this.alertaService.showMessage(AppConstants.TitleModal.DACTILAR_VALIDATE, messagePersonalizedSuccesFailed, MessageSeverity.error);

            // if (resulBiomatch.codResul === '70007') {
            //     this.sendMonitor(MONITOR_ANSWER_CODE.BIOMETRIC_VALIDATION_INCORRECT, MONITOR_AUTENTICATION_TYPE.BIOMETRIC, this.client.solId);
            // }
        }

    } else {
        var messagePersonalizedFailed = this.messageResponseBiomatchGateway.find(f => f.tabDetCode001 === resulBiomatch.coMensaje) == null ? resulBiomatch.deMensaje : this.messageResponseBiomatchGateway.find(f => f.tabDetCode001 === resulBiomatch.coMensaje).tabDetCode007;
        //this.registerAuditoriaGeneralSimulacion(false, resulBiomatch.coMensaje, messagePersonalizedFailed);
        this.alertaService.showMessage(AppConstants.TitleModal.DACTILAR_VALIDATE, messagePersonalizedFailed, MessageSeverity.error);
    }

    //modelUpdate.reponseMessage = messagePersonalizedSucces == null || messagePersonalizedSucces == '' || messagePersonalizedSucces === undefined ? resulBiomatch.deMensaje : messagePersonalizedSucces;
    //modelUpdate.transactionId = resulBiomatch.numTxn == null || resulBiomatch.numTxn == '' || resulBiomatch.numTxn === undefined ? null : resulBiomatch.numTxn;
    //modelUpdate.transactionDescription = resulBiomatch.codResul == null || resulBiomatch.codResul == '' || resulBiomatch.codResul === undefined ? resulBiomatch.coMensaje : resulBiomatch.codResul;

    //this.updateAuditValidation(modelUpdate);
  }

  captura(tipoDocumentoUsu, numDocumentoUsu, coProceso, tipoDocumentoCli, numDocumentoCli) {
    let args: Array<string>;
    args = [coProceso, tipoDocumentoUsu, numDocumentoUsu, tipoDocumentoCli, numDocumentoCli];
    let servicioResponse;
    let self = this;

    cordova.plugins.huelleroplugin.captura(
        function (result) {
            if (result.coError === '8000') {
                // if (result.codResul === '70006') {
                servicioResponse = {
                    'coMensaje': result.coError,
                    'deMensaje': result.desError,
                    'numDoc': result.nuDNI,
                    'codResul': result.coResultado,
                    'deResult': result.deResultado,
                    'indQA': result.inCalidad,
                    'numTxn': result.idTxn
                };
            } else {
                servicioResponse = {
                    'coMensaje': result.coError,
                    'deMensaje': result.desError
                };
            }
            // } else {
            //     servicioResponse = {
            //         'coMensaje': result.coError,
            //         'deMensaje': result.desError
            //     };
            // }
            self.validateReponseBiometrico(servicioResponse);

        }, function (error) {
            servicioResponse = {
                'coMensaje': error.coError,
                'deMensaje': error.desError
            };
            self.validateReponseBiometrico(servicioResponse);

        },
        {
            'preferFrontCamera': false, // iOS and Android
            'showFlipCameraButton': true, // iOS and Android
        },
        args);
  }

  /*showSendSupervisor(){
    return (this.solicitud.tipoSolicitud == TipoSolicitudBO.CambioProducto);
  }*/

    async validateStateSolicitud(SolicitudId: string) {
      this.loading = true;
    
      try {
        if (
          this.solicitud.tipoSolicitud === TipoSolicitudBO.IncrementoLinea ||
          this.solicitud.tipoSolicitud === TipoSolicitudBO.CambioProducto
        ) {
          const response = await this.solicitudService
            .getValidateStateSolicitud(SolicitudId)
            .toPromise();
          this.conditionValidateStateSolicitud = response.active;
        } else {
          const response = await this.solicitudServiceBO
            .getValidateStateSolicitud(SolicitudId)
            .toPromise();
          this.conditionValidateStateSolicitud = response.active;
        }
      } catch (error) {
        this.alertaService.showMessage(
          AppConstants.TitleModal.SOLICITUD_VALIDATE,
          error.error.messages || 'An error occurred',
          MessageSeverity.error
        );
        this.conditionValidateStateSolicitud = false;
      } finally {
        this.loading = false;
      }
    }
    
    

  sendVerification(state) {
    var model = new SolicitudPut();
    model.solId = this.solicitud.solicitudBOId;
    model.channel = !this.platform.is('cordova') ? AppConstants.Channel.WEB : AppConstants.Channel.MOVIL;
    model.processId = AppConstants.Process.ONBOARDING;
    model.solState = state;
    
    if (this.solicitud.tipoSolicitud == TipoSolicitudBO.IncrementoLinea ||
      this.solicitud.tipoSolicitud == TipoSolicitudBO.CambioProducto) {
        model.userId = this.scliente.userCreation;
        model.worFlowId = this.scliente.WFInstance;
        model.taskId = AppConstants.WFTareas.APPROVED;
        this.UpdateStateSolicitud(model);
    } else {
      this.UpdateStateSolicitudBO(model);
    }


  }

  async callSupervisor(state) {
    await this.validateStateSolicitud(this.solicitud.solicitudBOId);
    if (this.conditionValidateStateSolicitud) {
        this.typeBiometrico = false;
        this.sendVerification(state);
    }
    else {
        this.alertaService.showMessage(AppConstants.TitleModal.SOLICITUD_VALIDATE, AppConstants.MessageModal.SOLICITUD_ANULADA, MessageSeverity.warn);
    }
  }

  UpdateStateSolicitud(mode: SolicitudPut) {
    console.log('UpdateStateSolicitud | model :', mode);
    console.log('typeBiometrico:', this.typeBiometrico);
    console.log('RolId:', this.rolId);
    this.loading = true;
    this.solicitudService.updateStateSolicitud(mode).subscribe((response) => {
        this.loading = false;
        if(mode.solState!=3) {
          this.router.navigateByUrl('validacion/succes');
          //this.supervisor.emit();
        }
     },
        (error: HttpErrorResponse) => {
            this.loading = false;
            this.alertaService.showMessage(AppConstants.TitleModal.VALIDACION_DACTILAR, error.error.messages, MessageSeverity.error);
        }
    );
  }

  UpdateStateSolicitudBO(mode: SolicitudPut) {
    console.log('UpdateStateSolicitud | model :', mode);
    console.log('typeBiometrico:', this.typeBiometrico);
    console.log('RolId:', this.rolId);
    this.loading = true;
    this.solicitudServiceBO.updateStateSolicitud(mode).subscribe((response) => {
        this.loading = false;
        if(mode.solState!=3) {
          //this.router.navigateByUrl('validacion/succes');
          this.supervisor.emit();
        }
     },
        (error: HttpErrorResponse) => {
            this.loading = false;
            this.alertaService.showMessage(AppConstants.TitleModal.VALIDACION_DACTILAR, error.error.messages, MessageSeverity.error);
        }
    );
  }

}
