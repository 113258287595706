import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Client } from '../../../../models/client';
import { ClientService } from '../../../../services/client.service';
import { ParameterService } from '../../../../services/parameter.service';
import { AppConstants } from '../../../constants/app.constants';
import * as FileSaver from 'file-saver';
import { StorageService } from '../../../services/storage.service';
import { AlertService, MessageSeverity } from '../../../services/alert.service';

@Component({
    selector: 'app-attachment-detail-modal',
    templateUrl: './attachment-detail-modal.component.html',
    styleUrls: ['./attachment-detail-modal.component.css'],
})
export class AttachmentDetailModalComponent implements OnInit {

    messageAdjunto: string = '';
    comentarioForm: string = '';
    archivosList = [];
    edit = false;

    imgAttachmentCircle: string = './assets/images/icon-attachment-circle.png';

    data: any;
    loading: boolean = false;
    client: Client = new Client();

    constructor(
        private modalAttachmentDetailRef: BsModalRef,
        private clientService: ClientService,
        private parameterService: ParameterService,
        private platform: Platform,
        private storageService: StorageService,
        private alertaService: AlertService
    ) { }

    ngOnInit() {        
        this.getAdjuntos(this.data.numeroSolicitud, AppConstants.AdjuntoModo.Cliente);
    }

    close() {
        this.modalAttachmentDetailRef.hide();
    }

    downloadArchivo(index) {
        this.downloadFile(this.archivosList[index]["key"], this.archivosList[index]["name"]);
    }

    deleteArchivos() {

    }

    adjuntarArchivos() {

    }

    callGetClient(code: string) {
        this.loading = true;
        this.clientService.getClient(code).subscribe((response) => {
            this.client = response;
            this.getAdjuntos(this.client.solId, AppConstants.AdjuntoModo.Cliente);
            this.loading = false;
        },
            (error: HttpErrorResponse) => {
                this.loading = false;
            });
    }


    getAdjuntos(code: string, contidion: number) {
        this.loading = true;
        this.clientService.getFilesClient(code, contidion).subscribe((response) => {
          this.comentarioForm = response.observacion;
          for (let k in response.files) {
            var archivo = { name: response.files[k]["name"], key: response.files[k]["rutaId"] };
            this.archivosList.push(archivo);
          }
          this.parameterService.getParameters(AppConstants.ParameterCode.MESSAGE_MODAL).subscribe((response) => {
            if(response.filter(f => f.tabDetCode001 === this.data.codigoProducto).length > 0) {
                this.messageAdjunto = response.find(f => f.tabDetCode001 === this.data.codigoProducto).tabDetCode002;
            }
            this.loading = false;
          },
            (error: HttpErrorResponse) => {
              this.loading = false;
            });
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    downloadFile(fileId: string, nameFile: string): void {
        this.loading = true;

        this.clientService.downloadFile(fileId).subscribe(
          (response) => {

            const data: Blob = new Blob([response], {
              type: response.type
            });

            let extension = fileId.split('.');
            if (!this.platform.is('cordova')) {
              FileSaver.saveAs(data, nameFile);
            } else {
              this.storageService.WriteFileMovil(nameFile, data);
            }

          },
          (error) => {
            this.alertaService.showMessage(AppConstants.TitleModal.DOWNLOAD_FILE,
              error.messages[0], MessageSeverity.error);
          }
        ).add(() => {
          this.loading = false;
        });
      }
}
