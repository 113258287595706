export class ModeDesembolso {
  solId: string;
  worFlowId: string;
  modCtaExi: number;
  modCtaNue: number;
  modEfec: number;
  modNroCtaExi: string;
  modTransInterbancaria: number;
  modNroCtaInterbancaria: string;
  modTransInterbancariaBanco: string;
  modTransInterbancariaComision: number;
  modCtaInterna: number;
  processId: number;
  channel: number;
  taskId: string;
  transferOperationTypeCode: string;
  transferBankOperationPartyType: string;
  transferBankTaxOnPaymentRate: string;
  modTransInterbancariaTradeComision: number;
}
