import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { ClientService } from "../../../services/ms-backoffice/client.service";
import { ClientData, ClientReniec } from "../../../models/client";
import { Orientation } from "../../constants/app.constants";

@Component({
  selector: "onb-client-reniec",
  templateUrl: "./onb-client-reniec.component.html",
  styleUrls: ["./onb-client-reniec.component.css"],
})
export class OnbClientReniecComponent implements OnInit, OnChanges {
  @Input() docType: string;
  @Input() docNumber: string;
  @Input() data: ClientData;
  @Input() showButtons: boolean = true;
  @Input() orientation: string = Orientation.Horizontal;
  @Output() return = new EventEmitter();
  @Output() next = new EventEmitter();

  loading: boolean;
  client: ClientData = new ClientData();
  foto = "/assets/images/default-foto.png";

  constructor(private clientService: ClientService) {}

  ngOnInit(): void {
    this.callGetClientData(this.docType, this.docNumber, this.data);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["docType"] || changes["docNumber"]) {
      this.callGetClientData(this.docType, this.docNumber, this.data);
    }
  }

  onReturn() {
    this.return.emit();
  }
  onNext() {
    this.next.emit();
  }

  callGetClientData(typeDoc: string, numDoc: string, data: ClientData = null) {
    if(data){
      this.client = this.data;
      this.foto =
              this.client.reniecImage == null || this.client.reniecImage == ""
                ? "/assets/images/default-foto.png"
                : "data:image/png;base64," + this.client.reniecImage;
    } else{
      this.loading = true;
      if (this.docType && this.docNumber) {
        this.clientService.getClientData(typeDoc, numDoc).subscribe(
          (response) => {
            this.client = response;
            this.foto =
              this.client.reniecImage == null || this.client.reniecImage == ""
                ? "/assets/images/default-foto.png"
                : "data:image/png;base64," + this.client.reniecImage;
            this.loading = false;
          },
          (error: HttpErrorResponse) => {
            this.loading = false;
            /*-----------------------------------------------------------
              Return el flag de return, para poder validar que se termino 
              con error la carga del servicio y asi poder tener accion a
              consecuencia de la falla de la llamada.
              -----------------------------------------------------------*/
            this.return.emit();
          }
        );
      }
    }
  }
}
