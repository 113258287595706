import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Client } from '../../../../models/client';
import { Parameter } from '../../../../models/parameter';
import { ClientService } from '../../../../services/client.service';
import { ParameterService } from '../../../../services/parameter.service';
import { AppConstants } from '../../../constants/app.constants';

@Component({
    selector: 'app-reference-detail-modal',
    templateUrl: './reference-detail-modal.component.html',
    styleUrls: ['./reference-detail-modal.component.css'],
})
export class ReferenceDetailModalComponent implements OnInit {
    referenceForm: FormGroup;

    imgUserCircle: string = './assets/images/icon-user-circle.png';

    loading: boolean = false;
    data: any;
    client: Client = new Client();

    isCappta : boolean = false;
    messageCodigoVendedorCappta: string = '';
    titleCodigoVendedorCappta: string = '';

    constructor(
        private formBuilder: FormBuilder,
        private modalReferenceDetailRef: BsModalRef,
        private clientService: ClientService,
        private parameterService: ParameterService,
    ) { }

    async ngOnInit() {
        this.isCappta = this.data.codigoProducto == AppConstants.Products.CAPTACION ? true : false;
        this.callGetClient(this.data.numeroSolicitud);
        this.createForm();
        await this.GetParameters([
            AppConstants.ParameterCode.ADICIONAL_PARAMETERS
        ]);
    }

    createForm() {
        this.referenceForm = this.formBuilder.group({
            referenceNombres: ['', Validators.compose([
                Validators.required,
                , this.ValidatorNoWhitespace
            ])],
            referenceApellidoPaterno: ['', Validators.compose([
                Validators.required,
                , this.ValidatorNoWhitespace
            ])],
            referenceTelephoneCode: '',
            referenceTelephone: '',
            referencePhone: ['', Validators.compose([
                Validators.required,
                Validators.pattern('^9[0-9]{8}$')
            ])],
            auxReferido: ''
        })
    }

    close() {
        this.modalReferenceDetailRef.hide();
    }

    ValidatorNoWhitespace(control: FormControl) {
        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    callGetClient(code: string) {
        this.loading = true;
        this.clientService.getClient(code).subscribe((response) => {
          this.client = response;
          this.setValues();
          this.loading = false;
        },
          (error: HttpErrorResponse) => {
            this.loading = false;
          });
    }

    setValues() {
        this.referenceForm.patchValue({
          referenceNombres: this.client.referenciaNombres,
          referenceApellidoPaterno: this.client.referenciaApellido,
          referenceTelephoneCode: this.client.referenciaTelephoneCode,
          referenceTelephone: this.client.referenciaTelephone,
          referencePhone: this.client.referenciaPhone,
          auxReferido: this.client.solAssistantReferred
        });
    }

    async GetParameters(array: Array<number>) {
        let modelRequest = {headerId: array};
        this.loading = true;
        await this.parameterService.getParametersList(modelRequest).toPromise().then((response) => {                
                this.setVariablesParameters(response);
                this.loading = false;
            },
            (error: HttpErrorResponse) => {
                this.loading = false;
            });
    }

    setVariablesParameters(listParameter: Parameter[]) {
        
        this.messageCodigoVendedorCappta =  listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS && f.tabDetCode001 == "06" && f.tabDetState == AppConstants.StateFlag.Activo).length > 0 ? 
                                            listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS && f.tabDetCode001 == "06" && f.tabDetState == AppConstants.StateFlag.Activo)[0]["tabDetCode007"] : '';
        this.titleCodigoVendedorCappta =  listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS && f.tabDetCode001 == "06" && f.tabDetState == AppConstants.StateFlag.Activo).length > 0 ? 
                                          listParameter.filter(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS && f.tabDetCode001 == "06" && f.tabDetState == AppConstants.StateFlag.Activo)[0]["tabDetCode008"] : '';
    }
    
}
