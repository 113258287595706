import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, from } from 'rxjs';
import { AppConstants } from '../shared/constants/app.constants';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let token = sessionStorage.getItem(AppConstants.Session.ACCESS_TOKEN);

    if (request.url == environment.identityApiEndPointToken && token != "" && token != null && token != "undefined") {
      return next.handle(request);
    }

    return from(this.handleAccess(request, next));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    let token = sessionStorage.getItem(AppConstants.Session.ACCESS_TOKEN);
    let tokenRipley = sessionStorage.getItem(AppConstants.Session.ACCESS_TOKEN_RIPLEY);
    if (token != "" && token != null && token != "undefined") {
      let tokenWeb = 'Bearer ' + token;
      let tokenIntegration = 'Bearer ' + tokenRipley;

      if (request.url.toLowerCase().includes('gw.api.dev.bancoripley.com.pe/ripley-banco-dev')) {
        request = request.clone({
          setHeaders: {
            'Authorization': tokenIntegration,
            'Content-Type': 'application/json; charset=utf-8',
            //'Accept': 'application/json'
          }
        });
      }
      else {
        request = request.clone({
          setHeaders: {
            'Authorization': tokenWeb,
            'Content-Type': 'application/json; charset=utf-8',
            //'Accept': 'application/json'
          }
        });
      }
    }

    return next.handle(request).toPromise();
  }
}
