import { ResponseGetClientT24 } from "./client";
import { CreditAccount } from "./credit-card";

export class SolicitudBo {
  solicitudBOId: string;
  tipoSolicitud: number;
  estado: number;
  tipoDocumento: string;
  numeroDocumento: string;
  correoCliente: string;
  direccionCliente:string;
  correoLabCliente: string;
  direccionLabCliente:string;
  nombreCliente: string;
  usuarioId: string;
  usuarioRol: string;
  codigoAgencia: string;
}

export class SolicitudServAsocTC {
  tipoCliente: string;
  compraInternet: boolean;
  compraExtranjero: boolean;
  dispEfectivo: boolean;
  nroTarjEncryp: string;
  tipoTarjeta: string;
  tipoDoc: string;
  tipoDoc24: string;
  nroDoc: string;
  nombre: string;
  flgItemCompraInt: boolean;
  flgItemCompraExt: boolean;
  flgItemDispEfect: boolean;
  flgItemSobregiro: boolean;
  cliente: ResponseGetClientT24;
  registros: CreditAccount[];
}
export class solBloqueDesblTC {
  tipoCliente: string;
  nroTarjEncryp: string;
  tipoTarjeta: string;
  tipoDoc: string;
  nroDoc: string;
  nombre: string;
  cliente: ResponseGetClientT24;
  accountnumber:string;
  codigoBloqueo:string;
  FechaBloqueo:string;
  motivoBloqueo:string;
  nombreTarjeta: string;
  productDesc:string;
  status:string;
  tarjetaEncriptada:string;
  tipoProducto:string;
}

export class SolicitudBoSession {
  solicitudBOId: string;
  tipoTarjeta?: string;
  tipoSolicitud: number;
  tipoDocumento: string;
  tipoDocumentoName: string;
  numeroDocumento: string;
  nombreCliente: string;
  correoCliente: string;
  direccionCliente?:string;
  correoLabCliente?: string;
  direccionLabCliente?:string;
  montoIncremento?: number;
  nombreProducto?: string;
  estadoTarjeta?: string;
  apellidoPaterno?: string;
  apellidoMaterno?: string;  
  nombres?: string;
  numeroTarjeta?: string;
  constructor(){
    this.solicitudBOId = "";
    this.tipoSolicitud = 0;
    this.tipoDocumento = "";
    this.tipoDocumentoName = "";
    this.numeroDocumento ="";
    this.nombreCliente = "";
    this.correoCliente = "";
    this.direccionCliente = "";
    this.correoLabCliente = "";
    this.direccionLabCliente = "";
    this.montoIncremento = 0;
    this.nombreProducto = "";
    this.estadoTarjeta = "";
    this.numeroTarjeta = "";
  }
}

export class SolicitudBODebito extends SolicitudBo {
  numeroTarjeta : string;
  panEncrypt: string;
  numeroCuenta : string;
  numeroCuentaCCI : string;
  consumosInternet : number;
  consumosExtranjero : number;
  disposicionEfectivo : number;
  operacionTarjeta : number;
  motivoBloqueo : string;
  motivoCClave : number;
  fechaActivacion : string;
  fechaVencimiento : string;
  observacion : string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  nombres: string;
  cuentas: SolicitudBOCuenta[];
  usuarioDNI: string;
  estado: number;
}

export class SolicitudBOTCredito extends SolicitudBo {
  numeroTarjeta : string;
  numeroCuenta : string;
  consumosInternetF : number;
  consumosExtranjeroF : number;
  disposicionEfectivoF : number;
  sobregiroF : number;
  operacionTarjeta : number;
  motivoBloqueo : number;
  motivoCClave : number;
  fechaActivacion : string;
  fechaVencimiento : string;
  observacionSolicitud : string;
  solicitudBOId: string;
  tipoEnvio: number;
  tipoModalidad: number;
  motivoCancelacion?: string;
  tipoCli?: string;
  cuentas: SolicitudBOCuenta[]
  estadoProductoA:string;
  estadoProducto:string;
  motivobloqueoA:string;
  codigoBloqueoA:string;
  FechaBloqueoA:string;
  TipoProducto:string;
  motivoBloqueoF:string;
  codigoBloqueoF:string;
  FechaBloqueoF:string;  
}

export class SolicitudBOCuenta {
  prioridad: number;
  numeroCuenta: string;
  numeroCuentaCCI: string;
  productCode: string;
  productDesc: string;
  moneda: string;
}

export class SolicitudBOCredito extends SolicitudBo {
  tipoEnvio: number;
  tipoModalidad: number;
  numeroTarjeta: string;
  numeroCuenta: string;
  consumosInternetA : number;
  consumosExtranjeroA : number;
  disposicionEfectivoA : number;
  producto : string;
  tipoCli : string;
  estadoCliente: string;
  nomTarjeta : string;
  nroTarjetaEncrypt : string;
  nombres: string;
  apellidoPaterno: string;
  apellidoMaterno: string;
  usuarioDNI: string;
  observacionSolicitud: string;
  estadoProductoA: string;
  estadoProductoF: string;
  motivobloqueoA: string;
  codigoBloqueoA: string;
  fechaBloqueoA: string;
  tipoProducto: string;

  motivoCancelacion?: string;
  observacion?: string;
  saldoDisponible?: number;
}

export class ResponseProcessSolicitudBO {
  codigo: number;
  pending: boolean;
  finish: boolean;
  messages: Array<{message:string, status:string}> = [];
  maxAttempts: boolean;
}


export class SolicitudBOAsoCta extends SolicitudBo {
  consumosInternet: number;
  consumosExtranjero: number;
  disposicionEfectivo: number;
  operacionTarjeta: string;
  asociarCuentaDetalle: SolicitudBOAsoCtaDetalle[]
}

export class SolicitudBOAsoCtaDetalle {
  prioridad: number;
  numeroCuenta: string;
  productCode: string;
  productDesc: string
}

export class SolicitudBOContact extends SolicitudBo {
  tipoEnvio: string;
  modalidad: string;
  numeroCuenta: string;
}

export class SolicitudBOIncremetoL extends SolicitudBo {
  clienteId: number;
  ofertaId: string;
}

export class SolicitudHistoricoTD {
  listadoHistoricoTD: HistoricoTD[];
}

export class HistoricoTD{
  numeroTarjeta : string;
  numeroCuenta : string;
  solicitudBOId : string;
  fechaInicio : string;
  usuarioId : string;
  consumosInternet : string;
  consumosExtranjero : string;
  disposicionEfectivo : string;
}