import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {HomeComponent} from './components/home/home.component';
import {HeaderComponent} from './shared/components/header/header.component';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {LayoutComponent} from './shared/components/layout/layout.component';
import {LoginComponent} from './components/login/login.component';
import {FormatDateHourToLocal, FormatDateHourToLocalBO, FormatDateToLocal} from './shared/pipes/date.pipes';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ToastaModule} from 'ngx-toasta';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {AngularFontAwesomeModule} from 'angular-font-awesome';
import {SharedModule} from './shared/modules/shared.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module} from 'ng-recaptcha';
import {AuthInterceptor} from './interceptors/auth.interceptor';
import {ErrorInterceptor} from './interceptors/error.interceptor';
import {AuthGuard} from './guards/auth.guard';
import {AlertService} from './shared/services/alert.service';
import {AuthService} from './services/auth.service';
import {CurrencyPipe, DatePipe} from '@angular/common';
import {HttpService} from './shared/services/http.service';
import {environment} from '../environments/environment';
import {ServiceWorkerModule} from '@angular/service-worker';

import {FCM} from 'cordova-plugin-fcm-with-dependecy-updated/ionic/ngx';
import {AngularFireModule} from '@angular/fire';
import {AngularFireMessagingModule} from '@angular/fire/messaging';
import {AngularMultiSelectModule} from 'angular2-multiselect-dropdown';

import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {Camera} from '@ionic-native/camera/ngx';
import {CacheRouteReuseStrategy} from './shared/components/cache-route-reuse.strategy/cache-route-reuse.strategy';
import { Device } from '@ionic-native/device/ngx';


@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        HeaderComponent,
        NotFoundComponent,
        LayoutComponent,
        LoginComponent,
        FormatDateToLocal,
        FormatDateHourToLocal,
        FormatDateHourToLocalBO
    ],
    entryComponents: [],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        ToastaModule.forRoot(),
        ModalModule.forRoot(),
        CarouselModule.forRoot(),
        AngularFontAwesomeModule,
        SharedModule,
        HttpClientModule,
        RecaptchaV3Module,
        ServiceWorkerModule.register('combined-sw.js', {enabled: environment.production}),
        AngularFireModule.initializeApp(environment.FIREBASE_CONFIG),
        AngularFireMessagingModule,
        AngularMultiSelectModule
    ],
    providers: [
        FCM,
        StatusBar,
        SplashScreen,
        File,
        FileOpener,
        Camera,
        Device,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},

        HttpService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        AuthGuard,
        AlertService,
        HttpService,
        AuthService,
        FormatDateToLocal,
        FormatDateHourToLocal,
        FormatDateHourToLocalBO,
        DatePipe,
        CurrencyPipe,
        {provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.reCaptchaKey},
        {
            provide: RouteReuseStrategy,
            useClass: CacheRouteReuseStrategy
        }
    ],
    exports: [
        LayoutComponent,
        ToastaModule
    ],
    schemas: [NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule {
}
