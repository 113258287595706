import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { RequestTray} from '../../models/request-tray';
import { HelperService } from '../../shared/services/helper.service';

@Injectable({
  providedIn: 'root'
})
export class RequestTrayService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "RequestTray";
  private BASE_URL: string = HelperService.buildRequestURL(environment.msBackofficeApi);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  getStatus(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/GetStatus`);
  }

  getSolicitudes(requestTrayParameters: any) {
    return this.httpClient.post<RequestTray[]>(`${this.REQUEST_URL}/GetSolicitudes`, requestTrayParameters);
  }
  getSolicitudesBO(requestTrayParameters: any) {
    return this.httpClient.post<RequestTray[]>(`${this.REQUEST_URL}/GetSolicitudesBO`, requestTrayParameters);
  }

  getChannels(): Observable<any> {
    return this.httpClient.get<any>(`${this.REQUEST_URL}/GetChannels`);
  }

  getSolicitudesReport(requestTrayParameters: any) {
    return this.httpClient.post<RequestTray[]>(`${this.REQUEST_URL}/GetSolicitudesReport`, requestTrayParameters);
  }

  downloadFileReportSolicitud(request: any): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/GetSolicitudesReportDownload`,  request,
    {responseType: 'blob' as 'json' })
    .pipe(catchError(this.parseErrorBlob))
  }

  parseErrorBlob(err: HttpErrorResponse): Observable<any> {
    const reader: FileReader = new FileReader();

    const obs = Observable.create((observer: any) => {
        reader.onloadend = (e) => {
            observer.error(JSON.parse(reader.result.toString()));
            observer.complete();
        }
    });
    reader.readAsText(err.error);
    return obs;
  }

  downloadFileReportSolicitudPDF(request: any): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/GetSolicitudPDF`,  request,
    {responseType: 'blob' as 'json' })
    .pipe(catchError(this.parseErrorBlob))
  }

  downloadConstanciaAnulacionPDF(request: any): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/Efex/Constancia/PDF`,  request,
    {responseType: 'blob' as 'json' })
    .pipe(catchError(this.parseErrorBlob))
  }

  downloadConstanciaCancelacionPDF(request: any): Observable<Blob> {
    return this.httpClient.post<Blob>(`${this.REQUEST_URL}/CancelAccount/Constancia/PDF`,  request,
    {responseType: 'blob' as 'json' })
    .pipe(catchError(this.parseErrorBlob))
  }

}
