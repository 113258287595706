import { Injectable } from '@angular/core';
import { AppConstants, CAMBIOCLAVE_TYPES, PIN_PAD } from '../constants/app.constants';
import { KeyConfigurationResponse } from '../../models/onboarding-integration';
import { OnboardingIntegrationService } from '../../services/onboarding-integration.service';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PinpadConnectServiceTd {

  Configuration = new KeyConfigurationResponse();
  WSParametersMap = [];
  pinpadOperationType = "";
  URLLocal = "";
  pinPadtimeOut = 0;
  CrsProcessResponseObj: any;
  pinpadError = false;
  localResponseObjJSON: any;
  t24TxnId: string;
  message: string = "";
  OperationTypeText = "OperationType=";
  txnIdtext = "t24TransactionId=";
  txtServiceData = "t24ServiceKey=";
  logMessage: string;
  pinpadErrorMessage = "";
  responseObjJSON: any;

  logInfo = true;
  logError = true;
  logTrace = true;
  logDebug = true;
  usersessionId: string = sessionStorage.getItem(AppConstants.Session.USERID);

  validatePinArr:number[] = [1,2,3]
  changePinArr:number[] = []

  constructor(
    private onboardingIntegrationService: OnboardingIntegrationService) {
    this.WSParametersMap = this.getWsParameters(); 
  }

  async ConnectPINPAD(typeSol: string | number) {
    let result;
    let model = {
      UserId: this.usersessionId
    }

    //await this.onboardingIntegrationService.getKeyConfiguration(model).toPromise().then((data) => {
    //this.Configuration = data;
    //sessionStorage.setItem(AppConstants.Session.CONFIGURATION_BUS, JSON.stringify(this.Configuration));
    result = await this.Initialize(typeSol);    
    /*},
      (error: HttpErrorResponse) => {
        console.log(error);
      })*/

    return result;
  }

  async Initialize(typeSol: string | number) {
    let normalizedTypeSol = String(typeSol);
    let result = null;
    this.t24TxnId = PIN_PAD.TRANSACTION_ID; 
    console.log('pinpadservice-td',typeSol)
    if (CAMBIOCLAVE_TYPES.includes(normalizedTypeSol)) {
      this.pinpadOperationType = PIN_PAD.CHANGE_PIN;
    } else  {
        this.pinpadOperationType = PIN_PAD.VALIDATE_PIN;
    } 
    // else {
    //     throw new Error("typeSol no es válido para ningún tipo de operación.");
    // }

    this.WSParametersMap = this.getWsParameters(); 

    if (this.t24TxnId !== null && this.pinpadOperationType !== null && (this.t24TxnId.length > 0 && this.pinpadOperationType.length > 0)) {
      
        this.URLLocal = this.WSParametersMap["PINPAD.URL"];
        if (this.WSParametersMap["PINPAD.TIMEOUT"] != undefined) {
          this.pinPadtimeOut = this.WSParametersMap["PINPAD.TIMEOUT"];
        }
      

      switch (this.pinpadOperationType) {
        case PIN_PAD.VALIDATE_PIN:
          this.pinpadOperationType = "93";
          result = await this.Process93();
          break;
        case PIN_PAD.CHANGE_PIN:
          this.pinpadOperationType = "97";
          result = await this.Process97();
          break;
      }
    } else {
      console.log('NO EXISTE LA CONFIGURACION');
    }

    return result;
  }

  getWsParameters() {
    var WSMap = [];
    WSMap["PINPAD.URL"] = PIN_PAD.URL;
    WSMap["PINPAD.TIMEOUT"] = PIN_PAD.TIMEOUT;
    WSMap["wsse:Username"] = PIN_PAD.WSS_USERNAME;
    WSMap["wsse:Password"] = PIN_PAD.WSS_PASSWORD; 
    return WSMap;
  }

  async Process93() {
    let ticketResult = "";
    let CrsystemTicketNumberT24Obj = await this.ConnectLocalPostTypeHttp_V3(this.t24TxnId);  //  CR-IRD-020-08072021
    console.log("ticket", CrsystemTicketNumberT24Obj);

    if (this.pinpadError == false) {
      return CrsystemTicketNumberT24Obj;
    }

    return CrsystemTicketNumberT24Obj;
  }

  async Process97() {
    let ticketResult = "";
    let CrsystemTicketNumberT24Obj = await this.ConnectLocalPostTypeHttp_V3(this.t24TxnId);  //  CR-IRD-020-08072021
    console.log("ticket", CrsystemTicketNumberT24Obj);

    if (this.pinpadError == false) {
      return CrsystemTicketNumberT24Obj;
    }

    return CrsystemTicketNumberT24Obj;
  }

  async ConnectLocalPostTypeHttp_V3(t24TxnId) {

    let ticketResult = "";
    try {
      
      let txtServiceDataText = encodeURIComponent(this.WSParametersMap["wsse:Password"]);
      this.message = this.OperationTypeText + this.pinpadOperationType + "&";
      this.message = this.message + this.txnIdtext + t24TxnId + "&";
      this.message = this.message + this.txtServiceData + txtServiceDataText + "&";

      const request = this.message;
      const url = this.URLLocal; 
      console.log('request pinpad',request);
      console.log('url pinpad',url);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: request,
      });
      console.log('response pinpad',response);
      if (!response.ok) {
        throw new Error("HTTP error! Status:" + response.status);
      }

      ticketResult = await response.text();
      console.log('ticketresyk',ticketResult);
      return ticketResult;

    } catch (error) {
      console.error('Error generating ticket:', error.message);
      return null;
    }

  }
}


