export class Client {
    solId: string;
    tipoDocumento: string;
    tipoDocumentoString: string;
    numeroDocumento: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    nombres: string;
    nombresSeccond: string;
    genero: string;
    fecNacimiento: string;
    fecEmision: string;
    profesion: string;
    nivelEducacion: string;
    tipoOcupacion: string;
    email: string;
    telephoneCode: string;
    telephone: string;
    phone: string;
    proteccionDatos: number;
    tipoActividad: string;
    tipoCallCode: number;
    empresaLabora: string;
    departamento: string;
    provincia: string;
    distrito: string;
    ubigeo: string;
    direccion: string;
    situacion: string;
    wfInstance: string;
    solMoneda: number;
    solAmount: number;
    solQuota: number;
    solPeriodGra: number;
    solDayPayment: number;
    solDateFirstPayment: string;
    solValueQuota: number;
    solSaldoCDD: number;
    solTCEA: number;
    solState: number;
    solAssistantReferred: string;
    simulatorAmmount: number;
    desemCtaExi: number;
    desemCtaNew: number;
    desemModEfect: number;
    desemNroCta: string;
    offerProduct: string;
    offerModAct: string;
    offerValidityStart: string;
    offerValidityEnd: string;
    offerAmountMax: number;
    offerAmountMin: number;
    offerCodBase: string;
    offerPlazoMin: number;
    offerPlazoMax: number;
    referenciaNombres: string;
    referenciaApellido: string;
    referenciaTelephoneCode: string;
    referenciaTelephone: string;
    referenciaPhone: string;
    estadoCivil: string;
    offerTEA: number;
    middleName: string;
    montoResta: number;
    debtAmount: number;
    ammountNeto: number;
    desembolsoDescription: string;
    numberCtaExistente: string;
    ofeIndConvPef: string;
    modTransInterbancaria: number;
    modNroCtaInterbancaria: string;
    modTransInterbancariaComision: number;
    modTransInterbancariaBanco: string;
    modCtaInterna: number;
    salesChannel: number;
    entSolicitud: number;
    offerTotalSolicitud: number;
    ammountFree: number;

    clieDatoFinanciero01: string;
    clieDatoFinanciero02: string;

    offerTNA: number;
    offerTEM: number;
    offeTCA: number;
    offeLinEfexFina: number;
    offeCodTipoTarj: string;
    offeTipTarj: string;
    offeLinTcFina: number;
    offeLinFina: number;
    offeNumCuen: string;
    ofeGracePeriodMax?: number;
    userCreation:string;
    responseDataFinanciero01: string;
    responseDataFinanciero02: string;

    offerClase: string;
    estadoCuentaRecepcion: string;
    estadoCuentaFlag: number;
    direccion2: string;
    sumEntidades: number;

    ofeCharacter: string;
    ofeGracePeriod: string;

    solComprasInternet?: boolean;
    solComprasExtranjero?: boolean;
    solRecibirNotificaciones?: boolean;
    solComentarioRestriccion?: string;
    nacionalidad?: string;
    pais?: string;
    planDesgravamen?: string;
    sdSinRescate?: string;
    sdConRescate?: string;

    transferOperationTypeCode?: string;
    typeOfferCode?: string;
    typeOfferDescription?: string;
    transferBankOperationPartyType?: string;
    transferBankTaxOnPaymentRate?: string;
    modTransInterbancariaTradeComision?: number;

    indicadorOrigenSef: string;
    offerBaseOrigen: string;

    creditCardCode?: string;
    creditCardOnlineShopping?: boolean;
    creditCardShoppingAbroad?: boolean;
    creditCardCashprovision?: boolean;
    creditCardOverdraft?: boolean;
    creditCardNotification?: boolean;
    creditCardClientPEP?: boolean;
    creditCardClientAceptaCuentaAhorro?:boolean;
    creditCardTipoCuentaAhorro?:string;
    creditCardCreateSavingAccount: boolean;
    imageReniecBase64?: string;
    tipoTarjeta?: string;
    clieDireccionFull?: string;
    clieDireccionReferencia?: string;

    offerSegTEA?: number;
    solAmountPrimaSeg?: number;
    solAmountNeto?: number;
    solFlagSeguro?: boolean;
    solPercentageSeguro?: number;

    validateFirstClientSection: boolean;
    relationship: string;
    referenciaRelationship: string;
    typeSale: string;

    codeFlowTcRemissionReactivationSpecial: string;
    previousContract : string;
    previousTcDebtAmount: string;
    returnOption: string;
    altaConRemision: string;
    validacionSinToken: string;

    ofProductReferenceCP:string;
    ofProductDescriptionCP:string;
    ofmethodAutorizationCP:string;
    ofnombreTCAntigua:string;
    panTCAntigua:string;

    solCompraInt:string;
    solCompraExt:string;
    solDispEfec:string;
    solTipo:string;
}

export class CreateClient {
    clientDocumemtType: string;
    clientDocumemtNumber: string;
    clientApePat: string;
    clientApeMat: string;
    clientNombres: string;
    clieMiddleName: string;
    situacion: string;

    solCountry: number;
    solEnterprise: string;
    solCurrency: number;
    solAmount: number;
    solQuotaNumber: number;
    solPeriodGra: number;
    solPaymentDay: number;
    solDateFirstPayment: string;
    solValueQuota: number;
    solTCEA: number;
    solSaldoCDD: number;

    offerId: string;
    offerModAct: string;
    offerCodProd: string;
    offerValidityStart: string;
    offerValidityEnd: string;
    offerAmountMax: number;
    offerAmountMin: number;
    offerTEM: number;
    offerTEA: number;
    offePlazoMin: number;
    offePlazoMax: number;
    offeCodigoBase: string;

    ProcessId: number;
    TaskId: string;
    Channel: number;

    seguroProdDato: number;
    seguroDesgravamen: number;

    simulatorAmmount: number;

    entidades: Array<ClienteEntidad> = [];

    applyTF: string;

    prestamoProducto: string;
    prestamoDescription: string;
    prestamoNumero: string;
    prestamoArragement: string;
    prestamoFechaDesembolso: string;
    prestamoMontoOferta: string;
    prestamoDeudaTotal: number;
    flagReengancheCDD: string;
    flagConvenioEntidades: string;
    offerModoActivacionPYA: string;
    messageReenganche: string;
    OfeTCA: number;
    OfeTNA: number;
    OfeLinEfexFina: number;
    OfeCodTipoTarj: string;
    OfeTipTarj: string;
    OfeLinTcFina: number;
    OfeLinFina: number;
    flagColaboradorReenganche: string;
    indicadorOrigenSef: string;
    offeNumCuenta: string;
    diasMora: number;
    quotasPendient: number;
    quotasCanceled: number;
    seguroDesgravamenAnt: number;
    seguroProdDatoAnt: number;
    OfeGracePeriodMax: string;
    /*ley usura*/
    solTipoRescate: string;
    solTasaRescate: number;
    sdSinRescate: string;
    sdConRescate: string;

    OfferAmountMaxSure: string;
    OfferTEASure: string;
    offerPrimaSure?: number;
    seguroOptativoFlag: boolean;
    seguroPercentage?: number;
    safeParcialAmount?: number;
    safeTaxes?: number;
    safeCost?: number;
    safeOfferIdentification: string;

    detalle: [];

    clientFecNac: string;
    OfferTEMSure: string;
    OfferSearchDate: string;

    diferido?: number;
    solTEM?: number;
    solTEA?: number;
    panNumber: string;
    maskPan: string;
    settlementGroup: string;
    product: string;
    productType: string;
    productTypeDescription: string;
    cardBrandIdentification: string;
    settlementGroupCode: string;
    subProductType: string;
    expiryDate: string;
}

export class ClienteEntidad {
    entCodigo: string;
    entNombre: string;
    entCuenta: string;
    entMoneda: number;
    entMonto: number;
    entProducto: string;
    files: Array<FilesSend> = [];
}

export class UploadFile {
    solId: string;
    type: number;
    files: Array<FilesSend> = [];
}

export class FilesSend {
    name: string;
    fileBase64: string;
    rutaId: string;
}

export class FilesClient {
    observacion: string;
    files: Array<FilesGet> = [];
}

export class FilesGet {
    rutaId: string;
    name: string;
}

export class FileDeleteClient {
    solId: string;
    rutaId: string;
}

export class ClientReniec {

    apellidoPaterno: string;
    apellidoMaterno: string;
    nombres: string;
    fechaNacimiento: string;
    fechaEmisionDocumento: string;
    departamento: string;
    provincia: string;
    distrito: string;
    direccion: string;
    estadoCivil: string;
    genero: string;
    gradoInstruccion: string;
    correo: string;
    celular: string;
    telephone: string;
    profesion: string;
    workplace: string;
    typeActivity: string;
    referenceGivenName: string;
    referenceLastName: string;
    referenceTelephone: string;
    referenceCelular: string;
    nacionalidad: string;
    pais: string;
    reniecImage: string;
    relationship: string;
    referenceRelationship: string;
    numDoc:string;
    typeDoc:string;
    enableInsuranceTC: boolean;
    situacionCliente: string;
}

export class RequestClientLoan {
    documentType: string;
    documentNumber: string;
    codeProductT24: string;
}

export class ClientLoan {
    loanProductCode: string;
    loanProductDescryption: string;
    loanNumber: string;
    loanArrangement: string;
    loanDisbursementDate: string;
    loanAmmountOffer: string;
    loanAmmountOfferFormat: string;
    quotasCanceled: number;
}

export class WorkFlowHeader {
    wfInsId: string;
    wfProNombre: string;
    wfInsUsuCre: string;
    wfInsFechaCre: string;
    wfInsFechaFin: string;
    wfHoraIni: string;
    wfHoraFin: string;
}

export class RequestValidationLoan {
    idClientT24: string;
    offerCodProd: string;
    quotaCancel: number;
}

export class ResponseValidationLoan {
    code: number;
    showAlert: boolean;
    message: string;
}

export class RequestClientReprogramming {
    contractNumber: string;
    currency: number;
    tasaTem: number;
    classCode: string;
}

export class ResponseClientReprograming {
    cardNumber: string;
    totalDebt: number;
    totalDebtFormat: string;
    capital: number;
    capitalFormat: string;
    interests: number;
    interestsFormat: string;
    mora: number;
    moraFormat: string;
    debtAditional: number;
    debtAditionalFormat: string;
    another: number;
    anotherFormat: string;
    contractNumber: string;
}


export class CreateClientReprogramming {
    clientDocumemtType: string;
    clientDocumemtNumber: string;
    clientApePat: string;
    clientApeMat: string;
    clientNombres: string;
    clieMiddleName: string;
    situacion: string;

    solCountry: number;
    solEnterprise: string;
    solCurrency: number;
    solAmount: number;
    solQuotaNumber: number;
    solPeriodGra: number;
    solPaymentDay: number;
    solDateFirstPayment: string;
    solValueQuota: number;
    solTCEA: number;
    solSaldoCDD: number;

    offerId: string;
    offerModAct: string;
    offerCodProd: string;
    offerValidityStart: string;
    offerValidityEnd: string;
    offerAmountMax: number;
    offerAmountMin: number;
    offerTEM: number;
    offerTEA: number;
    offePlazoMin: number;
    offePlazoMax: number;
    offeCodigoBase: string;

    ProcessId: number;
    TaskId: string;
    Channel: number;

    simulatorAmmount: number;

    OfeTCA: number;
    OfeTNA: number;
    OfeLinEfexFina: number;
    OfeCodTipoTarj: string;
    OfeTipTarj: string;
    OfeLinTcFina: number;
    OfeLinFina: number;
    OfeNumeroDiasAtraso: number;
    offeNumCuenta: string;
    codigoProductoT24: string;

    cardNumber: string;
    contractNumber: string;
    totalDebt: number;
    capital: number;
    intereses: number;
    mora: number;
    otherConcepts: number;
    simulationAmmountQuota: number;
    simulationTEA: number;
    simulationTCEA: number;
    simulationIntereses: number;
    simulationImportTotal: number;
    simulationFirstPayment: string;

    seguroProdDato: number;
    seguroDesgravamen: number;
    applyTF: string;
    ofeClase: string;

    tCBicfi: string;
    tCBankPartyIdentification: string;
    tCAmmount: string;
    tCDocumentIdentification: string;
    ofeCharacter: string;
    ofeGracePeriod: string;
    BaseOrigen: string;
    diasMora?: number;
    solTipoRescate?: string;
    solTasaRescate?: number;
    sdSinRescate?: string;
    sdConRescate?: string;
    ofeModoActivacionPYA?: string;
    OfeGracePeriodMax?: number;
    OfferSearchDate: string;
}

export class UpdateClientSimulationReprograming {
    solId: string;
    solQuotaNumber: number;
    solValueQuota: number;
    solPeriodGra: number;
    solDateFirstPayment: string;

    simulationAmmountQuota: number;
    simulatorAmmount: number;
    simulationTEA: number;
    simulationTCEA: number;
    simulationIntereses: number;
    simulationImportTotal: number;
    simulationFirstPayment: string;

    tCBicfi: string;
    tCBankPartyIdentification: string;
    tCAmmount: string;
    tCDocumentIdentification: string;
}

export class RequestClientReprogrammingSef {
    contractNumber: string;
    currency: number;
    productCode: string;
}

export class ResponseClientReprogramingSef {
    contractNumber: string;
    totalDebt: number;
    totalDebtFormat: string;
    capital: number;
    capitalFormat: string;
    interests: number;
    interestsFormat: string;
    taxes: string;
    taxesFormat: string;
}

export class CreateClientCappta {
    clientDocumemtType: string;
    clientDocumemtNumber: string;
    clientApePat: string;
    clientApeMat: string;
    clientNombres: string;
    clieMiddleName: string;
    clieMail: string;
    clientFechaNac: string;
    situacion: string;

    solCountry: number;
    solEnterprise: string;
    solCurrency: number;

    offerId: string;
    offerModAct: string;
    offerCodProd: string;
    offerValidityStart: string;
    offerValidityEnd: string;
    offerAmountMax: number;
    offerAmountMin: number;
    offerTEM: number;
    offerTEA: number;
    offePlazoMin: number;
    offePlazoMax: number;
    offeCodigoBase: string;

    ProcessId: number;
    TaskId: string;
    Channel: number;

    OfeTCA: number;
    OfeTNA: number;
    OfeLinEfexFina: number;
    OfeCodTipoTarj: string;
    OfeTipTarj: string;
    OfeLinTcFina: number;
    OfeLinFina: number;
    offeNumCuenta: string;
    ofeClase: string;
    ammountCoco: number;
    ofeModoActivacionTC: string;
    ofeCodTipOferta: string;
    offersearchdate: string;

    previousTcNumber?:string;
    previousContract?: string;
    debtAmount?: string;
    returnOption?: string;
    altaConRemision?: string;
    codeFlowTcRemissionReactivationSpecial?: string;
    ofeLinComprasIniA: number;
    ofeLinEfexIniA: number;
    OfProductReferenceCP: string;
    OfProductDescriptionCP: string;
    OfmethodAutorizationCP: string;
    ofnombreTCAntigua: string;
    panTCAntigua: string;
}

export class RequestValidateReprogramming {
    documentType: string;
    documentNumber: string;
    productId: string;
    baseCode: string;
}

export class ResponseValidateReprogramming {
    status: boolean;
}

export class RequestUpdateCardRestrictions {
    comprasInternet: boolean;
    comprasExtranjero: boolean;
    recibirNotificaciones: boolean;
    comentarioRestriccion: string;
    numeroTarjeta: string;
    numeroTarjetaOfuscada: string;
    idClientT24: string;
}

export class ResponseUpdateCardRestrictions {
    details: ResponseUpdateCardRestrictionsDetails[];
    tempFileName: string;
    constancias: string[];
}

export class ResponseUpdateCardRestrictionsDetails {
    operation: string;
    status: string;
}

export class ResponseGetHistoryRestrictions {
    constancia: string;
    cuenta: string;
    numeroTarjeta: string;
    tipoCliente: string;
    nombreTarjeta: string;
    restriccion: string;
    accion: string;
    fechaProceso: string;
    usuario: string;
}

export class ResponseGetCardRestrictions {
    comprasInternet: boolean;
    comprasExtranjero: boolean;
    recibirNotificaciones: boolean;
    comentarioRestriccion: string;
}

export class ResponseGetCardsClient {
    secuencial: string;
    contrato: string;
    numeroTarjeta: string;
    numeroTarjetaOfuscado: string;
    producto: string;
    tipo: string;
    productoTipo: string;
    productoSubTipo: string;
}

export class RequestValidationPoliticalAndBlocks {
    contractNumber: string;
    productCode: string;
    tipo: string;
    documentNumber: string;
    documentType: string;
}

export class ResponseValidationPoliticalAndBlocks {
    mensaje: string;
    diasAtraso: number;
    diasAtrasoPermitido: number;
    block: boolean;
    codigoBloqueo: string;
}

export class ResponseGetClientT24 {
    id: string;
    apellidoPaterno: string;
    apellidoMaterno: string;
    nombres: string;
    fechaNacimiento: string;
    fechaEmisionDocumento: string;
    departamento: string;
    provincia: string;
    distrito: string;
    direccion: string;
    estadoCivil: string;
    genero: string;
    gradoInstruccion: string;
    correo: string;
    celular: string;
    telephone: string;
    workplace: string;
    profesion: string;
    typeActivity: string;
    referenceGivenName: string;
    referenceLastName: string;
    referenceTelephone: string;
    referenceCelular: string;
    numeroDocumento: string;
    tipoDocumento: string;
}

export class ResponseClientInsurance {
    fromLoadDateTimeStr: string;
    expiryDateStr: string;
    indexReliefInsurance: string;
    insuranceAmount: number;
    weightedAverageLoan: number;
    principalAmount: number;
    numberOfDays: number;
    fromBillingDateTimeStr: string;
    toBillingDateTimeStr: string;
    interestPercentageStr: number;
    baseCurrency: string;
    settlementGroupCode: number;
    productIdentifier: string;
    productDescription: string;
    productType: string;
    account: string;
    motivo: string;
}

export class RequestGetCardDetail {
    contractNumber: string;
    paymentCardNumberEncryp: string;
    stateCard: string;
    ignoreMovements?:boolean;
}

export class RequestGetProducts {
    documentType: string;
    documentNumber: string;
}

export class RequestGetLoanDetail {
    arrangment: string;
    codeProductT24: string;
    loanNumber: string;
    ip: string;
    currency: string;
}


export class RequestValidateAccount {
    solId: string;
}

export class ResponseValidateAccount {
    isValid: boolean;
    message: string;
}

export class ResponseConsultTCProducts {
    idClientT24: string;
    fullName: string;
    emailPersonal: string;
    emailLaboral: string;
    direccionPersonal: string;
    direccionLaboral: string;
    observation: string;
    cards: Array<CardViewModel> = [];
}

export class CardViewModel {
    agency:string;
    codAgency:string;
    numberAccount :string;
    registerDate :string;
    registerHour :string;
    typeSend :string;
    typeSendCode :number;
    typeModality :string;
    typeModalityCode :number;
    numberCard :string;
    description :string;
    channel :string;
    userChannel :string;
    userHost :string
}

export class ResponseEmailT24{
  estado:string;
}

export class ClientData
{
  documentNumber: string;
  startDate: string;
  fullName: string;
  lastName: string;
  secondLastName: string;
  dateOfBirth: string;
  situation: string;
  reniecImage: string;
  email: string;
}

export class ActiveProduct{
    type :string;
    title :string;
    status :number;
    detail :string;
    instance :string;
    additionalProp1 :string;
    additionalProp2 :string;
    additionalProp3 :string;
}

export class flagsServAsoc{
    comprasInternet: boolean;
    comprasExtranjero: boolean;
    dispoEfect: boolean;
}

export class RqActProd{
    userid :string;
    contractNumber :string;
}