import { Component, HostListener } from '@angular/core';

import { Platform } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { AppConstants } from './shared/constants/app.constants';
import { ToastaConfig } from 'ngx-toasta';
import { Router } from '@angular/router';
import { FirebaseService } from './services/firebase.service';

const seconds: number = 600;

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  private homePath: string = "productsList";
  private loginPath: string = "login";

  loading: boolean = false;
  timeLeft: number = 0;
  interval;
  stop: boolean = false;
  mouseMove: number = 0;
  timeSeconds: number = seconds;

  notificationTimer: boolean = true;
  intervalNotification;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    public router: Router,
    private toastaConfig: ToastaConfig,
    private firebaseService: FirebaseService
  ) {
    //this.initializeApp();
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
    });
  }

  ngOnInit() {
    setTimeout(() => {
      if (sessionStorage.getItem(AppConstants.Session.TIMESECONDS) != undefined &&
          sessionStorage.getItem(AppConstants.Session.TIMESECONDS) != null &&
          sessionStorage.getItem(AppConstants.Session.TIMESECONDS) != '')
        this.timeSeconds = Number(sessionStorage.getItem(AppConstants.Session.TIMESECONDS));

      var accessToken = sessionStorage.getItem(AppConstants.Session.ACCESS_TOKEN);
      if (accessToken != null && accessToken != "" && accessToken != undefined)
        this.loading = true;
    }, 500);

    this.startTimerNotification(this.notificationTimer);
    this.firebaseService.receiveMessage();
  }

  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event) {
    clearInterval(this.interval)
    this.stop = false;
    this.timeLeft = this.timeSeconds;
    if (!this.stop) {
      this.interval = setInterval(() => {
        this.subtractTime();
      }, 1000)
    }
  }

  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    clearInterval(this.interval)
    this.stop = false;
    this.timeLeft = this.timeSeconds;
    if (!this.stop) {
      this.interval = setInterval(() => {
        this.subtractTime();
      }, 1000)
    }
  }

  subtractTime() {
    if (this.timeLeft > 0) {
      this.timeLeft--;
      this.stop = false;
    } else {
      this.timeLeft = this.timeSeconds;
      sessionStorage.clear();
      this.navigateTo('', this.loginPath);
    }
  }

  navigateTo($event, path) {
    if (path == this.homePath)
      this.loading = true;
    else if (path == this.loginPath)
      this.loading = false;

    this.stop = true;
    this.router.navigate([path]);
  }

  ngOnDestroy(): void { }

  startTimerNotification(validate : boolean) {
    let timeSecond = 1800;
    if (validate) {
        this.notificationTimer = false;
        this.intervalNotification = setInterval(() => {
            if (timeSecond > 0) {
                timeSecond--;
            } else {
                clearInterval(this.intervalNotification);
                this.notificationTimer = true;
                //Call WS
                if(sessionStorage.getItem(AppConstants.Session.USERROLID) != undefined &&
                sessionStorage.getItem(AppConstants.Session.USERROLID) != null &&
                sessionStorage.getItem(AppConstants.Session.USERROLID) != ''){
                  this.RegisterDeviceToken();
                }
                this.startTimerNotification(this.notificationTimer);
            }
        }, 1000)
    }    
  }

  private async RegisterDeviceToken(){
    await this.platform.ready();
    if (!this.platform.is('cordova')) {
      this.firebaseService.requestPushNotificationsPermission(AppConstants.Channel.WEB);
    } else {
      await this.firebaseService.requestTokenMovil(AppConstants.Channel.MOVIL);
    }
  }

}
