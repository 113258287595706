import { Component, OnInit } from "@angular/core";
import { SolicitudService } from "../../../../services/ms-backoffice/solicitud.service";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SolicitudPut } from "../../../../models/solicitud";
import { HttpErrorResponse } from "@angular/common/http";

@Component({
  selector: "request-process",
  templateUrl: "./request-process.component.html",
  styleUrls: ["./request-process.component.scss"],
})
export class RequestProcessComponent implements OnInit {
  arrayMessages = [];
  isLoadingProcess = false;
  messagestatusResult = "";
  classmessage = "";
  errorProcess = false;
  maxAttempts = false;
  maxiError = 0;
  // Paramtros
  solicitudId: string;
  onClose: (() => void) | undefined;

  constructor(private solicitudService: SolicitudService, public modalRef: BsModalRef) {}

  ngOnInit() {
    this.isLoadingProcess = true;
    this.CallFinalizar();
  }

  FinalizarSolicitud(solicitudId: string) {
    console.log("FinalizarSolicitud");
    this.solicitudService.solicitudProcess(solicitudId).subscribe(
      async (response) => {
        this.arrayMessages = [...response.messages.filter((f) => f.status !== "NA")];
        this.maxAttempts = response.maxAttempts;
        this.maxiError = 0;
        if (response.codigo === 400) {
          this.errorProcess = true;
          this.isLoadingProcess = false;
          this.classmessage = "modal-texto-error";
          this.messagestatusResult =
            this.maxAttempts === true
              ? "Usted superó el número de reintentos, favor comunicarse con el área de soporte."
              : "Finalizado con Error, porfavor vuelva a intentar";
        } else if (response.pending && !response.finish) {
          this.errorProcess = false;
          this.CallFinalizar();
        } else if (!response.pending && response.finish) {
          this.errorProcess = false;
          this.classmessage = "modal-texto-exito";
          this.messagestatusResult = "FINALIZADO CON EXITO";
          this.isLoadingProcess = false;
        } else {
          this.isLoadingProcess = false;
        }
      },
      (error: HttpErrorResponse) => {
        // this.loading = false;
        this.isLoadingProcess = false;
        this.errorProcess = true;
        this.maxiError = this.maxiError + 1;
        if (this.maxiError == 3) {
          this.maxAttempts = true;
        }
      }
    );
  }

  CallFinalizar() {
    this.FinalizarSolicitud(this.solicitudId);
    this.errorProcess = true;
  }

  closeAlertModal() {
    if (this.onClose && !this.errorProcess) {
      this.onClose(); // Llama a la función de callback
    }
    this.modalRef.hide();
  }

  retryAgain() {
    this.isLoadingProcess = true;
    this.errorProcess = false;
    this.arrayMessages = [];
    this.messagestatusResult = "";
    this.classmessage = "";
    this.CallFinalizar();
  }
}
