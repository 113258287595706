import { Component, Input, OnInit } from '@angular/core';
import { Option } from '../../../models/option';
import { ControlContainer, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'onb-checkbox-group',
  templateUrl: './onb-checkbox-group.component.html',
  styleUrls: ['./onb-checkbox-group.component.css']
})
export class OnbCheckboxGroupComponent implements OnInit {

  @Input() label: string;
  @Input() name: string;
  @Input() options: Option[];
  formGroup:FormGroup;
  control: FormArray;

  rules = {
    required: (props, label) => `Marque una opcion ${label}`,
    requireChecked: (props, label) => `Marque ${label}`    
  };

  constructor(private controlContainer: ControlContainer) {
    console.log(this.options);
  }  

  // get formArray() {
  //   return this.formGroup.get(this.name) as FormArray;
  // }

  // get formGroup() {
  //   return this.controlContainer.control as FormGroup;
  // }

  ngOnInit(): void {
    
    //console.log(this.control);
    // // this.control.updateValueAndValidity();

    // // Acceder al FormGroup del formulario principal
    // const mainForm = this.controlContainer.control as FormGroup;

    // // Crear el FormArray si no existe
    // if (!mainForm.contains(this.name)) {
    //   mainForm.addControl(this.name, new FormArray([]));
    // }

    // // Agregar los controles al FormArray
    // for (const item of this.options) {
    //   (mainForm.get(this.name) as FormArray).push(this.fb.control(false));
    // }    
    // this.options.forEach(item => {
    //   this.control.push(new FormControl(item.selected));
    // });
    this.formGroup = this.controlContainer.control as FormGroup;
    this.control = this.formGroup.get(this.name) as FormArray;
    this.options.forEach(item => {
      this.control.push(new FormControl(false,this.control.validator));
    });
    console.log(this.options);
  }

  onCheckboxChange(e: any) {    
    // if (e.target.checked) {
    //   this.control.push(new FormControl(e.target.value));
    // } else {
    //   let i: number = 0;
    //   this.control.controls.forEach((item: any) => {
    //     if (item.value == e.target.value) {
    //       this.control.removeAt(i);
    //       return;
    //     }
    //     i++;
    //   });
    // }
  }

  isInvalid(): boolean {    
    //return this.control.invalid && this.control.touched;    
    return false;
  }
  
  getErrorMessage() {
    let message = '';    
    for (const error in this.control.errors) {
      // Si el error actual tiene una función de mensaje de error correspondiente
      if (this.rules[error]) {
        // Llama a la función de mensaje de error con los detalles del error
        message = this.rules[error](this.control.errors[error], this.label);
        break;
      }
    }
    return message;
  }

}
