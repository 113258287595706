import { Component, Input, OnInit } from '@angular/core';
import { SolicitudBoSession } from '../../../models/solicitud-bo';
import { TipoSolicitudBO } from '../../constants/app.constants';

@Component({
  selector: 'solicitud-header',
  templateUrl: './solicitud-header.component.html',
  styleUrls: ['./solicitud-header.component.scss'],
})
export class SolicitudHeaderComponent implements OnInit {
  @Input() title: string;
  @Input() imagen: string;
  @Input() data: SolicitudBoSession;
  hasData: boolean = false;

  incrementoLinea = TipoSolicitudBO.IncrementoLinea;
  cambioProducto = TipoSolicitudBO.CambioProducto;

  constructor() { }

  ngOnInit() {
    this.hasData = (this.data!=null);
    this.imagen = this.imagen ? `/assets/images/${this.imagen}` : '';
  }

}
