import {Injectable} from '@angular/core';
import {File} from '@ionic-native/file/ngx';
import {FileOpener} from '@ionic-native/file-opener/ngx';


@Injectable({
    providedIn: 'root'
})
export class StorageService {

    constructor(
        private file: File,
        private fileOpener: FileOpener
    ) {
    }


    public WriteFileMovil(nameFile: string, data: Blob) {
        this.file.writeFile(this.file.dataDirectory, nameFile, data, {replace: true}).then(fileEntry => {
            this.fileOpener.showOpenWithDialog(this.file.dataDirectory + nameFile, this.getMIMEtype(nameFile));
        });
    }

    private getMIMEtype(filename: string) {
        const extension = filename.split('.');
        const extn = extension[extension.length - 1];
        const ext = extn.toLowerCase();
        const MIMETypes = {
            'txt': 'text/plain',
            'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'doc': 'application/msword',
            'pdf': 'application/pdf',
            'jpg': 'image/jpeg',
            'bmp': 'image/bmp',
            'png': 'image/png',
            'xls': 'application/vnd.ms-excel',
            'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            'rtf': 'application/rtf',
            'ppt': 'application/vnd.ms-powerpoint',
            'pptx': 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
        };
        return MIMETypes[ext];
    }

}
