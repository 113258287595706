import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpParams} from '@angular/common/http';
import {HelperService} from '../../shared/services/helper.service';
import {environment} from '../../../environments/environment';
import {
  ClientData,
    ClientReniec,
    ResponseConsultTCProducts,
    ResponseGetClientT24,
} from '../../models/client';
import {Observable, of} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ClientService {

    constructor(
        private httpClient: HttpClient
    ) {
    }

    private POST_ENDPOINT: string = 'Client';
    private BASE_URL: string = HelperService.buildRequestURL(environment.msBackofficeApi);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    getClientReniec(typeDoc: string, documentNumber: string): Observable<ClientReniec> {
        return this.httpClient.get<ClientReniec>(`${this.REQUEST_URL}/Reniec?docType=${typeDoc}&documentNumber=${documentNumber}`);
    }

    getClientData(typeDoc: string, documentNumber: string): Observable<ClientData> {
      return this.httpClient.get<ClientData>(`${this.REQUEST_URL}/ClientData?docType=${typeDoc}&documentNumber=${documentNumber}`);
    }

    getClientT24(typeDoc: string, documentNumber: string): Observable<ResponseGetClientT24> {
      return this.httpClient.get<ResponseGetClientT24>(`${this.REQUEST_URL}/T24?docType=${typeDoc}&documentNumber=${documentNumber}`);
    }

    getProductsTC(typeDocument: string, documentNumber: string): Observable<any> {
        const httpParams = new HttpParams({
            fromObject: {docType: typeDocument, documentNumber: documentNumber}
        });
        return this.httpClient.get<ResponseConsultTCProducts>(`${this.REQUEST_URL}/ProductsTC`, {params: httpParams});
    }
}
