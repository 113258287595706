import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { BsModalRef } from "ngx-bootstrap/modal";
import { SolicitudService } from "../../../../services/ms-backoffice/solicitud.service";
import { AlertService, MessageSeverity } from "../../../services/alert.service";
import { AppConstants } from "../../../constants/app.constants";
import { StorageService } from "../../../services/storage.service";
import * as FileSaver from "file-saver";
import { Platform } from "@ionic/angular";
import { RequestTrayService } from "../../../../services/ms-backoffice/request-tray.service";
import { Router } from '@angular/router';

@Component({
  selector: "app-request-process-cancel-modal",
  templateUrl: "./request-process-cancel-modal.component.html",
  styleUrls: ["./request-process-cancel-modal.component.css"],
})
export class RequestProcessCancelModalComponent implements OnInit {
  arrayMessages = [];
  isLoadingProcess = false;
  messagestatusResult = "";
  classmessage = "";
  validDownload = false;
  isSuccess = false;
  errorProcess = false;
  maxAttempts = false;  
  maxiError = 0;
  type: string;
  // Paramtros
  solicitudId: string;
  onClose: (() => void) | undefined;
  rutaSinBase: string = '';

  constructor(
    private solicitudService: SolicitudService,
    private alertaService: AlertService,
    private storageService: StorageService,
    private platform: Platform,
    private requestTrayService: RequestTrayService,
    public modalRef: BsModalRef,
    private router: Router
  ) {
    this.rutaSinBase = this.router.url; // Obtiene solo la ruta después del dominio
  }

  ngOnInit() {
    this.isLoadingProcess = true;
    this.CallFinalizar();
  }

  FinalizarSolicitud(solicitudId: string) {
    console.log("FinalizarSolicitud");
    this.solicitudService.solicitudProcess(solicitudId).subscribe(
      async (response) => {
        this.arrayMessages = [
          ...response.messages.filter((f) => f.status !== "NA"),
        ];
        this.maxAttempts = response.maxAttempts;
        this.maxiError = 0;
        if (response.codigo === 400) {
          this.errorProcess = true;
          this.isLoadingProcess = false;
          this.classmessage = "modal-texto-error";
          this.messagestatusResult =
            this.maxAttempts === true
              ? "Usted superó el número de reintentos, favor comunicarse con el área de soporte."
              : "Finalizado con Error, porfavor vuelva a intentar";
        } else if (response.pending && !response.finish) {
          this.errorProcess = false;
          this.CallFinalizar();
        } else if (!response.pending && response.finish) {
          this.errorProcess = false;
          this.classmessage = "modal-texto-exito";
          this.messagestatusResult = "FINALIZADO CON EXITO";
          this.isSuccess = true;
          this.isLoadingProcess = false;
        } else {
          this.isLoadingProcess = false;
        }
      },
      (error: HttpErrorResponse) => {
        // this.loading = false;
        this.validDownload = true;
        this.isLoadingProcess = false;
        this.errorProcess = true;
        this.maxiError = this.maxiError + 1;
        if (this.maxiError == 3) {
          this.maxAttempts = true;          
        }
        let responseError = error.error;
        console.log('responseError error', error);        
        if (responseError) {
          if (responseError.code === 409) {
            this.errorProcess = true;
            this.isLoadingProcess = false;
            this.classmessage = 'modal-texto-error';
            this.messagestatusResult = responseError.messages[0];
            this.alertaService.showMessage(AppConstants.TitleModal.CANCELACION_CUENTA, this.messagestatusResult, MessageSeverity.error);
            return;
          }
          if (responseError.code === 999) {
            console.log('ERROR',responseError);
            this.errorProcess = true;
            this.isLoadingProcess = false;
            this.classmessage = 'modal-texto-error';
            this.messagestatusResult = this.maxAttempts ? 'Usted ha superado el número máximo de intentos permitidos' :'Finalizado con Error, porfavor vuelva a intentar';
            this.alertaService.showMessage(AppConstants.TitleModal.CANCELACION_CUENTA, responseError.messages[0], MessageSeverity.error);
            let resp = JSON.parse(responseError.messages[1]);
            this.arrayMessages = [...resp.messages.filter(f => f.status !== 'NA')];
            return;
          }

          switch (this.type) {
            case 'TRAY_VIEW':
                this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                    MessageSeverity.error);
                break;
            case 'VALIDATION_VIEW':
                this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                    MessageSeverity.error);
                break;
            case 'TRAY_VIEW_SUPERVISOR':
                this.alertaService.showMessage(AppConstants.TitleModal.PROCESS_MANAGEMENT, error.error.messages,
                    MessageSeverity.error);
                break;
          }
        }
      }
    );
  }

  CallFinalizar() {
    this.FinalizarSolicitud(this.solicitudId);
    //this.errorProcess = true;
  }

  closeAlertModal() {
    if(!this.validDownload){
      this.donwloadConstacia(0);
    }

    if (this.onClose && !this.errorProcess) {
      this.onClose(); // Llama a la función de callback
    }

    if(this.maxAttempts){      
      if(this.rutaSinBase.includes("bandeja-postventa/view")){
        this.modalRef.hide();
      }else{
        this.router.navigate(["/solicitud/verificacion"]);
      }
    }

    this.modalRef.hide();
  }

  retryAgain() {
    this.isLoadingProcess = true;
    this.errorProcess = false;
    this.arrayMessages = [];
    this.messagestatusResult = "";
    this.classmessage = "";
    this.CallFinalizar();
  }

  donwloadConstacia(row: any) {
    this.isLoadingProcess = true;

    var filter = {
      solId: this.solicitudId,
      NameProduct:""
    };

    this.requestTrayService
      .downloadConstanciaCancelacionPDF(filter)
      .subscribe(
        (response) => {
          const data: Blob = new Blob([response], {
            type: response.type,
          });
          var nameFile = `Constancia_${this.solicitudId}.pdf`;
          if (!this.platform.is("cordova")) {
            FileSaver.saveAs(data, nameFile);
          } else {
            this.storageService.WriteFileMovil(nameFile, data);
          }
          this.validDownload = true;
        },
        (error) => {
          this.alertaService.showMessage(
            AppConstants.TitleModal.DOWNLOAD_FILE,
            error.messages,
            MessageSeverity.error
          );
        }
      )
      .add(() => {
        this.isLoadingProcess = false;
      });
  }
}
