export class SolicitudBo {
  solicitudBOId: string;
  tipoSolicitud: number;
  estado: string;
  tipoDocumento: string;
  numeroDocumento: string;
  correoCliente: string;
  direccionCliente:string;
  correoLabCliente: string;
  direccionLabCliente:string;
  nombreCliente: string;
  usuarioId: string;
  usuarioRol: string;
  codigoAgencia: string;
}

export class SolicitudBoSession {
  solicitudBOId: string;
  tipoSolicitud: number;
  tipoDocumento: string;
  tipoDocumentoName: string;
  numeroDocumento: string;
  nombreCliente: string;
  correoCliente: string;
  direccionCliente?:string;
  correoLabCliente?: string;
  direccionLabCliente?:string;
  montoIncremento?: number;
  nombreProducto?: string
  estadoTarjeta?: string
  constructor(){
    this.solicitudBOId = "";
    this.tipoSolicitud = 0;
    this.tipoDocumento = "";
    this.tipoDocumentoName = "";
    this.numeroDocumento ="";
    this.nombreCliente = "";
    this.correoCliente = "";
    this.direccionCliente = "";
    this.correoLabCliente = "";
    this.direccionLabCliente = "";
    this.montoIncremento = 0;
    this.nombreProducto = "";
    this.estadoTarjeta = "";
  }
}

export class SolicitudBODebito extends SolicitudBo {
  numeroTarjeta : string;
  panEncrypt: string;
  numeroCuenta : string;
  consumosInternet : number;
  consumosExtranjero : number;
  disposicionEfectivo : number;
  operacionTarjeta : number;
  motivoBloqueo : string;
  motivoCClave : number;
  fechaActivacion : string;
  fechaVencimiento : string;
  observacion : string;
  cuentas: SolicitudBOCuenta[]
}

export class SolicitudBOCuenta {
  prioridad: number;
  numeroCuenta: string;
  productCode: string;
  productDesc: string;
  moneda: string;
}

export class SolicitudBOCredito extends SolicitudBo {
  tipoEnvio: number;
  tipoModalidad: number;
  numeroTarjeta: string;
  numeroCuenta: string;
}

export class ResponseProcessSolicitudBO {
  codigo: number;
  pending: boolean;
  finish: boolean;
  messages: Array<{message:string, status:string}> = [];
  maxAttempts: boolean;
}


export class SolicitudBOAsoCta extends SolicitudBo {
  consumosInternet: number;
  consumosExtranjero: number;
  disposicionEfectivo: number;
  operacionTarjeta: string;
  asociarCuentaDetalle: SolicitudBOAsoCtaDetalle[]
}

export class SolicitudBOAsoCtaDetalle {
  prioridad: number;
  numeroCuenta: string;
  productCode: string;
  productDesc: string
}

export class SolicitudBOContact extends SolicitudBo {
  tipoEnvio: string;
  modalidad: string;
  numeroCuenta: string;
}

export class SolicitudBOIncremetoL extends SolicitudBo {
  clienteId: number;
  ofertaId: string;
}