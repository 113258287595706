import {Directive, ElementRef, HostListener} from '@angular/core';

@Directive({
    selector: '[TextT24]'
})
export class TextT24Directive {
    regExpresionReplace = /[^a-zA-Z0-9#()!.\s]*$/;
    regExpresion = /^[a-zA-Z0-9#()!.\s]*$/;

    private navigationKeys = [
        'Backspace',
        'Delete',
        'Tab',
        'Escape',
        'Enter',
        'Home',
        'End',
        'ArrowLeft',
        'ArrowRight',
        'Clear',
        'Copy',
        'Paste'
    ];

    inputElement: HTMLInputElement;

    constructor(private element: ElementRef) {
        this.inputElement = element.nativeElement;
    }

    @HostListener('keypress', ['$event'])
    onKeypressHandler(e: any) {
        if (
            this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
            ((e.key === 'a' || e.code === 'KeyA') && e.ctrlKey === true) || // Allow: Ctrl+A
            ((e.key === 'c' || e.code === 'KeyC') && e.ctrlKey === true) || // Allow: Ctrl+C
            ((e.key === 'v' || e.code === 'KeyV') && e.ctrlKey === true) || // Allow: Ctrl+V
            ((e.key === 'x' || e.code === 'KeyX') && e.ctrlKey === true) || // Allow: Ctrl+X
            ((e.key === 'a' || e.code === 'KeyA') && e.metaKey === true) || // Allow: Cmd+A (Mac)
            ((e.key === 'c' || e.code === 'KeyC') && e.metaKey === true) || // Allow: Cmd+C (Mac)
            ((e.key === 'v' || e.code === 'KeyV') && e.metaKey === true) || // Allow: Cmd+V (Mac)
            ((e.key === 'x' || e.code === 'KeyX') && e.metaKey === true) // Allow: Cmd+X (Mac)
        ) {
            // let it happen, don't do anything
            return true;
        }

        const charCode = (e.which) ? e.which : e.keyCode;
        return this.regExpresion.test(String.fromCharCode(charCode));
    }

    @HostListener('paste', ['$event'])
    onPaste(event: ClipboardEvent): void {
        let pastedInput = '';
        if (event.clipboardData && event.clipboardData.getData) {
            // Other browsers
            pastedInput = event.clipboardData.getData('text/plain');
        }
        this.pasteData(pastedInput);
        event.preventDefault();
    }

    private pasteData(pastedContent: string): void {
        pastedContent = pastedContent.replace(this.regExpresionReplace, '');
        const pasted = document.execCommand('insertText', false, pastedContent);
        if (!pasted) {
            if (this.inputElement.setRangeText) {
                const {selectionStart: start, selectionEnd: end} = this.inputElement;
                this.inputElement.setRangeText(pastedContent, Number(start), Number(end), 'end');
                if ('InstallTrigger' in window) {
                    this.inputElement.dispatchEvent(new Event('input', {cancelable: true}));
                }
            } else {
                // Browser does not support setRangeText, e.g. IE
                this.insertAtCursor(this.inputElement, pastedContent);
            }
        }
    }

    private insertAtCursor(myField: HTMLInputElement, myValue: string): void {
        const startPos = Number(myField.selectionStart);
        const endPos = Number(myField.selectionEnd);

        myField.value =
            myField.value.substring(0, startPos) +
            myValue +
            myField.value.substring(endPos, myField.value.length);

        const pos = startPos + myValue.length;
        myField.focus();
        myField.setSelectionRange(pos, pos);

        this.triggerEvent(myField, 'input');
    }

    private triggerEvent(el: HTMLInputElement, type: string): void {
        if ('createEvent' in document) {
            // modern browsers, IE9+
            const e = document.createEvent('HTMLEvents');
            e.initEvent(type, false, true);
            el.dispatchEvent(e);
        }
    }

}
