import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import * as jwt_decode from 'jwt-decode';
import {AppComponent} from '../../app.component';
import {TokenModel} from '../../models/user';
import {AuthService} from '../../services/auth.service';
import {ParameterService} from '../../services/parameter.service';
import {AppConstants, LoginMessages} from '../../shared/constants/app.constants';
import {AlertService, MessageSeverity} from '../../shared/services/alert.service';
import {forkJoin, Subscription} from 'rxjs';
import {ReCaptchaV3Service} from 'ng-recaptcha';
import {VerificationService} from '../../services/verification.service';

import {Platform} from '@ionic/angular';
import {FirebaseService} from '../../services/firebase.service';
import {HttpErrorResponse} from '@angular/common/http';
import { DeviceService } from '../../services/device.service';
import { DeviceObject } from '../../models/device';
import { AuditoriaService } from '../../services/auditoria.service';
import { Parameter } from '../../models/parameter';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

    private singleExecutionSubscription: Subscription;
    public token: string;

    constructor(
        private appComponent: AppComponent,
        private formBuilder: FormBuilder,
        private alertService: AlertService,
        private authService: AuthService,
        private parameterService: ParameterService,
        private recaptchaV3Service: ReCaptchaV3Service,
        private verificationService: VerificationService,
        private platform: Platform,
        private firebaseService: FirebaseService,
        private deviceService: DeviceService,
        private auditoriaService: AuditoriaService
    ) {
    }

    @Output() navigateToEvent = new EventEmitter<string>();

    loginForm: FormGroup;

    loading: boolean = false;
    stop: boolean = false;
    isInvalidFieldUser: boolean = false;
    isInvalidFieldPassword: boolean = false;
    usuario: string = '';
    password: string = '';
    isCordova: boolean = false;
    deviceSession: DeviceObject = null;
    appVersion: string = AppConstants.APP_VERSION;
    appCurrentVersion: string = null;
    appCurrentVersionMessage: string = null;
    //rolesSystem = [AppConstants.RolesCode.ADMINISTRADOR, AppConstants.RolesCode.GESTOR, AppConstants.RolesCode.SUPERVISOR, AppConstants.RolesCode.ANALISTA, AppConstants.RolesCode.ASESOR, AppConstants.RolesCode.CALLCENTER];

    async ngOnInit() {
        this.createForm();
        this.appComponent.loading = false;
        await this.platform.ready();
        this.isCordova = this.platform.is('cordova');
        if(this.deviceSession == null)
        {
            this.deviceSession = await this.deviceService.getDevicePropertis(this.isCordova);
        }
    }

    isDifferentAppVersion(listParameter: Parameter[]) : boolean {

        if(!this.isCordova)
            return false;

        let result = false;
        let filter = listParameter.find(f => f.tabCabId == AppConstants.ParameterCode.ADICIONAL_PARAMETERS
                && f.tabDetState == AppConstants.StateFlag.Activo
                && f.tabDetCode001 == AppConstants.AdicionalParameterCode.VERSION_OB);

        if(filter === undefined)
        {
            result = false;
        }
        else
        {
            this.appCurrentVersion = filter.tabDetCode006;
            this.appCurrentVersionMessage = filter.tabDetCode007.replace('[version]', this.appCurrentVersion);
            console.log('appCurrentVersion' , this.appCurrentVersion);
            console.log('appVersion' , this.appVersion);
            console.log(this.appCurrentVersion != this.appVersion);
            result = (this.appCurrentVersion != this.appVersion);
        }
        return result;
    }

    ngAfterViewInit() {
        if (this.platform.is('cordova')) {
            window['plugins'].intent.getCordovaIntent((intent) => {

                if (intent.extras['flagApp'] == 'Cappta') {
                    sessionStorage.setItem(AppConstants.FlagIntent.FLAG_APP, intent.extras['flagApp']);
                    sessionStorage.setItem(AppConstants.FlagIntent.FLAG_DNI, intent.extras['flagDNI']);
                } else {
                    sessionStorage.setItem(AppConstants.FlagIntent.FLAG_APP, intent.extras['']);
                    sessionStorage.setItem(AppConstants.FlagIntent.FLAG_DNI, intent.extras['']);
                    sessionStorage.setItem(AppConstants.Session.ACCESS_TOKEN, '');
                }
            }, function () {
            });
        }
    }

    createForm() {
        this.loginForm = this.formBuilder.group({
            usuario: '',
            contrasena: ''
        });
    }

    logueo() {
        let login = this.loginForm.value;
        this.loading = true;

        this.authService.GetAuthTokenAsync(login.usuario, login.contrasena).subscribe(
            async (response: TokenModel) => {

                const data = this.getDecodedAccessToken(response.access_token);
                sessionStorage.setItem(AppConstants.Session.ACCESS_TOKEN, response.access_token);
                // #region SessionValues
                sessionStorage.setItem(AppConstants.Session.CHANGEPASSWORD, data['changePassword']);
                sessionStorage.setItem(AppConstants.Session.EMAIL, data['correo']);
                sessionStorage.setItem(AppConstants.Session.EXPIREDPASSWORD, data['expiredPassword']);
                sessionStorage.setItem(AppConstants.Session.TIMESECONDS, '50000');
                sessionStorage.setItem(AppConstants.Session.USERID, data['userId']);
                sessionStorage.setItem(AppConstants.Session.USERNAME, data['userName']);
                sessionStorage.setItem(AppConstants.Session.USERROLID, data['rolId']);
                sessionStorage.setItem(AppConstants.Session.USERROLNAME, data['rolName']);
                sessionStorage.setItem(AppConstants.Session.USERDOCUMENTNUMBER, data['documentNumber']);
                // #endregion

                forkJoin([
                    this.parameterService.getAgency(),
                    this.parameterService.getParametersList({headerId: [AppConstants.ParameterCode.ADICIONAL_PARAMETERS]})
                ]).subscribe((value) => {
                    const version = value[1];
                    if(this.isDifferentAppVersion(version)){
                        this.loading = false;
                        this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, this.appCurrentVersionMessage, MessageSeverity.error);
                        return;
                    }

                    const agency = value[0];
                    sessionStorage.setItem(AppConstants.Session.AGENCYCODE, agency['agencyCode']);
                    sessionStorage.setItem(AppConstants.Session.AGENCYNAME, agency['agencyName']);
                    sessionStorage.setItem(AppConstants.Session.SALESCHANNEL, agency['salesChannel']);
                    sessionStorage.setItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION, agency['salesChannelName']);
                    sessionStorage.setItem(AppConstants.Session.FLAG_CANCEL_SOLICITUD, agency['flagCancelSolicitud']);

                    this.RegisterDeviceToken();
                    this.loading = false;
                    this.appComponent.loading = true;
                    this.appComponent.stop = false;
                    this.navigateTo('home');
                    this.registerAuditoria();
                }, (error: HttpErrorResponse) => {
                    throw error;
                });
            },
            (error) => {
                this.appComponent.loading = false;
                this.loading = false;
                var message: string;
                switch (error.error.error) {
                    case '1':
                        message = AppConstants.MessageModal.USER_DOESNT_EXIST;
                        break;
                    case '2':
                        message = AppConstants.MessageModal.PASSWORD_DOESNT_CORRECT;
                        break;
                    case '3':
                        message = AppConstants.MessageModal.USER_IS_INACTIVE;
                        break;
                    case '5':
                        message = AppConstants.MessageModal.USER_IS_LOCKED;
                    break;
                }
                this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, message, MessageSeverity.error);
            }
        );
    }

    navigateTo(route: string) {
        this.navigateToEvent.next(route);
    }

    getDecodedAccessToken(token: string): any {
        try {
            return jwt_decode(token);
        } catch (Error) {
            return null;
        }
    }

    validFields(login): boolean {
        login.usuario = (login.usuario != null ? login.usuario.replace(/\s/g, '') : '');
        login.contrasena = (login.contrasena != null ? login.contrasena.replace(/\s/g, '') : '');
        this.isInvalidFieldUser = false;
        this.isInvalidFieldPassword = false;
        if ((login.usuario == '') && (login.contrasena == '')) {
            this.isInvalidFieldUser = true;
            this.isInvalidFieldPassword = true;
            return false;
        } else if (login.usuario == '') {
            this.isInvalidFieldUser = true;
            return false;

        } else if (login.contrasena == '') {
            this.isInvalidFieldPassword = true;
            ;
            return false;
        }
        return true;
    }

    onInput(event) {
        if (event.target.value != null && event.target.value.length >= 1) {
            switch (event.target.id) {
                case 'usuario':
                    this.isInvalidFieldUser = false;
                    break;
                case 'password':
                    this.isInvalidFieldPassword = false;
                    break;
                default:
                    break;
            }
        }
    }

    public ngOnDestroy() {
        if (this.singleExecutionSubscription) {
            this.singleExecutionSubscription.unsubscribe();
        }
    }

    executeCaptcha(): void {
        this.loading = true;

        if (!this.validFields(this.loginForm.value)) {
            this.loading = false;
            return;
        }

        this.singleExecutionSubscription = this.recaptchaV3Service.execute('login')
            .subscribe((token) => {
                this.loading = false;
                this.handleRecaptchaExecute(token);
            }, (error) => {
                this.loading = false;
                this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.MessageModal.LOGIN_ERROR_SERVICES, MessageSeverity.error);
            });
    }

    handleRecaptchaExecute(token: string) {
        if (token == undefined || token == null || token == '') {
            this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, LoginMessages.ACTIVIDAD_SOSPECHOSA, MessageSeverity.error);
        }

        this.verificationService.validateCaptcha(token)
            .subscribe((response: boolean) => {
                if (response) {
                    this.logueo();
                } else {
                    this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, LoginMessages.ACTIVIDAD_SOSPECHOSA, MessageSeverity.error);
                }
            }, (error) => {
                this.loading = false;
                this.alertService.showMessage(AppConstants.TitleModal.LOGIN_TITLE, AppConstants.MessageModal.LOGIN_ERROR_SERVICES, MessageSeverity.error);
            });
    }


    private async RegisterDeviceToken() {
        await this.platform.ready();
        if (!this.platform.is('cordova')) {
            this.token = this.firebaseService.requestPushNotificationsPermission(AppConstants.Channel.WEB);
        } else {
            this.token = await this.firebaseService.requestTokenMovil(AppConstants.Channel.MOVIL);
        }
    }

    async getAgency() {
        await this.parameterService.getAgency().toPromise().then((response) => {
                sessionStorage.setItem(AppConstants.Session.AGENCYCODE, response['agencyCode']);
                sessionStorage.setItem(AppConstants.Session.AGENCYNAME, response['agencyName']);
                sessionStorage.setItem(AppConstants.Session.SALESCHANNEL, response['salesChannel']);
                sessionStorage.setItem(AppConstants.Session.SALES_CHANNEL_DESCRIPTION, response['salesChannelName']);
                sessionStorage.setItem(AppConstants.Session.FLAG_CANCEL_SOLICITUD, response['flagCancelSolicitud']);
            },
            (error: HttpErrorResponse) => {
            });
    }

    async registerAuditoria() {
        try {
            await this.auditoriaService.Login(this.deviceSession).toPromise().then((response) => {
                this.loading = false;
            });
        } catch (error) {
            console.log(error);
        }
    }

}
