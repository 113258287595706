import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Platform } from '@ionic/angular';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ClientService } from '../../../../services/client.service';
import { ParameterService } from '../../../../services/parameter.service';
import { ProductEntidadDetailModalComponent } from '../product-entidad-detail-modal/product-entidad-detail-modal.component';

@Component({
  selector: 'app-product-detail-modal',
  templateUrl: './product-detail-modal.component.html',
  styleUrls: ['./product-detail-modal.component.scss'],
})
export class ProductDetailModalComponent implements OnInit {
  modalDetailRef: BsModalRef;
  data: any;
  loading: boolean = false;

  imgUserCircle: string = './assets/images/icon-simulation-circle.png';
  imgElipse = './assets/images/icon-ellipse.png';

  columnsEntidadesView = [];
  rowsEntidades = [];
  tableClassCompany: string = 'table-company-1';


  @ViewChild('actionsTemplateView') actionsTemplateView: TemplateRef<any>

  constructor(
    private modalProductDetailRef: BsModalRef,
    private parameterService: ParameterService,
    private platform: Platform,
    private clientService: ClientService,
    private modalService: BsModalService
  ) { }

  async ngOnInit() {
    await this.platform.ready();
    this.createColumnsTable();
    this.GetEntidades();
  }

  private createColumnsTable(): void {

    const pipe_$ = {
      transform(value: string): string {
        if (value == '' || value == undefined || value == null) {
          return "";
        } else {
          return 'S/ ' + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
      }
    };

    if (!this.platform.is('cordova')) {
      this.columnsEntidadesView = [
        { prop: 'entNombre', name: 'Entidad', width: 125, headerClass: "prueba", resizeable: false, cellClass: "cell-datatable-bold" },
        { prop: 'entMonto', name: 'Monto', width: 135, headerClass: "prueba", resizeable: false, cellClass: "cell-datatable-color", pipe: pipe_$ },
        { prop: 'entProducto', name: 'Producto', width: 135, headerClass: "prueba", resizeable: false, cellClass: "text-center" },
        { prop: 'entCuenta', name: 'Cuenta', width: 140, headerClass: "prueba", resizeable: false, cellClass: "text-center" },
        { cellTemplate: this.actionsTemplateView, name: 'Acción', width: 85, draggable: false, resizeable: false, sortable: false },
      ];
    } else {  
      this.columnsEntidadesView = [
        { prop: 'entNombre', name: 'Entidad', width: 110, headerClass: "prueba", resizeable: false, cellClass: "cell-datatable-color" },
        { prop: 'entMonto', name: 'Monto', width: 90, headerClass: "prueba", resizeable: false, cellClass: "cell-datatable-color", pipe: pipe_$ },
        { cellTemplate: this.actionsTemplateView, name: 'Acción', width: 77, draggable: false, resizeable: false, sortable: false },
      ];
    }
    
  }

  close() {
    this.modalProductDetailRef.hide();
  }

  GetEntidades() {
    this.loading = true;
    var filter = { solId: this.data.numeroSolicitud };
    this.clientService.getEntidades(filter).subscribe((response) => {
      
      response.forEach((item) => {
        item.estadoAccion = 4;
        item.guid = this.uuidv4();
        if (item.files.length > 0) {
          item.files.forEach((item2) => { item2.estadoAccion = 3 });
        }
      });
      this.rowsEntidades = response;
      let numberResults = this.rowsEntidades.length;
      switch (numberResults) {
        case 0:
        case 1:
          this.tableClassCompany = 'table-company-1';
          break;
        case 2:
          this.tableClassCompany = 'table-company-2';
          break;
        case 3:
          this.tableClassCompany = 'table-company-3';
          break;
        case 4:
          this.tableClassCompany = 'table-company-4';
          break;
        default:
          this.tableClassCompany = 'table-company-default';
          break;
      }

      this.loading = false;
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }

  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  productDetailEnitdadShow(row : any) {
    const initialState = {
        data: row
    }
    this.modalDetailRef = this.modalService.show(ProductEntidadDetailModalComponent, { class: 'modal-dialog-centered', backdrop: 'static', keyboard: false, initialState });
  }

}
