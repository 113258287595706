import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[OnlyDecimal]'
})
export class OnlyDecimalDirective {
  constructor(private element: ElementRef) { }

  @HostListener('keypress', ['$event'])
  onKeypressHandler(event: any) {

    
    var valueInputText = event.currentTarget.value;
    const charCode = (event.which) ? event.which : event.keyCode;

    //Valid '.' decimal
    if (valueInputText.indexOf(".") != -1 && charCode == 46) {
      return false;
    } else if (valueInputText == '' && charCode == 46) {
      return false;
    }

    //Valid first '.'
    if (valueInputText.indexOf(".") == -1 && charCode == 46) {
      return true;
    }

    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
}
