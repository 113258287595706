import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { HelperService } from '../shared/services/helper.service';
import { RegisterMonitor } from '../models/verification';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private httpClient: HttpClient
  ) { }

  private POST_ENDPOINT: string = "Notification";
  private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
  private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

  postRegisterDeviceToken(model: any): Observable<boolean> {
    return this.httpClient.post<boolean>(`${this.REQUEST_URL}/RegisterDevice`, model);
  }

  postSearchDevice(model: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.REQUEST_URL}/GetUsersDevice`, model);
  }

  postRegisterNotification(model: any): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.REQUEST_URL}/SendNotification`, model);
  }

  postRegisterMonitor(codRespuesta: string, tipoAutenticacion: string, solId: string, applyRule: string = ""): Observable<boolean> {
    let model: RegisterMonitor = new RegisterMonitor();
    model.solId = solId;
    model.codRespuesta = codRespuesta;
    model.tipoAutenticacion = tipoAutenticacion;
    model.applyRule = applyRule;
    return this.httpClient.post<boolean>(`${this.REQUEST_URL}/SendMonitor`, model);
  }

  postRegisterTracePinpad(solId: string): Observable<boolean> {
    let model: RegisterMonitor = new RegisterMonitor();
    model.solId = solId;
    return this.httpClient.post<boolean>(`${this.REQUEST_URL}/SaveTracePinpad`, model);
  }

}
