import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {HelperService} from '../shared/services/helper.service';
import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {AuditoriaGeneral, AuditoriaGeneralFilter, AuditoriaGeneralTipo} from '../models/auditoria';
import { DeviceObject } from '../models/device';

@Injectable({
    providedIn: 'root'
})
export class AuditoriaService {

    constructor(private httpClient: HttpClient) {
    }

    private POST_ENDPOINT: string = 'Auditoria';
    private BASE_URL: string = HelperService.buildRequestURL(environment.sistemaWebAdmin);
    private REQUEST_URL: string = `${this.BASE_URL}/${this.POST_ENDPOINT}`;

    Create(auditoriaGeneral: AuditoriaGeneral): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.REQUEST_URL}/general`, auditoriaGeneral);
    }

    GetFiltered(filter: AuditoriaGeneralFilter): Observable<any[]> {
        return this.httpClient.get<any[]>(`${this.REQUEST_URL}/filter`, {
            params: new HttpParams({
                fromObject: {
                    'startDate': filter.startDate,
                    'endDate': filter.endDate,
                    'typeId': filter.typeId.toString(),
                    'documentTypeId': filter.documentTypeId.toString(),
                    'solId': filter.solId,
                    'documentNumber': filter.documentNumber
                }
            })
        });
    }

    GetTypes(): Observable<AuditoriaGeneralTipo[]> {
        return this.httpClient.get<AuditoriaGeneralTipo[]>(`${this.REQUEST_URL}/types`);
    }

    Login(modelDevice: DeviceObject): Observable<boolean> {
        return this.httpClient.post<boolean>(`${this.REQUEST_URL}/login`, modelDevice);
    }
}
