import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { AppComponent } from '../../../app.component';
import { AuthService } from '../../../services/auth.service';
import { EmitterService } from '../../../services/emitter.service';
import { AppConstants, ConstantMessages } from '../../constants/app.constants';
import { AlertService, MessageSeverity } from '../../services/alert.service';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl } from '@angular/forms';
import { HelperService } from '../../services/helper.service';
import { UserPasswordModel } from '../../../models/user';
import { UserService } from '../../../services/user.service';
import { Cliente } from '../../../models/simular';
import { ParameterService } from '../../../services/parameter.service';
import { SolicitudService } from '../../../services/solicitud.service';
import { RechazoService } from '../../../services/rechazo.service';
import { SolicitudPut } from '../../../models/solicitud';
import { Rechazo } from '../../../models/rechazo';
import {Platform} from '@ionic/angular';
import { CacheRouteReuseStrategy } from '../cache-route-reuse.strategy/cache-route-reuse.strategy';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  isNavRipley: boolean = false;
  isProducts: boolean = true;
  isSimulacion: boolean = false;
  isVerificacion: boolean = false;
  isValidacion: boolean = false;
  isClientsDetails: boolean = false;
  bandejaActive: boolean = false;
  subscription: Subscription;
  isAdmin: boolean = sessionStorage.getItem(AppConstants.Session.USERROLID) == AppConstants.RolesCode.ADMINISTRADOR ? true : false;
  isAnalista: boolean = sessionStorage.getItem(AppConstants.Session.USERROLID) == AppConstants.RolesCode.ANALISTA ? true : false;
  userId: string = sessionStorage.getItem(AppConstants.Session.USERID);

  loginPath: string = "login";
  loading: boolean = false;
  cliente: Cliente = new Cliente();
  comentarioAnulacionForm: string = '';
  motivoAnulacionForm;

  @ViewChild('prototipoModal') prototipoModal: ModalDirective;
  @ViewChild('invalidateModal') invalidateModal: ModalDirective;
  @ViewChild('passwordModal') passwordModal: ModalDirective;
  
  motivosAnulacion = [];

  modalTitle: string = AppConstants.TitleModal.CHANGE_PASSWORD;
  isChangePassword = false;
  resetPasswordForm: FormGroup;
  equalsPassword: boolean = false;
  userPasswordModel: UserPasswordModel = new UserPasswordModel();
  finalizacionActive: boolean = false;

  arrayMenu = [];
  currentRoute = '';

  agencyCode : string = sessionStorage.getItem(AppConstants.Session.AGENCYCODE);
  agencyName : string = sessionStorage.getItem(AppConstants.Session.AGENCYNAME);

  constructor(
    private authService: AuthService,
    private appComponent: AppComponent,
    private emitterService: EmitterService,
    private parameterService: ParameterService,
    private solicitudService: SolicitudService,
    private rechazoService: RechazoService,
    private alertaService: AlertService,
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private userService: UserService,
    private platform: Platform,
    private router: Router) {
  }

  ngOnInit() {
    this.createMenu();
    this.createForm();
    if (!this.isAdmin) {
      this.subscription = this.emitterService.getMessage().subscribe(message => {
        this.isProducts = message == "/productsList" ? true : false;
        this.isSimulacion = message == "/simulacion" ? true : false;
        this.isVerificacion = message == "/verificacion" ? true : false;
        this.isValidacion = message == "/validacion/token" ? true :
                            message == "/validacion/dactilar" ? true : 
                            message == "/validacion/succes" ? true : false;
        this.isClientsDetails = message == "/clienteDetails" ? true : false;
        this.bandejaActive = message == "/bandeja" ? true : false;
        this.finalizacionActive = message == "/validacion/succes" ? true : false;
      });
      this.getParameters(AppConstants.ParameterCode.MOTIVOS);
    }
  }

  showNav() {
    this.isNavRipley = !this.isNavRipley;
  }

  hideNav() {
    this.isNavRipley = !this.isNavRipley;
  }

  goToRoute(route: string) {
    route == "salir" ? this.prototipoModal.show() :
    route == "anular" ? this.invalidate() : this.navigateLogic(route);
  }

  private navigateLogic(route: string){
    this.deleteStoredRoute(route);
    this.currentRoute = route;
    this.router.navigateByUrl(route)
  }

  /**
   * Limpia la instancia(Componente) de memoria
   * @param url 
   */
  private deleteStoredRoute(url: string): void {
    if(this.currentRoute === url){
      return;
    }
    const strategy = this.router
      .routeReuseStrategy as CacheRouteReuseStrategy;

    strategy.deleteStoredRoute(url);
  }

  /**
  * Limpia todas las instancias(Componentes) de memoria
  * @param url 
  */
  private deleteAllStoredRoute(): void {
    const strategy = this.router
      .routeReuseStrategy as CacheRouteReuseStrategy;

    strategy.deleteAllStoredRoute();
  }

  logout() {
    this.prototipoModal.show();
  }

  confirmLogout() {
    this.authService.logout();
    this.appComponent.navigateTo('', this.loginPath);
    this.deleteAllStoredRoute();
  }

  cancelLogout() {
    this.prototipoModal.hide();
  }

  invalidate() {
    this.cliente = JSON.parse(sessionStorage.getItem(AppConstants.ObjectCliente.CLIENTE));
   
    if (this.cliente == null) {
      this.alertaService.showMessage(AppConstants.TitleModal.ANNULMENT_PRODUCT, AppConstants.MessageModal.ANNULMENT_PRODUCT_NOT_EXIST, MessageSeverity.error);
      return;
    }

    if ((this.cliente.NumeroSolicitud == '' || this.cliente.NumeroSolicitud == null) && (this.cliente.WFInstance == '' || this.cliente.WFInstance == null)) {
      this.alertaService.showMessage(AppConstants.TitleModal.ANNULMENT_PRODUCT, AppConstants.MessageModal.ANNULMENT_PRODUCT_NOT_EXIST, MessageSeverity.error);
      return;
    }

    if(this.cliente.userCreation != this.userId){
      this.alertaService.showMessage(AppConstants.TitleModal.ANNULMENT_PRODUCT, AppConstants.MessageModal.ANNULMENT_PRODUCT_NOT_USER_CREATION, MessageSeverity.error);
      return;
    }
    this.comentarioAnulacionForm = '';
    this.invalidateModal.show();
  }
  closeAnulacion() {
    this.invalidateModal.hide();
    this.hideNav();
  }

  sendAnulacion() {
    if (this.cliente == null) {
      this.alertaService.showMessage(AppConstants.TitleModal.ANNULMENT_PRODUCT, AppConstants.MessageModal.ANNULMENT_PRODUCT_NOT_EXIST, MessageSeverity.error);
      return;
    }
    else {
      if ((this.cliente.NumeroSolicitud == '' || this.cliente.NumeroSolicitud == null) && (this.cliente.WFInstance == '' || this.cliente.WFInstance == null)) {
        this.alertaService.showMessage(AppConstants.TitleModal.ANNULMENT_PRODUCT, AppConstants.MessageModal.ANNULMENT_PRODUCT_NOT_EXIST, MessageSeverity.error);
        return;
      }
     
      this.sendFinalizado(AppConstants.SolicitudState.CANCELADO, AppConstants.WFTareas.CLOSE);
    }
      
  }
  getParameters(code: number) {
    this.loading = true;
    this.parameterService.getParameters(code).subscribe((response) => {
      this.motivosAnulacion = response.filter(x => x.tabDetCode008 == "ANULADO");
      this.motivoAnulacionForm = this.motivosAnulacion[0]["tabDetCode001"];
      this.loading = false;
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
      });
  }
  sendFinalizado(estado, task) {
    var model = new SolicitudPut();
    model.solId = this.cliente.NumeroSolicitud;
    model.channel = !this.platform.is('cordova') ? AppConstants.Channel.WEB : AppConstants.Channel.MOVIL;
    model.processId = AppConstants.Process.ONBOARDING;
    model.solState = estado;
    model.taskId = task;
    model.worFlowId = this.cliente.WFInstance;
    this.UpdateStateSolicitud(model);
  }

  UpdateStateSolicitud(mode: SolicitudPut) {
    this.loading = true;
    this.solicitudService.updateStateSolicitud(mode).subscribe((response) => {
      this.CallSendRechazado();
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.alertaService.showMessage(AppConstants.TitleModal.CREDIT_MANAGEMENT, error.error.messages, MessageSeverity.error);
      });
  }
  CallSendRechazado() {
    var model = new Rechazo();
    model.channel = !this.platform.is('cordova') ? AppConstants.Channel.WEB : AppConstants.Channel.MOVIL;
    model.motivoRechazo = this.comentarioAnulacionForm;
    model.motivoRechazoId = this.motivoAnulacionForm;
    model.processId = AppConstants.Process.ONBOARDING;
    model.solId = this.cliente.NumeroSolicitud;
    model.taskId = AppConstants.WFTareas.APPROVED;
    model.worFlowId = this.cliente.WFInstance;
    this.SendRechazo(model);
  }
  SendRechazo(model: Rechazo) {
    this.loading = true;
    this.rechazoService.createRechazo(model).subscribe((response) => {
      this.loading = false;
      sessionStorage.removeItem(AppConstants.ObjectCliente.CLIENTE);
      this.invalidateModal.hide();
      this.hideNav();
      this.alertaService.showMessage(AppConstants.TitleModal.SEND_RECHAZO, AppConstants.MessageModal.SEND_RECHAZO_OK, MessageSeverity.success);
      this.router.navigateByUrl("productsList");
    },
      (error: HttpErrorResponse) => {
        this.loading = false;
        this.alertaService.showMessage(AppConstants.TitleModal.SEND_RECHAZO, error.error.messages, MessageSeverity.error);
      });
  }

  createForm() {
      this.resetPasswordForm = this.formBuilder.group({
          userId: '',
          currentPassword: ['', [this.noWhiteSpaceValidator]],
          newPassword: ['', [this.noWhiteSpaceValidator,
          this.passwordValidator,
          Validators.minLength(8)]],
          confirmNewPassword: ['', [this.noWhiteSpaceValidator,
          this.passwordValidator,
          Validators.minLength(8)]]
      })
  }

  ngAfterViewInit() {

      let openPopup: boolean = false
      if (sessionStorage.getItem(AppConstants.Session.CHANGEPASSWORD) === "1") {
          this.modalTitle = AppConstants.TitleModal.REQUIRED_CHANGE_PASSWORD;
          openPopup = true;
      }
      else if (sessionStorage.getItem(AppConstants.Session.EXPIREDPASSWORD) === "1") {
          this.modalTitle = AppConstants.TitleModal.EXPIRED_PASSWORD;
          openPopup = true;
      }

      if (openPopup && sessionStorage.getItem(AppConstants.Session.USERROLID) != AppConstants.RolesCode.SUPERVISOR) {
          this.isChangePassword = true;
          setTimeout(() => {
              this.passwordModalShow();
          }, 1000)
      }
  }

  passwordModalShow() {
      this.resetPasswordForm.reset();
      this.helperService.cleanFormErrors(this.formErrors);
      this.passwordModal.show();
  }

  formErrors = {
      currentPassword: '',
      newPassword: '',
      confirmNewPassword: ''
  }

  public noWhiteSpaceValidator(control: FormControl) {
      const isWhitespace = (control.value || '').toString().trim().length === 0;
      const isValid = !isWhitespace;
      return isValid ? null : { 'required': true };
  }

  public passwordValidator(control: FormControl) {
      const passwordRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.#])[A-Za-z\d@$!%*?&.#]{8,}$/;
      if (!passwordRegexp.test(control.value)) {
          return { "pattern": true };
      }
      return null;
  }

  close() {
      this.passwordModal.hide();
  }

  changePassword() {


      let model = this.resetPasswordForm.value;

      if (model.newPassword != model.confirmNewPassword) {
          this.equalsPassword = true;
          this.formErrors.newPassword = AppConstants.MessageModal.PASSWORDS_INCORRECTS;
          this.formErrors.confirmNewPassword = AppConstants.MessageModal.PASSWORDS_INCORRECTS;
          return false;
      }
      else {

          this.equalsPassword = false;
          this.formErrors['newPassword'] = '';
          this.formErrors['confirmNewPassword'] = '';
      }

      if (!this.resetPasswordForm.valid) {
          this.formErrors = this.validateForm(this.resetPasswordForm, this.formErrors, ConstantMessages.LoginErrorMessages, false);
          return false;
      }
      this.loading = true;

      model.userId = sessionStorage.getItem(AppConstants.Session.USERID);
      this.userPasswordModel.setAll(model);
      this.userService.updatePassword(this.userPasswordModel).subscribe(
          (response) => {

              this.alertaService.showMessage(AppConstants.TitleModal.CHANGE_PASSWORD, AppConstants.MessageModal.CHANGE_PASSWORD_CORRECT, MessageSeverity.success);
              this.appComponent.navigateTo('', this.loginPath);
              this.loading = false;
          },
          (error) => {
              let message = this.helperService.getErrorMessages(error);
              this.alertaService.showMessage(AppConstants.TitleModal.CHANGE_PASSWORD, message, MessageSeverity.error);
              this.loading = false;
          }
      )
  }

  public validateForm(formToValidate: AbstractControl, formErrors: any, messages: any, checkDirty?: boolean) {
      const form = formToValidate;

      for (const field in formErrors) {
          var messagesT = messages[field];
          if (field) {
              const control = form.get(field);
              if (control.hasOwnProperty('controls')) {
                  var formErrorsT = formErrors[field];
                  control['controls'].forEach(item => {
                      this.validateForm(item, formErrorsT, messagesT);
                  });
              }
              else {
                  if (control && !control.valid) {
                      if (!checkDirty || (control.dirty || control.touched)) {
                          for (const key in control.errors) {
                              if (key && key !== 'invalid_characters') {
                                  formErrors[field] = '';
                                  formErrors[field] = formErrors[field] || messagesT[key];
                              } else {
                                  formErrors[field] = formErrors[field] || messagesT[key](control.errors[key]);
                              }
                          }
                      }
                  }
                  else {
                      formErrors[field] = '';
                  }
              }
          }
      }
      return formErrors;
  }



  createMenu(){
    this.loading = true;
    this.parameterService.getMenu().subscribe((response) => {
      this.arrayMenu = response;
      this.loading = false;
    },
    (error: HttpErrorResponse) => {
      this.loading = false;
    });
  }

  showTransaction() {
    let result = false;
    let rolId = sessionStorage.getItem(AppConstants.Session.USERROLID);
    result = rolId == AppConstants.RolesCode.ASESOR ? true :
             rolId == AppConstants.RolesCode.CALLCENTER ? true :
             rolId == AppConstants.RolesCode.GESTOR ? true : false;    
    return result;    
  }


}
