import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, ControlContainer } from '@angular/forms';

@Component({
  selector: 'onb-textarea',
  templateUrl: './onb-textarea.component.html',
  styleUrls: ['./onb-textarea.component.css'],
})
export class OnbTextareaComponent implements OnInit {
  @Input() label: string;
  @Input() name: string;
  @Input() maxlength: number;
  @Input() rows: number = 2;

  rules = {
    required: (props, label) => `Ingrese ${label}`,
    noWhiteSpaceValidator: (props, label) => `Ingrese ${label}`,
  };

  constructor(private controlContainer: ControlContainer) {
  }

  get control() {
    const fb = this.controlContainer.control as FormGroup;
    return fb.controls[this.name] as FormControl;
  }

  ngOnInit(): void {
    this.control.updateValueAndValidity();
  }

  isInvalid(): boolean {
    return this.control.invalid && this.control.touched;
  }

  getErrorMessage() {
    let message = '';
    for (const error in this.control.errors) {
      // Si el error actual tiene una función de mensaje de error correspondiente
      if (this.rules[error]) {
        // Llama a la función de mensaje de error con los detalles del error
        message = this.rules[error](this.control.errors[error], this.label);
        break;
      }
    }
    return message;
  }
}