import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
  name: "numberTwoDecimals"
})

export class NumberTwoDecimalsPipe implements PipeTransform {
  transform(value: string): string {
    return "S/ " + parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
